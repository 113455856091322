import React, { FC, useEffect, useRef } from 'react';

import Chart from 'features/drilldown/cashflows/modelingChart/Chart';
import MonthlyTable from 'features/drilldown/cashflows/MonthlyTable';
import SummaryTable from 'features/drilldown/cashflows/SummaryTable';
import { GetPortfolioSummary_user_company_performanceData } from 'features/pages/portfolio/__generated__/GetPortfolioSummary';
import { GetCashflowByPool_userCompanyListing_performance_summary as PoolCashflowPerfSummary } from 'features/pages/portfolio/CashFlowModeling/__generated__/GetCashflowByPool';
import { usePortfolioContext } from 'features/pages/portfolio/PortfolioContext';

import { useSelectedPoolCashflowValue } from './CashFlows';
import { CashFlowsValidValues } from './configurations/cashflow-inputs';
import { ChartSkeletonLoader } from './modelingChart/ChartSkeletonLoader';

type CashFlowDataDisplayProps = {
  cashflowData: PoolCashflowPerfSummary | undefined;
  userInputs: CashFlowsValidValues;
  selectedPoolId?: string;
  selectedPoolCashflow?: (
    listingId: string,
    inputValues: CashFlowsValidValues,
  ) => useSelectedPoolCashflowValue;
  loading: boolean;
};

const CashFlowsDataDisplay: FC<CashFlowDataDisplayProps> = ({
  cashflowData,
  selectedPoolId,
  selectedPoolCashflow,
  loading,
}) => {
  const { setTotalFilteredPoolData } = usePortfolioContext();
  const data = cashflowData;
  const monthlyCashFlow = data?.cashFlows?.monthlyCashFlows;
  const totals = data?.cashFlows?.totals;
  const filteredPoolDataRef = useRef<Record<
    keyof GetPortfolioSummary_user_company_performanceData,
    number
  > | null>(null);

  if (selectedPoolCashflow && selectedPoolId) {
    if (!loading && data?.cashFlows) {
      const totalFiltered: Record<
        keyof GetPortfolioSummary_user_company_performanceData,
        number
      > = {
        averageAge: data.averageAge,
        averageMaturity: data.averageMaturity,
        dti: data.dti,
        fico: data.fico,
        ltv: data.ltv,
        netCoupon: data.netCoupon,
        unpaidBalance: data.unpaidBalance,
        loanCount: data.loanCount,
        averageBalance: data.averageBalance,
        originalBalance: data.originalBalance,
        wa_servicing_rate: data.wa_servicing_rate,
      };

      filteredPoolDataRef.current = totalFiltered;
    }
  }

  useEffect(() => {
    if (!loading) {
      setTotalFilteredPoolData(filteredPoolDataRef.current);
    }

    if (!selectedPoolId) {
      setTotalFilteredPoolData(null);
    }
  }, [loading, selectedPoolId, setTotalFilteredPoolData]);

  return (
    <>
      <div className="mx-5 my-0 font-heebo text-lg font-normal text-gray-100">
        Cashflow Projections
      </div>
      <div className="mt-5 h-[318px] w-full font-heebo text-lg font-normal">
        {loading ? (
          <ChartSkeletonLoader />
        ) : (
          <Chart cashFlowMonths={monthlyCashFlow} />
        )}
      </div>
      <div className="mt-5 flex flex-nowrap gap-x-3">
        <div>
          <div className="mx-0 my-5 font-heebo text-lg font-normal text-gray-100">
            Cashflow Matrix
          </div>
          <SummaryTable
            cashFlowTotals={totals ?? null}
            performanceSummary={data ?? null}
          />
        </div>
        {monthlyCashFlow != null && (
          <MonthlyTable monthlyCashFlows={monthlyCashFlow} />
        )}
      </div>
    </>
  );
};

export default CashFlowsDataDisplay;
