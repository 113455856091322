import React from 'react';

export interface SimpleTableProps {
  children: React.ReactNode;
  className?: string;
}

export const SimpleTable = ({ children, className }: SimpleTableProps) => (
  <table
    className={`${className} w-full table-auto border-collapse overflow-auto border-0 bg-red-950`}
  >
    {children}
  </table>
);
