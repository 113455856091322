import React, { useState, useEffect } from 'react';

import {
  ComboDropdownValuePill,
  ComboDropdownValuePillProps,
} from './ComboDropdownValuePill';
import { usePopout, FloatingPortal } from '../hooks/usePopout';

export type DropdownPillListInputProps = Omit<
  ComboDropdownValuePillProps,
  'value'
> & {
  value: string[];
  onValueChanged: (value: string[]) => void;
  placeholder?: string;
  className?: string;
  valueToString?: (value: string[]) => string;
};

export function DropdownPillListInput({
  description,
  value,
  onValueChanged,
  placeholder,
  iconName,
  iconOnClick,
  valueElipses,
  valueToString,
  className,
}: DropdownPillListInputProps) {
  const {
    refs,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    floatingStyles,
    setIsOpen,
  } = usePopout();

  const [inputValue, setInputValue] = useState(value.join('\n'));

  useEffect(() => {
    setInputValue(value.join('\n'));
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = event.target.value;
    setInputValue(input);

    const values = input
      .split(/[\n,]+/)
      .map((val) => val.trim())
      .filter((val) => val.length > 0);

    onValueChanged(values);
  };

  const displayValue = valueToString
    ? valueToString(value)
    : `${value.length} items`;

  return (
    <>
      <ComboDropdownValuePill
        {...getReferenceProps()}
        ref={refs.setReference}
        description={description}
        className={className}
        isActive={isOpen}
        iconName={iconName}
        iconOnClick={iconOnClick}
        value={displayValue}
        valueElipses={valueElipses}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen ? (
        <FloatingPortal>
          <div
            className="z-10"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="w-64 rounded bg-gray-950 p-2 shadow-lg">
              <textarea
                className="h-32 w-full resize-none rounded border bg-background-canvas p-2 text-input-text focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder={placeholder}
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
}
