import React, { Dispatch, SetStateAction } from 'react';

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';

import { SimpleTable } from './SimpleTable';

export interface DraggableColumnsTableProps {
  children: React.ReactNode;
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
  disabledColumnsIds?: string[];
  className?: string;
}

export const DraggableColumnsTable = ({
  children,
  setColumnOrder,
  disabledColumnsIds = [],
  className = '',
}: DraggableColumnsTableProps) => {
  // reorder columns after drag & drop
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (
      active &&
      over &&
      active.id !== over.id &&
      !disabledColumnsIds.includes(over.id as string)
    ) {
      setColumnOrder((columnOrder: string[]) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SimpleTable className={className}>{children}</SimpleTable>
    </DndContext>
  );
};
