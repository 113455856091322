import { FC } from 'react';

import { chartGradientDataUri } from 'features/pages/market-data/shared';

type ChartSkeletonLoaderProps = {
  width?: string | number;
  height?: number;
};

export const ChartSkeletonLoader: FC<ChartSkeletonLoaderProps> = ({
  width = '100%',
  height = 318,
}) => {
  return (
    <div
      className="relative mx-auto overflow-hidden rounded-lg bg-background-surface px-4 py-3"
      style={{ width, height }}
    >
      {/* Gradient background */}
      <div
        className="absolute inset-0 opacity-65"
        style={{
          backgroundImage: `url("${chartGradientDataUri}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      {/* Animated shimmer effect */}
      <div className="from-transparent to-transparent animate-shimmer absolute inset-0 bg-gradient-to-r via-slate-700"></div>

      {/* Chart content */}
      <div className="relative z-10">
        {/* Title */}
        <div className="mx-auto mb-8 h-6 w-1/3 rounded bg-slate-700"></div>
        {/* Legend */}
        <div className="flex justify-start space-x-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-4 w-20 rounded bg-slate-700"></div>
          ))}
        </div>
        {/* Chart area */}
        <div className="relative" style={{ height: height - 20 }}>
          {/* Chart lines */}
          <div className="absolute bottom-12 left-12 right-2 top-2 flex items-end justify-between">
            {[...Array(50)].map((_, i) => (
              <div
                key={i}
                className="w-4 rounded-t bg-slate-600"
                style={{ height: `${Math.random() * 80 + 20}%` }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
