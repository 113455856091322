import { DropdownPillListInput } from 'common-ui/Combobox/DropdownPillListInput';

import {
  BaseFiltersConfig,
  ListInputFilter,
  ListInputFilterConfigEntry,
} from '../filters.types';
import { CommonProps } from '../PillFilters';

const getPlaceholder = (displayName: string): string => {
  switch (displayName) {
    case 'Loan Id':
      return `Copy/paste your list of loan_ids or account_ids, separated by comma, into this field`;
    default:
      return `Input comma or newline separated values for ${displayName}`;
  }
};

export function ListInputPillFilter<T extends BaseFiltersConfig>({
  filter,
  filterConfig,
  updateFilter,
  removeFilter,
  index,
  className,
}: CommonProps<T> & {
  filter: ListInputFilter<T>;
  filterConfig: ListInputFilterConfigEntry;
  className: string;
}) {
  const { displayName } = filterConfig;
  const placeholder = getPlaceholder(displayName);
  const valueToString = (values: string[]) => {
    return `${values.length} items`;
  };

  return (
    <DropdownPillListInput
      className={className}
      description={displayName}
      value={filter.values}
      onValueChanged={(values) => updateFilter(filter.name, index, values)}
      iconName="pill-close"
      iconOnClick={() => removeFilter(filter.name, index)}
      placeholder={placeholder}
      valueToString={valueToString}
    />
  );
}
