import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { RowSelectionState } from '@tanstack/react-table';
import { DataDisplayTable, Spinner, WithSubData } from 'common-ui';
import { NETWORK_ERROR } from 'configs/messages';
import { DealCardFragments } from 'features/deals/cards/DealCard';
import { ListingCardFragments } from 'features/deals/cards/ListingCard';
import { useTheme } from 'styled-components';
import { isNotNullOrUndefined } from 'utils/typeUtils';

import {
  ApolloError,
  gql,
  NetworkStatus,
  OperationVariables,
  QueryResult,
  useQuery,
} from '@apollo/client';

import { DealBlocker, DealStatus } from '__generated__/globalTypes';

import {
  GetDealsData,
  GetDealsData_user_company_sellingDeals,
} from './__generated__/GetDealsData';
import {
  GetSellerListingsData,
  GetSellerListingsData_user_company_listings_UserCompanyListing as Listing,
} from './__generated__/GetSellerListingsData';
import { activeColumnDefinitions } from './Table.config';

const ListingFragmentData = gql`
  fragment ListingFragment on Listing {
    id
    loan_count
    wa_borrower_credit_score
    wa_coupon
    wa_loan_age_months
    wa_ltv
    wa_remaining_loan_terms_months
    ...ListingCardListing
  }
  ${ListingCardFragments.listing}
`;

const GET_SELLER_LISTINGS_QUERY_DATA = gql`
  query GetSellerListingsData {
    user {
      id
      company {
        id
        listings {
          id
          ...ListingFragment
          ... on UserCompanyListing {
            is_public
            deals {
              id
              counterparty {
                ...DealCardCounterparty
              }
              ...DealCardDeal
            }
          }
        }
      }
    }
  }
  ${ListingFragmentData}
  ${DealCardFragments.counterparty}
  ${DealCardFragments.deal}
  ${ListingCardFragments.listing}
`;

const GET_All_DEALS_QUERY = gql`
  query GetDealsData {
    user {
      id
      company {
        id
        buyingDeals: deals(role: BUYER) {
          id
          counterparty {
            ...DealCardCounterparty
          }
          ...DealCardDeal
          listing {
            id
            ...ListingFragment
          }
        }
        sellingDeals: deals(role: SELLER) {
          id
          counterparty {
            ...DealCardCounterparty
          }
          ...DealCardDeal
          listing {
            id
            ...ListingFragment
          }
        }
      }
    }
  }
  ${DealCardFragments.counterparty}
  ${DealCardFragments.deal}
  ${ListingFragmentData}
`;

export const ErrorMessage = (props: {
  err: ApolloError | undefined;
}): JSX.Element | null => {
  if (props.err) {
    if (process.env.NODE_ENV === 'development') {
      console.log('Error!', props.err);
    }
    return <div className="flex justify-center">{NETWORK_ERROR}</div>;
  }
  return null;
};

type Props = {
  onDataFound: Dispatch<SetStateAction<boolean>>;
  gqlDeals: QueryResult<GetDealsData, OperationVariables>;
};

const ActiveListings = (props: Props): JSX.Element => {
  const gql = useQuery<GetSellerListingsData>(GET_SELLER_LISTINGS_QUERY_DATA, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const { gqlDeals } = props;

  const listingsToShow = useMemo(
    () =>
      gql.data?.user.company.listings
        .map((listing) => listing as Listing)
        .filter((listing) => listing.is_public)
        .map((listing) => {
          return {
            ...listing,
            deals:
              listing.deals &&
              listing.deals
                .filter((deal) => DealStatus.COMPLETE !== deal.state.status)
                .map((deal) => ({ ...deal, role: DealBlocker.SELLER })),
            subData:
              listing.deals &&
              listing.deals
                .filter((deal) => DealStatus.COMPLETE !== deal.state.status)
                .map((deal) => ({ ...deal, role: DealBlocker.SELLER })),
          };
        })

        .filter(
          (value, index, self) =>
            index === self.findIndex((listing) => listing.id === value.id),
        ) ?? [],
    [gql.data],
  );

  const completeDeals = useMemo(() => {
    const sellingDeals =
      gqlDeals.data?.user.company.sellingDeals
        .filter((deal) => deal.state.status === DealStatus.COMPLETE)
        .filter(isNotNullOrUndefined) ?? [];

    const buyingDeals =
      gqlDeals.data?.user.company.buyingDeals
        .filter((deal) => deal.state.status === DealStatus.COMPLETE)
        .filter(isNotNullOrUndefined) ?? [];

    const mergedArr = [...sellingDeals, ...buyingDeals];

    const uniqueDeals = Array.from(
      new Set(mergedArr.map((deal) => deal.id)),
    ).map((id) => mergedArr.find((deal) => deal.id === id));

    return uniqueDeals.map((item) => ({
      ...item?.listing,
      deals: [item],
    })) as unknown as WithSubData<GetDealsData_user_company_sellingDeals>[];
  }, [gqlDeals.data]);

  const { onDataFound } = props;
  useEffect(() => {
    onDataFound(completeDeals.length > 0 || listingsToShow.length > 0);
  }, [listingsToShow.length, completeDeals.length, onDataFound]);

  const [selectedListings, setSelectedListings] = useState<RowSelectionState>(
    {},
  );

  const theme = useTheme();
  return (
    <>
      <ErrorMessage err={gql.error} />
      <DataDisplayTable
        data={completeDeals}
        // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
        columns={activeColumnDefinitions}
        noDataMessage="NO DATA"
        selection={{
          selected: selectedListings,
          onSelectionChange: setSelectedListings,
        }}
        stylingOptions={{
          alternateLightRows: false,
          subRowColor: theme.color.black,
          rowColor: theme.color.gray900,
        }}
      />
      <Spinner
        loading={gql.loading || gql.networkStatus === NetworkStatus.loading}
      />
    </>
  );
};

const ExecutedOverview = (): JSX.Element => {
  const [foundData, setFoundData] = useState(true);
  const gqlDeals = useQuery<GetDealsData>(GET_All_DEALS_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  return (
    <>
      <div className="page mx-4">
        <ActiveListings gqlDeals={gqlDeals} onDataFound={setFoundData} />
        {!foundData && (
          <div className="flex justify-center">
            Nothing to show. Check the marketplace for loan pools, or publish
            your own
          </div>
        )}
      </div>
    </>
  );
};

export { ExecutedOverview };
