import { useMemo, useEffect } from 'react';

import { FilterValue, filtersToQueryFilters } from 'features/common-elements';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import {
  PortfolioFilterConfig,
  useFilterConfig,
} from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import useTableQueryParams from 'hooks/useTableQueryParams';

import { useQuery } from '@apollo/client';

import {
  AssetClass,
  SortDirection,
  SortableField,
  ParentType,
} from '__generated__/globalTypes';

import {
  GetDealDiligence,
  GetDealDiligenceVariables,
} from 'query/__generated__/GetDealDiligence';
import { GET_DEAL_DILIGENCE } from 'query/diligence';

const POLLING_INTERVAL = 5000;

export const useLoansSelectedForDiligence = (
  dealId: string,
  assetClass: AssetClass,
) => {
  const { isDocumentSelectedForView } = useDealRoomContext();

  const { params: queryParams, updateParams } = useTableQueryParams<
    FilterValue<PortfolioFilterConfig>,
    SortableField
  >();

  const portfolioFiltersConfig = useFilterConfig(assetClass, {
    parentType: ParentType.DEAL,
    parentId: dealId,
  });

  const queryFilters = useMemo(() => {
    return portfolioFiltersConfig
      ? filtersToQueryFilters<PortfolioFilterConfig>(
          queryParams.filters,
          portfolioFiltersConfig,
        )
      : [];
  }, [queryParams.filters, portfolioFiltersConfig]);

  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery<
    GetDealDiligence,
    GetDealDiligenceVariables
  >(GET_DEAL_DILIGENCE, {
    variables: {
      deal_id: dealId,
      sort: {
        sort_direction: queryParams.sortings[0]?.desc
          ? SortDirection.desc
          : SortDirection.asc,
        sort_field: queryParams.sortings[0]?.id,
      },
      pagination: {
        offset: (queryParams.pageNumber - 1) * queryParams.pageSize,
        page_size: queryParams.pageSize,
      },
      filters: queryFilters,
    },
    // TODO(kentskinner): figure out what this should be.
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (isDocumentSelectedForView) {
      stopPolling(); // Stop polling when a document IN DOCUMENTS PREVIW  is selected
    } else {
      startPolling(POLLING_INTERVAL); // Start polling if no document IN DOCUMENTS PREVIW is selected
    }
  }, [isDocumentSelectedForView, startPolling, stopPolling]);

  return { loading, error, data, refetch, queryParams, updateParams };
};
