import { ForwardedRef, forwardRef } from 'react';

import { getAttrToShowTooltip } from 'common-ui/tooltip/generalTooltip';
import { truncate } from 'lodash';
import { styled } from 'style/ORSNNTheme';

import { PillButton, PillButtonProps } from '../Buttons/PillButton/PillButton';

export type ComboDropdownValuePillProps = {
  description: string;
  iconName?: string;
  valueElipses?: number;
  iconOnClick?: () => void;
};

export const ComboDropdownValuePill = forwardRef(
  (
    {
      valueElipses,
      value,
      className,
      ...props
    }: PillButtonProps & { valueElipses?: number; className?: string },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const tooltipProps = valueElipses
      ? getAttrToShowTooltip({ content: value || '' })
      : {};

    const truncatedValue = valueElipses
      ? truncate(value, { length: valueElipses })
      : value;
    const classes = `${className} w-fit outline-none focus:border focus:border-pink-500 [&.is-active]:border [&.is-active]:border-pink-500 [&.is-focused]:border [&.is-focused]:border-pink-500`;
    return (
      <PillButton
        className={classes}
        ref={ref}
        {...props}
        {...tooltipProps}
        value={truncatedValue}
      />
    );
  },
);
