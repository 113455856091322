import React, { ChangeEvent, useEffect, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { gql, useMutation } from '@apollo/client';

import { DealRole } from '../../../../../__generated__/globalTypes';
import { Button, UiInput } from '../../../../../ui-kit';
import { GetDealGeneralInfo_deal_listing_UserCompanyListing_seller } from '../../../dealCards/gql/__generated__/GetDealGeneralInfo';
import { GET_DEAL_TIMELINE } from '../../fragments';
import {
  SaveWireDetailsPrices,
  SaveWireDetailsPricesVariables,
} from './__generated__/SaveWireDetailsPrices';
import { SettlementCard_SettlementCard_prices } from './__generated__/SettlementCard';
import {
  SetWireDetailsPricesVisible,
  SetWireDetailsPricesVisibleVariables,
} from './__generated__/SetWireDetailsPricesVisible';

export type SettlementPricesProps = {
  dealId: string;
  prices: SettlementCard_SettlementCard_prices;
  role: DealRole;
  is_complete: boolean;
  seller: GetDealGeneralInfo_deal_listing_UserCompanyListing_seller;
};

const SET_WIRE_DETAILS_PRICES_VISIBLE_MUTATION = gql`
  mutation SetWireDetailsPricesVisible(
    $input: SetWireDetailsPricesVisibleInput!
  ) {
    setWireDetailsPricesVisible(input: $input) {
      cleanPriceCents
      dirtyPriceCents
      isVisibleToBuyer
    }
  }
`;

const SAVE_WIRE_DETAILS_PRICES_MUTATION = gql`
  mutation SaveWireDetailsPrices($input: SaveWireDetailsPricesInput!) {
    saveWireDetailsPrices(input: $input) {
      cleanPriceCents
      dirtyPriceCents
      isVisibleToBuyer
    }
  }
`;

export const SettlementPrices = ({
  prices,
  dealId,
  role,
  seller,
}: SettlementPricesProps) => {
  // State to hold price inputs as dollars (string format)
  const [cleanPrice, setCleanPrice] = useState('');
  const [dirtyPrice, setDirtyPrice] = useState('');

  const [setWireDetailsPricesVisible] = useMutation<
    SetWireDetailsPricesVisible,
    SetWireDetailsPricesVisibleVariables
  >(SET_WIRE_DETAILS_PRICES_VISIBLE_MUTATION);

  const [saveWireDetailsPrices] = useMutation<
    SaveWireDetailsPrices,
    SaveWireDetailsPricesVariables
  >(SAVE_WIRE_DETAILS_PRICES_MUTATION);

  const handleSendPrices = async () => {
    try {
      await setWireDetailsPricesVisible({
        variables: { input: { dealID: dealId } },
        refetchQueries: [
          { query: GET_DEAL_TIMELINE, variables: { id: dealId } },
        ],
      });
    } catch (error) {
      console.error('Error sending prices', error);
    }
  };

  // When prices change in props, update our input fields (if prices are not yet sent)
  useEffect(() => {
    if (!prices.isVisibleToBuyer) {
      setCleanPrice(
        prices?.cleanPriceCents
          ? (prices.cleanPriceCents / 100).toFixed(2)
          : '0',
      );
      setDirtyPrice(
        prices?.dirtyPriceCents
          ? (prices.dirtyPriceCents / 100).toFixed(2)
          : '0',
      );
    }
  }, [prices]);

  const [debouncedCleanPrice] = useDebounce(cleanPrice, 500);
  const [debouncedDirtyPrice] = useDebounce(dirtyPrice, 500);

  useEffect(() => {
    if (!prices || prices.isVisibleToBuyer) return;
    const cp = parseFloat(debouncedCleanPrice);
    const dp = parseFloat(debouncedDirtyPrice);
    if (!isNaN(cp) && !isNaN(dp)) {
      const cleanCents = Math.round(cp * 100);
      const dirtyCents = Math.round(dp * 100);
      if (
        cleanCents !== prices.cleanPriceCents ||
        dirtyCents !== prices.dirtyPriceCents
      ) {
        saveWireDetailsPrices({
          variables: {
            input: {
              dealID: dealId,
              cleanPriceCents: cleanCents,
              dirtyPriceCents: dirtyCents,
            },
          },
        });
      }
    }
  }, [
    debouncedCleanPrice,
    debouncedDirtyPrice,
    prices,
    dealId,
    saveWireDetailsPrices,
  ]);

  return (
    <div className="mt-4 w-full ">
      {role === 'BUYER' && !prices?.isVisibleToBuyer && (
        <p className="rounded-sm bg-blue-600 p-4">
          The seller is currently preparing the necessary documentation for
          e-signatures and finalizing the loan tape for reconciliation after due
          diligence. You will receive a notification once everything is ready.
          Thank you for your patience.
        </p>
      )}
      {prices.isVisibleToBuyer && (
        <div className="text-white">
          <p>
            Clean Price: $ {((prices?.cleanPriceCents || 0) / 100).toFixed(2)}
          </p>
          <p>
            Dirty Price: $ {((prices?.dirtyPriceCents || 0) / 100).toFixed(2)}
          </p>
        </div>
      )}

      {role === 'SELLER' && !prices?.isVisibleToBuyer && (
        <div className="flex flex-col gap-2">
          <div className="flex w-full items-center justify-items-start">
            <label className="block w-[120px] text-[12px] uppercase leading-[20px] text-white">
              Clean Price
            </label>
            <UiInput
              type="number"
              suffix="$"
              value={cleanPrice}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCleanPrice(e.target.value)
              }
              error={prices.cleanPriceCents === null}
              size={'small'}
              variant={'light'}
            />
          </div>
          <div className="flex w-full items-center">
            <label className="block w-[120px] text-[12px] uppercase leading-[20px] text-white">
              Dirty Price
            </label>
            <UiInput
              type="number"
              suffix="$"
              value={dirtyPrice}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDirtyPrice(e.target.value)
              }
              error={prices.dirtyPriceCents === null}
              size={'small'}
              variant={'light'}
            />
          </div>
          <Button
            label="prices"
            size="small"
            onClick={handleSendPrices}
            className="w-[150px]"
            disabled={!prices?.cleanPriceCents && !prices?.dirtyPriceCents}
            // disabled={!parseInt(cleanPrice) && !parseInt(dirtyPrice)}
          >
            Send Prices
          </Button>
        </div>
      )}
    </div>
  );
};
