import { IconInfoCircle } from '@tabler/icons-react';
import { ColumnDef } from '@tanstack/react-table';

import { ModalNotStyled } from '../../common-ui';
import { LoanDatatableLoan as Loan } from '../../features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';
import { IconButton } from '../Buttons';
import { Checkbox } from '../Checkbox';
import { RadioButton } from '../RadioButton';
import { DetailsCard } from './DetailsCard';

export const radioButtonSelectionColumn: ColumnDef<unknown> = {
  id: 'selection',
  size: 40,
  cell: (cellContext) => (
    <RadioButton
      label={''}
      variant={cellContext.row.getIsSelected() ? 'default' : 'primary'}
      checked={cellContext.row.getIsSelected()}
      onChange={cellContext.row.getToggleSelectedHandler()}
    />
  ),
  meta: {
    headerNoPill: true,
    isDraggable: false,
    canResize: false,
  },
};

export const createCheckBoxSelectionColumn = (
  onHeaderCheckboxChange: (checked: boolean) => void,
): ColumnDef<Loan> => {
  return {
    id: 'selection',
    size: 40,
    cell: (cellContext) => {
      const loan = cellContext.row.original;
      const account_id = loan?.account_id || '';
      return (
        <div className="flex gap-4">
          <Checkbox
            checked={cellContext.row.getIsSelected()}
            onChange={cellContext.row.getToggleSelectedHandler()}
            disabled={!cellContext.row.getCanSelect()}
          />
          <ModalNotStyled
            trigger={
              <div>
                <IconButton
                  variant="table-info"
                  className="h-9 w-9"
                  icon={<IconInfoCircle color="#757575" height="24px" />}
                />
              </div>
            }
          >
            {({ closeModal }) => (
              <DetailsCard
                selectedLoanId={account_id}
                companyId={loan.company.id}
                onClose={closeModal}
              />
            )}
          </ModalNotStyled>
        </div>
      );
    },
    header: (headerContext) => {
      if (headerContext.table.options.enableMultiRowSelection !== false) {
        const originalOnChangeHandler =
          headerContext.table.getToggleAllRowsSelectedHandler();

        const handleChange = (checked: boolean) => {
          // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          // Call the original onChange handler from TanStack Table
          originalOnChangeHandler({
            target: {
              checked,
            },
          });
          onHeaderCheckboxChange(checked);
        };
        const isChecked = headerContext.table.getIsAllRowsSelected();
        const indeterminate = headerContext.table.getIsSomeRowsSelected();

        return (
          <Checkbox
            checked={!isChecked && indeterminate}
            icon="minus"
            onChange={() => handleChange(!isChecked)}
          />
        );
      }
      return null;
    },
    meta: {
      headerNoPill: true,
      isDraggable: false,
    },
  };
};
