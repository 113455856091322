import React, { useEffect, useState } from 'react';

import { IconCheck, IconFileTypePdf, IconNote } from '@tabler/icons-react';
import { KIND } from 'baseui/button';
import { CheckboxWithLabel } from 'common-ui';
import { Dialog, DialogProps, useDialog } from 'common-ui/Dialog';
import { DateTime } from 'luxon';

import { gql, useApolloClient, useMutation } from '@apollo/client';

import { DealMessageAuthorRole, FileType } from '__generated__/globalTypes';

import {
  ConfirmWireTransfer,
  ConfirmWireTransferVariables,
} from 'mutation/__generated__/ConfirmWireTransfer';
import {
  SentWireTransfer,
  SentWireTransferVariables,
} from 'mutation/__generated__/SentWireTransfer';
import { CONFIRM_WIRE_TRANSFER } from 'mutation/confirmWireTransfer';
import { SENT_WIRE_TRANSFER } from 'mutation/sentWireTransfer';

import { uploadConfig } from '../../../../../app-level/config/uploadConfig';
import {
  Button,
  ButtonWithIcon,
  Checkbox,
  FileListRow,
  IconButton,
  LinkButton,
} from '../../../../../ui-kit';
import FileUpload from '../../../../pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import { DealStagesDeal_state } from '../../__generated__/DealStagesDeal';
import { GET_DEAL_TIMELINE } from '../../fragments';
import {
  AddWireDetailsNote,
  AddWireDetailsNoteVariables,
} from './__generated__/AddWireDetailsNote';
import {
  WireConfirmationCard_WireConfirmationCard,
  WireConfirmationCard_WireConfirmationCard_files_with_notes,
} from './__generated__/WireConfirmationCard';
import { WireConfirmationCard_WireConfirmationCard_files_with_notes_notes } from './__generated__/WireConfirmationCard';

const GET_WIRE_DETAILS_DOWNLOAD_URL = gql`
  query GetWireDetailsFileDownloadURL(
    $input: WireDetailsFileDownloadURLInput!
  ) {
    getWireDetailsFileDownloadURL(input: $input) {
      URL
    }
  }
`;

const REMOVE_WIRE_DETAILS_FILE_MUTATION = gql`
  mutation RemoveWireDetailsFile($input: RemoveWireDetailsFileInput!) {
    removeWireDetailsFile(input: $input) {
      message
    }
  }
`;

const ADD_WIRE_DETAILS_NOTE = gql`
  mutation AddWireDetailsNote($input: AddWireDetailsNoteInput!) {
    addWireDetailsNote(input: $input) {
      id
      note
    }
  }
`;

interface WireFile {
  fileName: string;
  s3Key: string;
  deal: { id: string };
  notes: WireConfirmationCard_WireConfirmationCard_files_with_notes_notes[];
}

// A modal dialog for reviewing and adding notes on a wire details file.
interface WireDetailsNotesDialogProps {
  companyId: string;
  userRole: DealMessageAuthorRole;
  file: WireConfirmationCard_WireConfirmationCard_files_with_notes;
  dealId: string;
  dialog: DialogProps;
  onNoteAdded: () => void;
}

const WireDetailsNotesDialog: React.FC<WireDetailsNotesDialogProps> = ({
  companyId,
  userRole,
  file,
  dealId,
  dialog,
  onNoteAdded,
}) => {
  const [notes, setNotes] = useState<
    WireConfirmationCard_WireConfirmationCard_files_with_notes_notes[]
  >(file.notes);
  const [noteText, setNoteText] = useState('');
  const [addWireNote, { loading }] = useMutation<
    AddWireDetailsNote,
    AddWireDetailsNoteVariables
  >(ADD_WIRE_DETAILS_NOTE);

  useEffect(() => {
    setNotes(file.notes);
  }, [file.notes]);

  const handleAddNote = () => {
    if (!noteText.trim()) return;
    const tempId = 'temp-' + Date.now();
    const optimisticNote: WireConfirmationCard_WireConfirmationCard_files_with_notes_notes =
      {
        id: tempId,
        note: noteText,
        authorRole: userRole,
        authorCompany: companyId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
    setNotes((prev) => [...prev, optimisticNote]);
    setNoteText('');
    addWireNote({
      variables: {
        input: {
          dealID: dealId,
          fileName: file.fileName,
          note: noteText,
        },
      },
      optimisticResponse: {
        addWireDetailsNote: {
          id: tempId,
          note: noteText,
        },
      },
    })
      .then((result) => {
        if (result.data?.addWireDetailsNote) {
          const realNote = result.data.addWireDetailsNote;
          setNotes((prev) =>
            prev.map((n) => (n.id === tempId ? { ...n, id: realNote.id } : n)),
          );
          onNoteAdded();
        }
      })
      .catch((error) => {
        setNotes((prev) => prev.filter((n) => n.id !== tempId));
        console.error('Error adding note', error);
      });
  };

  return (
    <Dialog dialog={dialog}>
      <button
        className="absolute right-2 top-2 text-gray-500 hover:text-gray-300"
        onClick={() => dialog.setIsOpen(false)}
      >
        ✕
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold">
        Notes for “{file.fileName}”
      </h2>
      <div className="mt-4 flex max-h-96 flex-col gap-2 overflow-y-auto">
        {notes.map((note) => {
          const align =
            note.authorRole === 'BUYER' ? 'justify-end' : 'justify-start';
          const textAlign =
            note.authorRole === 'BUYER' ? 'text-right' : 'text-left';
          return (
            <div key={note.id} className={`flex ${align}`}>
              <div className={`max-w-[80%] ${textAlign}`}>
                <div className="text-gray-500">{note.authorRole}</div>
                <div className="font-pt-sans-narrow text-xs text-slate-200">
                  {DateTime.fromISO(note.createdAt).toLocaleString(
                    DateTime.DATETIME_MED,
                  )}
                </div>
                <div className="text-sm">{note.note}</div>
              </div>
            </div>
          );
        })}
      </div>
      <textarea
        className="my-4 h-28 w-full rounded-md border border-pink-400 bg-background-surface p-2 text-sm text-foreground-default focus:border-pink-400 focus:outline-none"
        aria-label="Add Note"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <div className="flex justify-between gap-2">
        <Button
          type="secondary"
          size="medium"
          label="Cancel"
          className="px-[12px] py-[10px]"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={!noteText}
          size="medium"
          label="Add Note"
          className="px-[12px] py-[10px]"
          onClick={handleAddNote}
        >
          Add Note
        </Button>
      </div>
    </Dialog>
  );
};

// The main card body component.
type WireConfirmationCardBodyProps = {
  dealId: string;
  dealState: DealStagesDeal_state;
} & WireConfirmationCard_WireConfirmationCard;

const WireConfirmationCardBody: React.FC<WireConfirmationCardBodyProps> = (
  props,
) => {
  const [isSelected, setIsSelected] = useState(false);
  const [sendWireConfirmation] = useMutation<
    SentWireTransfer,
    SentWireTransferVariables
  >(SENT_WIRE_TRANSFER);
  const [confirmWireTransfer] = useMutation<
    ConfirmWireTransfer,
    ConfirmWireTransferVariables
  >(CONFIRM_WIRE_TRANSFER);

  const [removeWireDetailsFile] = useMutation(
    REMOVE_WIRE_DETAILS_FILE_MUTATION,
  );

  const client = useApolloClient();

  const dialog = useDialog();

  const shouldShowCheckbox = props.is_wire_sent;
  const optionalCheckbox = (
    <Checkbox
      checked={isSelected}
      onChange={() => setIsSelected((previous) => !previous)}
      label={
        props.user_role === 'BUYER'
          ? 'I have completed the transaction'
          : 'I confirm the transaction has been completed'
      }
    />
  );

  const handleSubmit = () => {
    if (isSelected && props.user_role === 'BUYER') {
      sendWireConfirmation({
        variables: {
          input: {
            deal_id: props.dealId,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: { id: props.dealId },
          },
        ],
      });
    } else if (isSelected && props.user_role === 'SELLER') {
      confirmWireTransfer({
        variables: {
          input: {
            deal_id: props.dealId,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: { id: props.dealId },
          },
        ],
      });
    }
  };

  const wireDetails = props.wire_details;

  // When a file is uploaded or a note is added, refetch the deal timeline.
  const refetchTimeline = () => {
    client.refetchQueries({
      include: [GET_DEAL_TIMELINE],
    });
  };

  // Download a file by fetching its presigned URL and opening it.
  const handleDownload = async (file: WireFile) => {
    try {
      const { data } = await client.query({
        query: GET_WIRE_DETAILS_DOWNLOAD_URL,
        variables: {
          input: {
            dealID: props.dealId,
            fileName: file.fileName,
          },
        },
        fetchPolicy: 'network-only',
      });
      if (
        data &&
        data.getWireDetailsFileDownloadURL &&
        data.getWireDetailsFileDownloadURL.URL
      ) {
        window.open(data.getWireDetailsFileDownloadURL.URL, '_blank');
      } else {
        console.error('Download URL not found');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  // Remove a file if the user is the seller.
  const handleRemove = async (file: WireFile) => {
    try {
      await removeWireDetailsFile({
        variables: {
          input: {
            dealID: props.dealId,
            fileName: file.fileName,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: { id: props.dealId },
          },
        ],
      });
    } catch (error) {
      console.error('Error removing file:', error);
    }
  };

  // Local state to control which file’s notes dialog is open.
  const [selectedFileForNotes, setSelectedFileForNotes] =
    useState<WireFile | null>(null);

  const disabledFilesEdit =
    props.dealState.status === 'COMPLETE' ||
    props.dealState.status === 'WIRE_TRANSFER_SENT' ||
    props.dealState.status === 'WIRE_TRANSFER_CONFIRMED';

  return (
    <div className="flex flex-wrap gap-6">
      {props.user_role === 'SELLER' && (
        <div className="w-full">
          <FileUpload
            parentId={props.dealId}
            fileType={FileType.OTHER}
            allowedFileTypes={
              uploadConfig.wireConfirmationDocuments.allowedFileTypes
            }
            onFileSuccess={refetchTimeline}
            isWire={true}
            width="auto"
            showButtons={false}
            uploadedBy={props.user_id}
            timeToHideProgress={2}
            isCollateral={true}
          />
        </div>
      )}
      <div className="w-full">
        <h3 className="mb-2 text-lg font-semibold">Uploaded Files</h3>
        <ul className="divide-y divide-slate-600">
          {props.files_with_notes.map((file, index) => (
            <FileListRow key={index} disabled={disabledFilesEdit}>
              <div className="w-[70%] truncate px-2 text-slate-200 ">
                {file.fileName}
              </div>
              <div className="flex gap-2">
                <LinkButton
                  icon={<IconFileTypePdf size={16} />}
                  text="Download"
                  iconPosition="before"
                  onClick={() => handleDownload(file)}
                  className="text-slate-200"
                  disabled={disabledFilesEdit}
                />

                <IconButton
                  onClick={() => {
                    setSelectedFileForNotes(file);
                    dialog.openDialog();
                  }}
                  variant="icon-only"
                  disabled={disabledFilesEdit}
                  className="hover:bg-none"
                  icon={
                    <IconNote
                      stroke={2}
                      className={`${file.notes.length ? 'text-warning-default' : 'text-white'}`}
                    />
                  }
                />

                {props.user_role === 'SELLER' && (
                  <LinkButton
                    text="Remove"
                    onClick={() => handleRemove(file)}
                    disabled={disabledFilesEdit}
                  />
                )}
              </div>
            </FileListRow>
          ))}
        </ul>
      </div>

      {wireDetails && (
        <>
          <div className="flex-1">
            <ul className="m-0 list-none p-0">
              <li className="flex justify-between border-t border-slate-600 px-3 py-2">
                <span className="text-gray-800">Account Name</span>
                <span className="text-slate-100">
                  {wireDetails.account_name}
                </span>
              </li>
              <li className="flex justify-between border-t border-slate-600 px-3 py-2">
                <span className="text-gray-800">Account Number</span>
                <span className="text-slate-100">
                  {wireDetails.account_number}
                </span>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <ul className="m-0 list-none p-0">
              <li className="flex justify-between border-t border-slate-600 px-3 py-2">
                <span className="text-gray-800">Bank Name</span>
                <span className="text-slate-100">{wireDetails.bank_name}</span>
              </li>
              <li className="flex justify-between border-t border-slate-600 px-3 py-2">
                <span className="text-gray-800">Routing Number</span>
                <span className="text-slate-100">
                  {wireDetails.routing_number}
                </span>
              </li>
            </ul>
          </div>
        </>
      )}
      {props.user_role === 'BUYER' && (
        <>
          {!shouldShowCheckbox && !!props.files_with_notes.length && (
            <>
              <div className="mt-4 w-full">{optionalCheckbox}</div>
              <div className="mt-4 w-full">
                <ButtonWithIcon
                  disabled={!isSelected}
                  onClick={handleSubmit}
                  iconPosition="right"
                  icon={<IconCheck size="18px" />}
                  label="Submit transfer"
                />
              </div>
            </>
          )}

          {shouldShowCheckbox && (
            <div>
              {props.dealState.status !== 'COMPLETE'
                ? 'Wire Transfer sent successfully. The seller has to submit it.'
                : 'Wire Transfer completed successfully'}
            </div>
          )}
        </>
      )}

      {!shouldShowCheckbox &&
        props.user_role === 'SELLER' &&
        !!props.files_with_notes.length && (
          <div>BUYER has to submit the transfer</div>
        )}

      {!shouldShowCheckbox && !props.files_with_notes.length && (
        <div>
          {props.user_role === 'SELLER'
            ? 'Upload the files'
            : 'Waiting for SELLER uploads the files'}
        </div>
      )}

      {props.dealState.status === 'COMPLETE' &&
        props.user_role === 'SELLER' && (
          <div>Wire Transfer completed successfully</div>
        )}

      {shouldShowCheckbox &&
        props.user_role === 'SELLER' &&
        props.dealState.status !== 'COMPLETE' && (
          <>
            <div className="mt-4 w-full">{optionalCheckbox}</div>
            <div className="mt-4 w-full">
              <ButtonWithIcon
                disabled={!isSelected}
                onClick={handleSubmit}
                iconPosition="right"
                icon={<IconCheck size="18px" />}
                label="Mark Deal Complete"
              />
            </div>
          </>
        )}

      {selectedFileForNotes && (
        <WireDetailsNotesDialog
          companyId={props.company_id}
          userRole={props.user_role}
          file={selectedFileForNotes}
          dealId={props.dealId}
          dialog={dialog}
          onNoteAdded={refetchTimeline}
        />
      )}
    </div>
  );
};

export default WireConfirmationCardBody;
