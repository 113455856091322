import * as navigation from 'app-level/navigation';
import _ from 'lodash';
import { ASSET_CLASS_IDS } from 'types/assetClass';

import { ParentType } from '__generated__/globalTypes';

import { useFilterConfig } from './portfolioFilters.config';

export function usePortfolioFilters() {
  const assetClass = navigation.usePortfolioAssetClass();
  const filtersConfig = useFilterConfig(
    assetClass && ASSET_CLASS_IDS[assetClass],
    { parentType: ParentType.COMPANY },
  );

  const [currentFilters, setCurrentFilters] = navigation.usePortfolioFilters();
  const { poolFromUrl, setPool } = navigation.usePoolUrl();

  return {
    filtersConfig,
    currentFilters,
    setCurrentFilters,
    poolFromUrl,
    setPool,
  };
}
