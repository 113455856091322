import { IconX } from '@tabler/icons-react';
import { Spinner } from 'common-ui';
import { FIELD_CONFIG } from 'configs/columns/index';

import { useQuery } from '@apollo/client';

import {
  GetLoanAccount,
  GetLoanAccountVariables,
} from 'query/__generated__/GetLoanAccount';
import { GET_LOAN } from 'query/loanDetails';

interface PassedProps {
  selectedLoanId: string;
  companyId: string;
  onClose: () => void;
}

export const DetailsCard = ({
  selectedLoanId,
  companyId,
  onClose,
}: PassedProps) => {
  const { loading, data } = useQuery<GetLoanAccount, GetLoanAccountVariables>(
    GET_LOAN,
    {
      variables: {
        accountId: (selectedLoanId as unknown as string) || '',
        companyId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const loanData = data?.account;

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <div className="shadow-custom-xl relative min-w-[712px] rounded-[6px] border border-pink-800 bg-gray-950 p-10 px-9">
      <div className="p-3 text-[18px] text-white">{loanData?.account_id}</div>
      <div className="absolute right-8 top-10 cursor-pointer" onClick={onClose}>
        <IconX size={20} stroke={2} color="#eeeeee" className="opacity-60" />
      </div>
      <div className="m-0 grid grid-cols-2 gap-x-4 p-0">
        {loanData &&
          Object.keys(loanData)?.map((field) => {
            const fieldConfig =
              FIELD_CONFIG[field as keyof typeof FIELD_CONFIG];

            if (!fieldConfig) return null;

            const { string, display } = fieldConfig;
            const fieldValue = loanData[field as keyof typeof loanData];

            return string != null && display != null ? (
              <div
                className="flex list-none justify-between border-t-[1px] border-t-slate-500 py-2 leading-6"
                key={string}
              >
                <div className="text-[14px] text-slate-400">{string}</div>
                {
                  <div className="font-mono text-gray-50">
                    {field === 'listing'
                      ? loanData.listing?.name
                      : // @ts-ignore display is a function that can take this value
                        display(fieldValue)}
                  </div>
                }
              </div>
            ) : null; // return null instead of '' to be React-friendly
          })}
      </div>
    </div>
  );
};
