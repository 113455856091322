import { FC, useState } from 'react';

import { BaseButton } from 'common-ui';
import { formatCurrency, formatPercent } from 'configs/columns';
import RequestAccess from 'features/OnMarket/RequestAccess';
import { RequestAccessFragments } from 'features/OnMarket/RequestAccess.fragments';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'ui-kit';

import { gql, useQuery } from '@apollo/client';

import {
  MarketplaceBlotterQuery,
  MarketplaceBlotterQuery_listings,
} from './__generated__/MarketplaceBlotterQuery';
import { MarketplaceBlotterSkeleton } from './MarketplaceBlotterSkeleton';
import { GetUserInfo } from '../../../../query/__generated__/GetUserInfo';
import { GET_USER_INFO } from '../../../../query/userData';

const MARKETPLACE_BLOTTER_QUERY = gql`
  query MarketplaceBlotterQuery {
    listings(
      sort: { sort_field: created_date_seconds, sort_direction: desc }
      pagination: { page_size: 3, offset: 0 }
    ) {
      id
      provided_name
      asset_class
      wa_coupon
      t_current_balance_cents
      ... on PublicListing {
        ...RequestAccessPublicListing
      }
    }
  }
  ${RequestAccessFragments.PublicListing}
`;

export const MarketplaceBlotter: FC = () => {
  const navigate = useNavigate();
  const {
    data,
    loading,
    error: queryError,
  } = useQuery<MarketplaceBlotterQuery>(MARKETPLACE_BLOTTER_QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState<
    null | MarketplaceBlotterQuery['listings'][0]
  >(null);

  const { data: userData } = useQuery<GetUserInfo>(GET_USER_INFO);

  if (loading) {
    return <MarketplaceBlotterSkeleton />;
  }

  if (queryError) {
    return <div>Error loading listings</div>;
  }

  const listings: MarketplaceBlotterQuery_listings[] =
    data?.listings.slice(0, 3) || [];

  const handleLearnMoreClick = () => {
    navigate('/marketplace/active_trades');
  };

  const handleListingClick = (
    listing: MarketplaceBlotterQuery['listings'][0],
  ) => {
    setSelectedListing(listing);
    setIsModalOpen(true);
  };
  return (
    <>
      <h2 className="mb-4 font-heebo text-lg font-medium text-white">
        Marketplace Blotter
      </h2>
      <div className="flex overflow-x-auto">
        {listings.map((listing) => (
          <div
            key={listing.id}
            className="box-border max-w-[220px] flex-1 flex-shrink-0 transform cursor-pointer rounded border-r border-slate-500 bg-background-canvas p-3 text-white transition-all duration-150 will-change-transform last:border-r-0 hover:border hover:border-accent-default hover:bg-slate-900 hover:ring-1 hover:ring-accent-default"
            onClick={() => handleListingClick(listing)}
          >
            <div className="max-w-48 truncate overflow-ellipsis font-medium text-purple-300">
              {listing.provided_name}
            </div>
            <div>RESI</div>
            <div className="flex max-w-48 justify-between font-bold">
              <span className="whitespace-nowrap text-left">GWAC</span>
              <span className="whitespace-nowrap text-right">
                {formatPercent(listing.wa_coupon ?? 0, 3)}
              </span>
            </div>
            <div className="flex max-w-48 justify-between text-green-500">
              <span className="whitespace-nowrap text-left">UPB</span>
              <span className="whitespace-nowrap text-right">
                {formatCurrency(listing.t_current_balance_cents)}
              </span>
            </div>
          </div>
        ))}
      </div>
      {listings.length > 3 && (
        <div className="mt-1 flex justify-end">
          <BaseButton
            size="small"
            type="secondary"
            onClick={handleLearnMoreClick}
            label="Learn more"
          >
            Learn More
          </BaseButton>
        </div>
      )}
      {isModalOpen && selectedListing && (
        <Modal
          headerText=""
          setIsOpen={setIsModalOpen}
          type="request-access"
          subText=""
          caption=""
          width="100%"
          className="max-h-[540px] w-full max-w-[985px] overflow-y-auto rounded-lg border border-pink-500 bg-background-canvas p-6 font-heebo text-white shadow-lg"
        >
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold">
              {selectedListing.provided_name}
            </h2>
            <button
              className="text-gray-500 hover:text-gray-300"
              onClick={() => setIsModalOpen(false)}
            >
              ✕
            </button>
          </div>
          <RequestAccess
            listingId={selectedListing.id}
            listingDetails={selectedListing}
            dealId={selectedListing.in_progress_deal_id}
            user={userData?.user}
          />
        </Modal>
      )}
    </>
  );
};
