import { ReactNode, VFC } from 'react';

import { Button } from 'ui-kit/Button';

export interface EmptyAction {
  label: ReactNode;
  onClick: () => void;
}

interface EmptyProps {
  message: string;
  action?: EmptyAction;
}

export const Empty: VFC<EmptyProps> = ({ message, action }) => {
  return (
    <div className="relative flex h-full min-h-[100px] w-0 items-center overflow-visible">
      <div className="absolute left-[64px] flex w-max flex-col gap-[17px]">
        <h1 className="text-[18px] leading-[1.33] text-white">{message}</h1>
        {typeof action !== 'undefined' ? (
          <Button
            size="medium"
            type="secondary"
            className="w-[77px]"
            onClick={action.onClick}
          >
            {action.label}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
