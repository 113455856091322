import { useState, useCallback, useEffect, useMemo } from 'react';

import { ChevronDoubleDown } from '@styled-icons/bootstrap/ChevronDoubleDown';
import { Button } from 'baseui/button';
import { FileDropZone } from 'features/common-elements/FileDropZone';
import { useS3Uploader } from 'features/common-elements/useS3Uploader';
import { isUnsecEnabled } from 'features/flags';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'ui-kit';

import {
  AssetClass,
  // CreProductType, TODO will be added during CRE implementation
  FileType,
  ProductType,
} from '__generated__/globalTypes';

import { Card, CardHeading, CardInstructions } from '../styles';
import { AssetClassOptionType } from './AssetClassOptionType';
import UploadHistory, { InProgressFile } from '../components/UploadHistory';

const ALLOWED_MIME_TYPES = [
  'application/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-csv',
  'text/comma-separated-values',
  'text/csv',
  'text/tab-separated-values',
  'text/x-comma-separated-values',
  'text/x-csv',
];

const ENABLED_ASSET_CLASSES = [
  AssetClass.HOME,
  AssetClass.AUTO,
  // TODO will be added during CRE implementation
  // AssetClass.CRE,
  ...(isUnsecEnabled() ? [AssetClass.UNSECPERSONAL] : []),
];

const getAssetsLabelName = (value: string) => {
  switch (value) {
    case AssetClass.HOME:
      return 'Resi';
    default:
      return value;
  }
};

export const assetClassOptions: AssetClassOptionType[] = Object.values(
  AssetClass,
).map((assetClass) => ({
  value: assetClass,
  label: getAssetsLabelName(assetClass.toString()).toUpperCase(),
}));

function useAssetClassAndProductType() {
  const [selectedAssetClass, setSelectedAssetClass] = useState<AssetClass>();
  const [productType, setProductType] = useState<ProductType>(
    ProductType.FIXED,
  );

  const handleAssetClassSelect = useCallback((selectedOption) => {
    setSelectedAssetClass(selectedOption?.value);
  }, []);

  const handleProductTypeSelect = useCallback((selectedOption) => {
    setProductType(selectedOption?.value);
  }, []);

  useEffect(() => {
    setProductType(ProductType.FIXED);
  }, [selectedAssetClass]);

  return {
    selectedAssetClass,
    handleAssetClassSelect,
    productType,
    handleProductTypeSelect,
  };
}

export interface LoanTapeUploaderProps {
  onClickNext?: () => void;
}

export default function LoanTapeUploader(props: LoanTapeUploaderProps) {
  const [uploadsInProgress, setUploadsInProgress] = useState<InProgressFile[]>(
    [],
  );

  const { tapeId } = useParams<{ tapeId: string }>();

  const {
    selectedAssetClass,
    handleAssetClassSelect,
    productType,
    handleProductTypeSelect,
  } = useAssetClassAndProductType();

  const onSuccess = useCallback(
    (file: File, _versionId?: string, uuid?: string) => {
      setUploadsInProgress((prev) => {
        const existingFile = prev.find(
          (f) => f.name === file.name && f.lastModified === file.lastModified,
        );
        if (existingFile) {
          existingFile.id = uuid;
        }
        return [...prev];
      });
    },
    [setUploadsInProgress],
  );

  const onProgress = useCallback(
    (progress: number, file?: File) => {
      if (!file) {
        return;
      }
      setUploadsInProgress((prev) => {
        const existingFile = prev.find(
          (f) => f.name === file.name && f.lastModified === file.lastModified,
        );
        if (existingFile) {
          existingFile.progress = {
            percentage: progress,
          };
        } else {
          prev.push({
            name: file.name,
            lastModified: file.lastModified,
            progress: {
              percentage: progress,
            },
          });
        }
        return [...prev];
      });
    },
    [setUploadsInProgress],
  );

  const { upload } = useS3Uploader({
    fileType: FileType.LOAN_TAPE,
    onSuccess,
    onProgress,
    assetClass: selectedAssetClass,
    productType,
    allowedMimeTypes: ALLOWED_MIME_TYPES,
  });

  const onFileDrop = async (file: File) => {
    try {
      await upload(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const { onClickNext = Function.prototype } = props;

  const onClickNextButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClickNext();
    },
    [onClickNext],
  );

  const productTypeOptions =
    // TODO will be added during CRE implementation
    // selectedAssetClass === AssetClass.CRE
    // ? Object.values(CreProductType).map((creProductType) => ({
    //     value: creProductType,
    //     label: creProductType.toString(),
    //   }))
    // : Object.values(ProductType).map((productType) => ({
    //     value: productType,
    //     label: productType.toString(),
    //   }));
    Object.values(ProductType).map((productType) => ({
      value: productType,
      label: productType.toString(),
    }));

  // TODO will be added during CRE implementation
  // const productTypeSelector =
  //   selectedAssetClass === AssetClass.HOME ||
  //   selectedAssetClass === AssetClass.CRE ? (
  //     <Dropdown
  //       options={productTypeOptions}
  //       onValueChange={handleProductTypeSelect}
  //       value={productType}
  //       placeholder="Select product category..."
  //       className="mt-2 w-[400px]"
  //     />
  //   ) : null;

  const productTypeSelector =
    selectedAssetClass === AssetClass.HOME ? (
      <Dropdown
        options={productTypeOptions}
        onValueChange={handleProductTypeSelect}
        value={productType}
        placeholder="Select product category..."
        className="mt-2 w-[400px]"
      />
    ) : null;

  const shouldShowDropSection =
    selectedAssetClass &&
    (selectedAssetClass === AssetClass.HOME ? productType : true);

  const filteredAssetClassOptions = useMemo(() => {
    return assetClassOptions
      .map((option) => ({
        ...option,
        isDisabled: !ENABLED_ASSET_CLASSES.includes(option.value),
      }))
      .sort((a, b) => {
        if (a.isDisabled && !b.isDisabled) {
          return 1;
        }
        if (!a.isDisabled && b.isDisabled) {
          return -1;
        }
        return 0;
      });
  }, []);

  const selectedOption = filteredAssetClassOptions.find(
    (option) => option.value === selectedAssetClass,
  );

  return (
    <Card>
      <CardHeading>Upload Your Tapes</CardHeading>
      <CardInstructions>
        Upload one or all of your loan tapes for evaluation. Supported file
        types include .csv, .xls, and .xlsx.
      </CardInstructions>
      <div className="flex-col">
        <div className="flex-1">
          <Dropdown
            placeholder="Select asset class for this upload..."
            options={filteredAssetClassOptions}
            onValueChange={handleAssetClassSelect}
            value={getAssetsLabelName(
              selectedOption?.value || '',
            ).toUpperCase()}
            className="mt-2 w-[400px]"
          />
          {productTypeSelector}
          <div className="z-0 my-2 block h-[168px] overflow-hidden text-[14px] text-white">
            {shouldShowDropSection && (
              <FileDropZone
                onFileDrop={onFileDrop}
                height="168px"
                width="auto"
              />
            )}
          </div>
        </div>
      </div>
      <UploadHistory uploadsInProgress={uploadsInProgress} />
      <div className="flex justify-center">
        <Button size="compact" disabled={!tapeId} onClick={onClickNextButton}>
          <ChevronDoubleDown size={20} className="mr-1" />
          Map File Columns to Fields
        </Button>
      </div>
    </Card>
  );
}
