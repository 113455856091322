import { CSSProperties } from 'react';

import { Option } from 'features/common-elements';
import { MultiValue, SingleValue } from 'react-select';

import {
  ComboDropdownValuePill,
  ComboDropdownValuePillProps,
} from './ComboDropdownValuePill';
import {
  PillButtonCombobox,
  PillButtonComboboxProps,
} from './PillButtonCombobox';
import { usePopout, FloatingPortal } from '../hooks/usePopout';

export type DropdownPillComboboxMultiValueProps<T extends string | Option> =
  Omit<
    PillButtonComboboxProps<T, true>,
    'onCloseBtnClick' | 'onValueSelected' | 'isMulti' | 'value'
  > &
    ComboDropdownValuePillProps & {
      value: T[];
      onValueChanged: (value: T[]) => void;
      valueToString: (value: T[]) => string;
      className?: string;
    };

export function DropdownPillComboboxMultiValue<T extends Option>({
  description,
  onValueChanged,
  iconName,
  valueToString,
  value,
  valueElipses,
  iconOnClick,
  className,
  ...comboProps
}: DropdownPillComboboxMultiValueProps<T>) {
  const {
    refs,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    floatingStyles,
    setIsOpen,
  } = usePopout();

  function onValueSelected(newValue: SingleValue<T> | MultiValue<T>) {
    onValueChanged(newValue as T[]);
  }

  return (
    <>
      <ComboDropdownValuePill
        {...getReferenceProps()}
        description={description}
        ref={refs.setReference}
        isActive={isOpen}
        iconName={iconName}
        value={valueToString(value)}
        valueElipses={valueElipses}
        iconOnClick={iconOnClick}
        className={className}
      />
      {isOpen ? (
        <FloatingPortal>
          <div
            className="z-10"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <PillButtonCombobox<T, true>
              {...comboProps}
              value={value}
              isMulti={true}
              onClose={() => setIsOpen(false)}
              onValueSelected={onValueSelected}
            />
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
}
