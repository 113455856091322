import React, { useEffect, useMemo, useState } from 'react';

import { CashFlowsValidValues } from 'features/drilldown/cashflows/configurations/cashflow-inputs';
import { Tab, Tabs } from 'ui-kit';

import { useQuery } from '@apollo/client';

import {
  DefaultAssumptionType,
  PrepaymentAssumptionType,
  RateType,
} from '__generated__/globalTypes';

import {
  GetShortCarves,
  GetShortCarvesVariables,
} from 'query/__generated__/GetShortCarves';
import { GET_CARVES_FOR_BID_DETAILS } from 'query/getCarves';

import CashFlowSummaryDisplay from './CashFlowSummaryDisplay';
import YieldMatrixBidDetails from './YieldMatrixBidDetails';

interface YieldMatrixAndCashflowTabsProps {
  dealId: string;
  bidId: string;
  onPriceSelect: (value: string) => void;
  buyerPrice: number;
}

const YieldMatrixAndCashflowTabs: React.FC<YieldMatrixAndCashflowTabsProps> = ({
  dealId,
  bidId,
  onPriceSelect,
  buyerPrice,
}) => {
  const [formValues, setFormValues] = useState<CashFlowsValidValues>({
    cdr: 25,
    cpr: 10,
    daysDelay: 0,
    lsr: 5,
    servicingRate: 4,
    defaultAssumptionType: DefaultAssumptionType.SDA,
    prepaymentAssumptionType: PrepaymentAssumptionType.PSA,
    rateType: RateType.Sofr,
  });

  const queryVariables = useMemo<GetShortCarvesVariables>(
    () => ({
      deal_id: dealId,
      bid_id: bidId,
      lossSeverityRate: formValues.servicingRate
        ? +formValues.servicingRate / 100
        : 0,
      conditionalPrepaymentRate: +formValues.cpr / 100,
      conditionalDefaultRate: +formValues.cdr / 100,
      prepaymentAssumptionType: formValues.prepaymentAssumptionType,
      defaultAssumptionType: formValues.defaultAssumptionType,
    }),
    [dealId, bidId, formValues],
  );

  const { data, loading, refetch } = useQuery<
    GetShortCarves,
    GetShortCarvesVariables
  >(GET_CARVES_FOR_BID_DETAILS, {
    variables: queryVariables,
  });

  useEffect(() => {
    refetch({ deal_id: dealId, bid_id: bidId, ...formValues });
  }, [formValues, refetch, dealId, bidId]);

  const handleFormChange = (updatedValues: CashFlowsValidValues) => {
    setFormValues(updatedValues);
  };

  const selectedCarve = useMemo(() => data?.deal?.carves?.[0] || null, [data]);
  const selectedCarveSummary = useMemo(
    () => selectedCarve?.carve_summary || null,
    [selectedCarve],
  );

  return (
    <Tabs>
      <Tab label="Yield Matrix">
        <YieldMatrixBidDetails
          onPriceSelect={onPriceSelect}
          formValues={formValues}
          onFormChange={handleFormChange}
          carveSummaryData={selectedCarveSummary}
          loading={loading}
          buyerPrice={buyerPrice}
        />
      </Tab>
      <Tab label="Cashflows">
        <CashFlowSummaryDisplay
          onSubmit={handleFormChange}
          formValues={formValues}
          selectedCarveSummary={selectedCarveSummary}
          loading={loading}
          withServicingRate
        />
      </Tab>
    </Tabs>
  );
};

export default YieldMatrixAndCashflowTabs;
