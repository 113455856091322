import React from 'react';

import classNames from 'classnames';

interface ButtonWithIconProps {
  label: string;
  icon: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  iconPosition?: 'left' | 'right';
}

export const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  label,
  icon,
  onClick,
  disabled = false,
  size = 'medium',
  iconPosition = 'left',
}) => {
  const sizeClasses = {
    small: 'text-[12px] py-2 px-4',
    medium: 'text-[14px] py-2 px-4',
    large: 'text-[16px] py-3 px-6',
    'extra-large': 'text-[20px] py-3 px-8',
  };

  const baseClasses = classNames(
    'inline-flex items-center justify-center rounded-[4px] border transition-colors duration-300 gap-2',
    {
      'border-[#E85EE5] text-[#E85EE5]': !disabled,
      'hover:text-white': !disabled,
      'border-[#424242] text-[#424242] cursor-not-allowed': disabled,
    },
    sizeClasses[size],
  );

  const iconClasses = 'w-4 h-4';

  return (
    <button
      type="button"
      className={baseClasses}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      {iconPosition === 'left' && <span className={iconClasses}>{icon}</span>}
      <span>{label}</span>
      {iconPosition === 'right' && <span className={iconClasses}>{icon}</span>}
    </button>
  );
};
