import React, { useEffect } from 'react';

import { IconInfoCircle } from '@tabler/icons-react';

import { Dropdown, DropdownOption } from '../Dropdown';
import { PageNumberBoxes } from './PageNumberBoxes';

// The backend can only handle 10k loans at a time
const MAXIMUM_TOTAL_ITEMS = 10000;

const rowsPerPageOptions: DropdownOption[] = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

export interface PaginationControlsProps {
  currentPage: number;
  pageSize: number;
  totalItems?: number;
  rowSelected?: number;
  update: (newPage: number, newPageSize: number) => void;
}

export const PaginationControls: React.FC<PaginationControlsProps> = ({
  currentPage,
  pageSize,
  totalItems,
  rowSelected = 0,
  update,
}) => {
  const constrainedTotalItems = totalItems
    ? Math.min(totalItems, MAXIMUM_TOTAL_ITEMS)
    : 0;

  const calculatedTotalPages = constrainedTotalItems
    ? Math.ceil(constrainedTotalItems / pageSize)
    : 0;

  const hasPages = calculatedTotalPages > 1;

  useEffect(() => {
    if (!calculatedTotalPages) {
      return;
    }
    if (currentPage > calculatedTotalPages) {
      if (Math.max(1, calculatedTotalPages) !== currentPage) {
        update(1, pageSize);
      }
    }
  }, [calculatedTotalPages, currentPage, update, pageSize]);

  const handlePageChange = (newPage: number) => {
    update(newPage, pageSize);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number | undefined) => {
    if (!newRowsPerPage) {
      return;
    }
    update(1, newRowsPerPage);
  };

  if (!hasPages) {
    return null;
  }
  const alignClass = rowSelected ? 'justify-between' : 'justify-end';

  return (
    <div
      className={`${alignClass} flex h-[60px] w-full pe-10 ps-5 text-gray-600`}
    >
      {!!rowSelected && (
        <div className="flex items-center justify-items-start gap-2">
          <IconInfoCircle size="18px" />
          {rowSelected} rows selected
        </div>
      )}
      <div className="flex items-center gap-[10px]">
        <div className="flex items-center justify-start gap-2 text-gray-600">
          <span>Rows Per Page</span>
          <Dropdown
            className={'w-[94px] border-none py-[6px]'}
            menuPlacement="top"
            menuClasses="border-none"
            options={rowsPerPageOptions}
            iconColor={'white'}
            value={
              rowsPerPageOptions.find(
                (opt) => opt.value === pageSize.toString(),
              )?.value
            }
            onValueChange={(option) =>
              handleRowsPerPageChange(parseInt(option.value))
            }
            disabled={!calculatedTotalPages}
          />
        </div>
        <PageNumberBoxes
          currentPage={currentPage}
          totalPages={calculatedTotalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
