import React from 'react';

import { KeyCallback, useKeyListener } from './useEnterKeyListener';

interface KeyListenerProps {
  children: React.ReactNode;
  listenerData: KeyCallback[];
}

export const KeyListener = ({ children, listenerData }: KeyListenerProps) => {
  useKeyListener(listenerData);
  return <>{children}</>;
};
