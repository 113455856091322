import React from 'react';

import { ProgressIndicator } from 'common-ui/ProgressIndicator/ProgressIndicator';
import { DateTime } from 'luxon';
import { Link, useParams } from 'react-router-dom';
import { AnimationsEllipsis } from 'ui-kit/Animations/AnimatedEllipsis';

import { ApolloError, gql } from '@apollo/client';

import { FileState, ProductType } from '__generated__/globalTypes';

import { TapesUploadedTableFile } from './__generated__/TapesUploadedTableFile';

const TapesUploadedTableFragments = {
  loanTape: gql`
    fragment TapesUploadedTableFile on File {
      id
      name
      state
      assetClass: asset_class
      productType: product_type
      createdDate: created_date
      original_id
      uploaded_by {
        id
        firstName: given_name
        lastName: family_name
      }
      processingReport: processing_report {
        new_count
        updated_count
        flagged_count
        duplicate_count
      }
    }
  `,
};

type InProgressFile = {
  id?: string;
  name: string;
  lastModified: number;
  progress?: {
    percentage: number;
  };
};

type UploadingCellProps = {
  progress?: number;
};

const UploadingCell: React.FC<UploadingCellProps> = ({ progress }) => {
  if (progress === undefined) {
    return <div className="w-[206px]"></div>;
  }
  return progress === 100 ? (
    <div className="flex flex-1 text-left">
      <div className="bg-yelllow-400 flex rounded-md px-2 text-gray-950">
        Reading
        <AnimationsEllipsis fontSize="18px" className="mr-1" />
      </div>
    </div>
  ) : (
    <div className="flex w-[206px] items-center gap-4">
      <ProgressIndicator percent={progress} />
    </div>
  );
};

const TAPE_STATE_STRINGS: Record<
  FileState,
  { label: React.ReactNode; colorClass: string; textColor?: string }
> = {
  [FileState.NEW]: {
    label: (
      <>
        Reading <AnimationsEllipsis fontSize="18px" className="ml-1" />
      </>
    ),
    colorClass: 'bg-yellow-400',
    textColor: 'text-white',
  },
  [FileState.READING_FILE_HEADERS]: {
    label: (
      <>
        Reading <AnimationsEllipsis fontSize="18px" className="ml-1" />
      </>
    ),
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
  [FileState.NEEDS_MAPPING]: {
    label: 'Needs mapping',
    colorClass: 'bg-blue-400',
    textColor: 'text-white',
  },
  [FileState.READY_TO_PROCESS]: {
    label: (
      <>
        Processing <AnimationsEllipsis fontSize="18px" className="ml-1" />
      </>
    ),
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
  [FileState.PROCESSING]: {
    label: (
      <>
        Processing <AnimationsEllipsis fontSize="18px" className="ml-1" />
      </>
    ),
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
  [FileState.PROCESSED]: {
    label: 'Ready to save',
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
  [FileState.READY_TO_PERSIST]: {
    label: 'Saving',
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
  [FileState.PERSISTING]: {
    label: 'Saving',
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
  [FileState.PERSISTED]: {
    label: 'Saved',
    colorClass: 'bg-green-400',
    textColor: 'text-gray-950',
  },
  [FileState.ERROR_HEADER]: {
    label: 'Error: Invalid headers',
    colorClass: 'bg-red-400',
    textColor: 'text-white',
  },
  [FileState.ERROR_PASSWORD]: {
    label: 'Error: Password protected',
    colorClass: 'bg-red-400',
    textColor: 'text-white',
  },
  [FileState.ERROR]: {
    label: 'Error',
    colorClass: 'bg-red-400',
    textColor: 'text-white',
  },
  [FileState.TO_DELETE]: {
    label: 'Deleting',
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
  [FileState.DELETING]: {
    label: 'Deleting',
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
  [FileState.DELETED]: {
    label: 'Deleted',
    colorClass: 'bg-yellow-500',
    textColor: 'text-gray-950',
  },
  [FileState.DELETE_ERROR]: {
    label: 'Error',
    colorClass: 'bg-yellow-400',
    textColor: 'text-gray-950',
  },
};

type Props = {
  tableRows: TapesUploadedTableFile[] | undefined;
  inProgressFiles?: InProgressFile[];
  error?: ApolloError;
};

const TapesUploadedTable: React.FC<Props> = ({
  tableRows,
  inProgressFiles,
  error,
}) => {
  const { tapeId } = useParams<{ tapeId: string }>();

  if (error) {
    console.error('Error fetching loan uploads', error);
    return <div>Error fetching data</div>;
  } else if (!tableRows) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mb-4 min-h-[200px] overflow-y-auto text-[12px] text-white">
      {inProgressFiles?.map((row) => (
        <div
          key={row.id ?? row.name}
          className="mb-3 flex items-center border-t border-slate-500 p-2 hover:bg-slate-500"
        >
          <div className="flex-2 mr-4">{row.name}</div>
          <div className="flex-1 text-right"></div>
          <UploadingCell progress={row.progress?.percentage} />
          <div className="flex-1 text-right font-pt-mono"></div>
          <div className="flex-1 text-right font-pt-mono"></div>
          <div className="flex-1 text-right font-pt-mono"></div>
        </div>
      ))}
      {tableRows.map((row) => {
        const processedRows =
          (row.processingReport.new_count ?? 0) +
          (row.processingReport.updated_count ?? 0);
        const isSelectable =
          row.state === FileState.NEEDS_MAPPING ||
          row.state === FileState.PROCESSED ||
          row.state === FileState.PROCESSING;
        const { label, colorClass, textColor } = TAPE_STATE_STRINGS[row.state];

        return (
          <Link
            to={isSelectable ? `/portfolio/tape-upload/${row.id}` : '#'}
            key={row.id}
            className="no-underline"
          >
            <div
              className={`mb-3 flex items-center border-t border-slate-500 p-2 hover:bg-slate-500 ${
                tapeId === row.id ? 'bg-pink-900 text-white' : ''
              } ${isSelectable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            >
              <div className="flex-2 mr-4">{row.name}</div>
              <div className="flex-1 font-pt-mono">{`${row.assetClass}${
                row.productType === ProductType.ARM
                  ? ' - ' + row.productType
                  : ''
              }`}</div>
              <div>
                <div
                  className={`flex flex-1 ${colorClass} ${textColor} rounded-md px-2`}
                >
                  {label}
                </div>
              </div>
              <div className="flex-1 text-right font-pt-mono">
                {row.uploaded_by.firstName + ' ' + row.uploaded_by.lastName}
              </div>
              <div className="flex-1 text-right font-pt-mono">
                {DateTime.fromISO(row.createdDate).toLocal().toFormat('ff')}
              </div>
              <div className="flex-1 text-right font-pt-mono">
                {row.processingReport.new_count != null
                  ? `${processedRows} rows`
                  : ''}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export type { InProgressFile };
export default TapesUploadedTable;
export { TapesUploadedTableFragments };
