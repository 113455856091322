import React from 'react';

type FileListRowProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  isSelected?: boolean;
};

export const FileListRow = ({
  children,
  onClick,
  disabled = false,
  isSelected = false,
}: FileListRowProps) => {
  const disabledClass = disabled
    ? 'cursor-not-allowed'
    : 'cursor-pointer hover:bg-pink-800';
  const baseClass =
    'flex items-center justify-between border-t border-slate-500 py-[8px] px-[4px] transition-colors duration-300';

  const selectedClass = isSelected ? 'bg-pink-900' : '';

  const classNames = `${baseClass} ${disabledClass} ${selectedClass}`;

  return (
    <div className={classNames} onClick={onClick}>
      {children}
    </div>
  );
};
