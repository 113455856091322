import React, { useEffect, useState } from 'react';

import { Section } from 'common-ui';
import { typographyLabelLargePrimaryRegular } from 'common-ui/typography';
import { AddUserSetting } from 'features/drilldown/cashflows/__generated__/AddUserSetting';
import { GetUserSetting } from 'features/drilldown/cashflows/__generated__/GetUserSetting';
import { GetCashflowByPool_userCompanyListing_performance_summary as PoolCashflowPerfSummary } from 'features/pages/portfolio/CashFlowModeling/__generated__/GetCashflowByPool';
import toast from 'react-hot-toast';
import { styled } from 'style/ORSNNTheme';
import { DownloadDocumentButton, Tab, Tabs } from 'ui-kit';
import { downloadExcelFromJson } from 'utils/excelUtils';

import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';

import {
  DefaultAssumptionType,
  PrepaymentAssumptionType,
  RateType,
} from '__generated__/globalTypes';

import { GetMarketData } from 'query/__generated__/GetMarketData';
import { GET_MARKET_DATA } from 'query/getMarketData';

import { CashFlowModelingInputs } from './CashFlowModelingInputs';
import CashFlowsDataDisplay from './CashFlowsDataDisplay';
import { CashFlowsValidValues } from './configurations/cashflow-inputs';
import { GetDealCashFlows_deal_performanceSummary } from './gql/__generated__/GetDealCashFlows';
import { GetPortfolioCashFlows_user_company_performanceSummary } from './gql/__generated__/GetPortfolioCashFlows';
import YieldMatrixModeling from './YieldMatrixModeling';

const ADD_USER_SETTING = gql`
  mutation AddUserSetting($name: String!, $value: String!) {
    addUserSetting(name: $name, value: $value)
  }
`;

const GET_USER_SETTING = gql`
  query GetUserSetting($name: String!) {
    user {
      id
      setting(name: $name)
    }
  }
`;

export type PerformanceSummary =
  | GetDealCashFlows_deal_performanceSummary
  | GetPortfolioCashFlows_user_company_performanceSummary
  | undefined;

export type useSummaryDataReturnValue = {
  loading: boolean;
  data: PerformanceSummary;
  error: ApolloError | undefined;
};

export type useSelectedPoolCashflowValue = {
  loading: boolean;
  data?: PoolCashflowPerfSummary;
  error: ApolloError | undefined;
};

export type CashFlowsProps = {
  settingKey: string;
  useSummaryData: (
    inputValues: CashFlowsValidValues,
  ) => useSummaryDataReturnValue;
  selectedPoolId?: string;
  selectedPoolCashflow?: (
    listingId: string,
    inputValues: CashFlowsValidValues,
  ) => useSelectedPoolCashflowValue;
};

export const CashFlows = (props: CashFlowsProps): JSX.Element | null => {
  const [addUserSetting] = useMutation<AddUserSetting>(ADD_USER_SETTING);

  const { data, loading, error } = useQuery<GetUserSetting>(GET_USER_SETTING, {
    variables: { name: props.settingKey },
    fetchPolicy: 'network-only',
  });

  const [formValues, setFormValues] = useState<CashFlowsValidValues>({
    cdr: 0,
    cpr: 0,
    lsr: 0,
    daysDelay: 0,
    rateType: RateType.Sofr,
    defaultAssumptionType: DefaultAssumptionType.CDR,
    prepaymentAssumptionType: PrepaymentAssumptionType.CPR,
    servicingRate: 0,
  });

  useEffect(() => {
    if (!loading && !error && data && data.user.setting) {
      try {
        const validatedFormValues: CashFlowsValidValues = JSON.parse(
          data.user.setting,
        );
        setFormValues(validatedFormValues);
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          console.log(e);
        }
      }
    }
  }, [data, loading, error]);

  const handleSuccessfulSubmission = (
    validatedFormValues: CashFlowsValidValues,
  ) => {
    setFormValues(validatedFormValues);
    addUserSetting({
      variables: {
        name: props.settingKey,
        value: JSON.stringify(validatedFormValues),
      },
    });
  };

  return (
    <Section>
      <CashFlowsFormWithDataDisplay
        onSubmit={handleSuccessfulSubmission}
        formValues={formValues}
        useSummaryData={props.useSummaryData}
        selectedPoolId={props.selectedPoolId}
        selectedPoolCashflow={props.selectedPoolCashflow}
        showYieldmatrix={true}
        withServicingRate={true}
      />
    </Section>
  );
};

export type CashFlowsFormWithDataDisplayProps = {
  onSubmit: (validatedFormValues: CashFlowsValidValues) => void;
  formValues: CashFlowsValidValues;
  useSummaryData: (
    inputValues: CashFlowsValidValues,
  ) => useSummaryDataReturnValue;
  withServicingRate?: boolean;
  selectedPoolId?: string;
  selectedPoolCashflow?: (
    listingId: string,
    inputValues: CashFlowsValidValues,
  ) => useSelectedPoolCashflowValue;
  showYieldmatrix?: boolean;
};

export const CashFlowsFormWithDataDisplay: React.FC<
  CashFlowsFormWithDataDisplayProps
> = ({
  onSubmit,
  formValues,
  useSummaryData,
  withServicingRate,
  selectedPoolId,
  selectedPoolCashflow,
  showYieldmatrix = false,
}) => {
  const formValuesWithDefaults = formValues || {
    cdr: 0,
    cpr: 0,
    lsr: 0,
    daysDelay: 0,
    servicingRate: 0,
    rateType: RateType.Sofr,
    defaultAssumptionType: 'CDR',
    prepaymentAssumptionType: 'CPR',
  };

  const summaryData = useSummaryData(formValues);

  const poolCashflowData = selectedPoolCashflow?.(
    selectedPoolId || '',
    formValues,
  );
  const cashflowData =
    selectedPoolId && selectedPoolCashflow ? poolCashflowData : summaryData;

  const handleDownloadExcel = () => {
    if (
      !cashflowData ||
      !cashflowData.data ||
      !Array.isArray(cashflowData?.data.cashFlows.monthlyCashFlows)
    ) {
      toast.error('No data available for download.');
      return;
    }
    downloadExcelFromJson(
      cashflowData.data.cashFlows.monthlyCashFlows,
      'Monthly Cashflows',
      'CashFlowSummary.xlsx',
    );
  };

  const { data: marketData } = useQuery<GetMarketData>(GET_MARKET_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <div className="flex justify-between pt-4">
        <CashFlowModelingInputs
          formValues={formValuesWithDefaults}
          onSubmit={onSubmit}
          withServicingRate={withServicingRate}
        />
        <div>
          <DownloadDocumentButton
            onClick={handleDownloadExcel}
            fileType="excel"
            tooltipText="Download Excel"
            tooltipPosition="left"
            className="mr-4"
          />
        </div>
      </div>
      <div className="mt-4">
        {showYieldmatrix ? (
          <Tabs>
            <Tab label="Cashflows">
              {formValues && cashflowData && (
                <CashFlowsDataDisplay
                  cashflowData={cashflowData.data}
                  userInputs={formValues}
                  selectedPoolId={selectedPoolId}
                  selectedPoolCashflow={selectedPoolCashflow}
                  loading={cashflowData.loading}
                />
              )}
            </Tab>
            <Tab label="Yield Matrix">
              {cashflowData && (
                <YieldMatrixModeling
                  formValues={formValues}
                  onFormChange={onSubmit}
                  marketData={marketData}
                  unpaidBalance={cashflowData.data?.unpaidBalance || 0}
                  cashFlows={cashflowData.data?.cashFlows}
                  netCoupon={cashflowData.data?.netCoupon}
                />
              )}
            </Tab>
          </Tabs>
        ) : (
          formValues &&
          cashflowData && (
            <CashFlowsDataDisplay
              cashflowData={cashflowData.data}
              userInputs={formValues}
              selectedPoolId={selectedPoolId}
              selectedPoolCashflow={selectedPoolCashflow}
              loading={cashflowData.loading}
            />
          )
        )}
      </div>
    </>
  );
};
