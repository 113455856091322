import React from 'react';

import { IconAlertCircle } from '@tabler/icons-react';

interface ErrorMessageProps {
  className?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`relative ${className}`}>
      {children ? (
        <span className="flex items-center text-sm text-red-500">
          <IconAlertCircle size={16} className="mr-1 -translate-y-px" />
          {children}
        </span>
      ) : null}
    </div>
  );
};
