import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import React from 'react';

import { DatePicker } from 'baseui/datepicker';
import {
  BaseButton,
  CheckboxWithLabel,
  IndeterminateCheckbox,
  SvgIcon,
} from 'common-ui';
import {
  NumberInputWithUnits,
  TextInput,
} from 'common-ui/inputs/Inputs-styled';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import { DateTime } from 'luxon';
import { Tooltip } from 'react-tooltip';
import { ModalNotStyled } from 'ui-kit';

import {
  FileType,
  ServicingPreference,
  StipulationFieldName,
  StipulationToggleName,
} from '__generated__/globalTypes';

import { PredefinedOrCustomParameter } from './BidDetails';
import { useBiddingPricingContext } from './BiddingPricingContext';
import {
  MAXIMUM_SERVICING_PRICE,
  MINIMUM_SERVICING_PRICE,
  PREDEFINED_SERVICING_PRICES,
} from './constants';
import SelectPercentage from './SelectPercentage';

interface ToggleableInputProps {
  label: string;
  children: ReactNode;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleableInput: FC<ToggleableInputProps> = ({
  label,
  children,
  checked,
  onChange,
}) => {
  return (
    <div className="my-[10px] flex items-center justify-between gap-5">
      <CheckboxWithLabel
        checked={checked}
        onChange={(event) => {
          if (onChange) {
            onChange(event);
          }
        }}
      >
        <span className="text-sm">{label}</span>
      </CheckboxWithLabel>
      <div className="flex w-[134px] justify-end text-sm">
        {React.cloneElement(children as React.ReactElement, {
          disabled: !checked,
        })}
      </div>
    </div>
  );
};

interface CustomBidStipulationProps {
  stipulationText: string | null;
  setStipulationText: (value: string) => void;
  placeholder: string;
  initiallyChecked: boolean;
}

interface BidStipulationsProps {
  dealId: string;
}

const CustomBidStipulation = (props: CustomBidStipulationProps) => {
  const [isChecked, setIsChecked] = useState(props.initiallyChecked);

  return (
    <div className="my-[10px] flex items-center justify-between gap-5">
      <IndeterminateCheckbox
        checked={isChecked}
        onChange={(event) => {
          setIsChecked(event.target.checked);
          props.setStipulationText('');
        }}
      />
      <TextInput
        style={{ fontSize: 14 }}
        disabled={!isChecked}
        withPrefix={false}
        placeholder={props.placeholder}
        value={props.stipulationText || ''}
        onChange={(event) => props.setStipulationText(event.target.value)}
      />
    </div>
  );
};

const BidStipulations = (props: BidStipulationsProps) => {
  const {
    selectedCarve,
    bidCard,
    bidDetails,
    updateCustomStips,
    updateBidDetails,
    setStipFlag,
  } = useBiddingPricingContext();
  const stipulations = bidDetails?.stipulations;
  const { is_servicing_negotiable } = useMemo(() => bidCard, [bidCard]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const updateServicingRate = useCallback(
    (selectedOption: PredefinedOrCustomParameter) => {
      if (bidDetails != null && is_servicing_negotiable) {
        updateBidDetails(
          {
            ...bidDetails,
            stipulations: {
              ...bidDetails.stipulations,
              servicingRate: selectedOption,
              servicing_rate: parseFloat(selectedOption.value),
            },
          },
          StipulationFieldName.servicing_rate,
          selectedOption.value,
        );
      }
    },
    [bidDetails, updateBidDetails, is_servicing_negotiable],
  );

  if (bidDetails == null || stipulations == null || selectedCarve == null) {
    return null;
  }

  const documents = bidCard.documents || [];

  const updateStipulation = (
    name: StipulationFieldName,
    value: string | null,
  ) => {
    updateBidDetails(
      {
        ...bidDetails,
        stipulations: {
          ...bidDetails.stipulations,
          [name]: value,
        },
      },
      name,
      value || undefined,
    );
  };

  const onDocumentSelect = (documentId: string) => {
    const documentIds = stipulations.document_ids;
    const hasDocument = documentIds.includes(documentId);

    // currently we dont save the documentIds to backend because its possible the old document is deleted
    // in this case, we always want to show and ask for the most recent list of documents loaded on the page
    if (!hasDocument) {
      updateBidDetails({
        ...bidDetails,
        stipulations: {
          ...bidDetails.stipulations,
          document_ids: [...documentIds, documentId],
        },
      });
    }
  };

  const onDocumentDeselect = (documentId: string) => {
    const documentIds = stipulations.document_ids;
    const updatedDocumentIds = documentIds.filter((id) => id !== documentId);

    updateBidDetails({
      ...bidDetails,
      stipulations: {
        ...bidDetails.stipulations,
        document_ids: updatedDocumentIds,
      },
    });
  };

  const servicingStipulation = (
    <>
      <div className="my-[10px] flex items-center justify-between gap-5">
        <CheckboxWithLabel
          defaultChecked={
            stipulations.is_servicing_retained ||
            bidCard.servicing_preference === ServicingPreference.RETAINED
          }
          checked={!!stipulations.is_servicing_retained}
          disabled={bidCard.servicing_preference !== ServicingPreference.NONE}
          onChange={(event) =>
            bidCard.servicing_preference === ServicingPreference.NONE &&
            setStipFlag(
              StipulationToggleName.is_servicing_retained,
              event.target.checked,
            )
          }
        >
          <span className="text-sm">Servicing Retained</span>
        </CheckboxWithLabel>
      </div>
      {is_servicing_negotiable &&
        stipulations.is_servicing_retained === true && (
          <>
            <div>Select Servicing Rate</div>
            <SelectPercentage
              options={PREDEFINED_SERVICING_PRICES}
              customMax={MAXIMUM_SERVICING_PRICE}
              customMin={MINIMUM_SERVICING_PRICE}
              selectedOption={stipulations.servicingRate}
              setSelectedOption={updateServicingRate}
            />
          </>
        )}
    </>
  );

  const optionalUploadSection = (
    <div className="mb-3 mt-3 flex justify-start">
      <div>
        <ModalNotStyled
          trigger={
            <BaseButton
              icon="pill-plus"
              iconFill="#BDBDBD"
              isIconRight
              label="Upload an LOI"
              size="medium"
              type="tertiary"
              onClick={() => setIsModalOpen(true)}
            >
              Upload an LOI
            </BaseButton>
          }
          handleClose={() => setIsModalOpen(false)}
        >
          {({ closeModal }) => (
            <div className="w-full max-w-[647px] rounded-lg border border-pink-500 bg-background-canvas p-6 font-heebo text-white shadow-lg">
              <div className="flex items-center justify-end">
                <button
                  className="text-gray-500 hover:text-gray-300"
                  onClick={closeModal}
                >
                  ✕
                </button>
              </div>
              <h2 className="mt-2 flex justify-center text-xl font-semibold">
                Upload an LOI
              </h2>
              <p className="mb-8 mt-4 flex justify-center text-center">
                Upload your Letter of Intent in PDF or JPG format.
              </p>
              <FileUpload
                parentId={props.dealId}
                fileType={FileType.TERMS_DOCUMENT}
                setIsOpen={closeModal}
                allowedFileTypes={['application/pdf', 'image/jpeg']}
                width="600px"
              />
            </div>
          )}
        </ModalNotStyled>
      </div>
    </div>
  );

  return (
    <div className="flex gap-8">
      <div>
        <div>Select Stipulations</div>
        {/* TODO(kentskinner): this should only be shown for participation bids 
          https://linear.app/orsnn/issue/ORS-1078/toggle-participation-component-based-on-offering-and-deal-context#comment-d266e61b
        <div>Select Participation Percentage</div>
        <SelectPercentage
          options={PREDEFINED_PARTICIPATION_PERCENTS}
          customMax={MAXIMUM_PARTICIPATION_PERCENT}
          customMin={MINIMUM_PARTICIPATION_PERCENT}
          selectedOption={bidDetails.participationPercent}
          setSelectedOption={updateParticipationPercentage}
        />*/}
        {servicingStipulation}
        <div className="flex items-center">
          <ToggleableInput
            label="Percentage of Collateral Diligence"
            checked={!!stipulations.has_percent_collateral_diligence}
            onChange={(event) =>
              setStipFlag(
                StipulationToggleName.has_percent_collateral_diligence,
                event.target.checked,
              )
            }
          >
            <NumberInputWithUnits
              unit="%"
              style={{ fontSize: 14 }}
              min="0"
              max="100"
              error={false}
              value={stipulations.percent_collateral_diligence || ''}
              onChange={(event) =>
                updateStipulation(
                  StipulationFieldName.percent_collateral_diligence,
                  event.target.value,
                )
              }
            />
          </ToggleableInput>

          <div
            className="ml-2 flex justify-end p-1"
            data-tooltip-id="percent_collateral_diligence"
          >
            <SvgIcon name="info" />
          </div>
          <Tooltip id="percent_collateral_diligence" className="max-w-[400px]">
            <p>
              Select the percentage of loans to undergo additional due
              diligence. The platform will randomly select loans from the
              offering that reflect the overall characteristics, ensuring the
              sample accurately represents the risk profile and performance
              distribution of the full offering.
            </p>
            <p className="mt-2">
              This automated selections can be later modified during diligence
            </p>
          </Tooltip>
        </div>

        <ToggleableInput
          label="Min Net Yield to Buyer"
          checked={!!stipulations.has_min_net_yield_to_buyer}
          onChange={(event) =>
            setStipFlag(
              StipulationToggleName.has_min_net_yield_to_buyer,
              event.target.checked,
            )
          }
        >
          <NumberInputWithUnits
            unit="%"
            style={{ fontSize: 14 }}
            min="0"
            max="100"
            error={false}
            value={stipulations.min_net_yield_to_buyer || ''}
            onChange={(event) =>
              updateBidDetails(
                {
                  ...bidDetails,
                  stipulations: {
                    ...bidDetails.stipulations,
                    min_net_yield_to_buyer: parseFloat(event.target.value),
                  },
                },
                StipulationFieldName.min_net_yield_to_buyer,
                event.target.value,
              )
            }
          />
        </ToggleableInput>

        <ToggleableInput
          label="Diligence Completion Date"
          checked={!!stipulations.has_diligence_completion_date_seconds}
          onChange={(event) =>
            setStipFlag(
              StipulationToggleName.has_diligence_completion_date_seconds,
              event.target.checked,
            )
          }
        >
          <DatePicker
            size="compact"
            formatString="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            mask="99/99/9999"
            value={
              stipulations.diligence_completion_date_seconds
                ? DateTime.fromSeconds(
                    stipulations.diligence_completion_date_seconds,
                  ).toJSDate()
                : null
            }
            minDate={new Date()}
            onChange={(data) => {
              const date = Array.isArray(data.date) ? data.date[0] : data.date;
              if (date) {
                updateBidDetails(
                  {
                    ...bidDetails,
                    stipulations: {
                      ...bidDetails.stipulations,
                      diligence_completion_date_seconds:
                        DateTime.fromJSDate(date).toSeconds(),
                    },
                  },
                  StipulationFieldName.diligence_completion_date_seconds,
                  DateTime.fromJSDate(date).toSeconds().toString(),
                );
              }
            }}
          />
        </ToggleableInput>
        <ToggleableInput
          label="Settlement Date"
          checked={!!stipulations.has_settlement_date_seconds}
          onChange={(event) =>
            setStipFlag(
              StipulationToggleName.has_settlement_date_seconds,
              event.target.checked,
            )
          }
        >
          <DatePicker
            size="compact"
            formatString="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            mask="99/99/9999"
            value={
              stipulations.settlement_date_seconds
                ? DateTime.fromSeconds(
                    stipulations.settlement_date_seconds,
                  ).toJSDate()
                : null
            }
            minDate={new Date()}
            onChange={(data) => {
              const date = Array.isArray(data.date) ? data.date[0] : data.date;
              if (date) {
                updateBidDetails(
                  {
                    ...bidDetails,
                    stipulations: {
                      ...bidDetails.stipulations,
                      settlement_date_seconds:
                        DateTime.fromJSDate(date).toSeconds(),
                    },
                  },
                  StipulationFieldName.settlement_date_seconds,
                  DateTime.fromJSDate(date).toSeconds().toString(),
                );
              }
            }}
          />
        </ToggleableInput>

        <CustomBidStipulation
          initiallyChecked={!!stipulations.custom1}
          placeholder="Enter Custom Stipulation 1..."
          stipulationText={stipulations.custom1}
          setStipulationText={(text) =>
            updateCustomStips(selectedCarve.id, [
              text,
              stipulations.custom2,
              stipulations.custom3,
            ] as string[])
          }
        />

        <CustomBidStipulation
          initiallyChecked={!!stipulations.custom2}
          placeholder="Enter Custom Stipulation 2..."
          stipulationText={stipulations.custom2}
          setStipulationText={(text) =>
            updateCustomStips(selectedCarve.id, [
              stipulations.custom1,
              text,
              stipulations.custom3,
            ] as string[])
          }
        />

        <CustomBidStipulation
          initiallyChecked={!!stipulations.custom3}
          placeholder="Enter Custom Stipulation 3..."
          stipulationText={stipulations.custom3}
          setStipulationText={(text) =>
            updateCustomStips(selectedCarve.id, [
              stipulations.custom1,
              stipulations.custom2,
              text,
            ] as string[])
          }
        />
      </div>

      <div className="w-1/2">
        <div>Select Transaction Documents</div>
        <div>
          {documents.map((document) => {
            const isChecked = bidDetails.stipulations.document_ids.includes(
              document.id,
            );
            return (
              <label
                key={document.id}
                className="flex cursor-pointer items-center justify-between gap-2 border-t border-slate-400 p-1 text-slate-200 hover:bg-pink-800"
              >
                <IndeterminateCheckbox
                  checked={isChecked}
                  onChange={(_e) => {
                    if (isChecked) {
                      onDocumentDeselect && onDocumentDeselect(document.id);
                    } else {
                      onDocumentSelect && onDocumentSelect(document.id);
                    }
                  }}
                />
                <div className="w-4/5 flex-1 flex-shrink flex-grow-0 whitespace-nowrap">
                  {document.name}
                </div>
                <a
                  className="flex w-1/5 flex-1 flex-shrink flex-grow-0 items-center px-2 text-slate-200 no-underline"
                  target="blank"
                  href={document.documentUrl}
                  download
                  onClick={(e) => e.stopPropagation()}
                >
                  <SvgIcon name="pdf" />
                  <span className="text-accentDefault pl-2">View</span>
                </a>
              </label>
            );
          })}
          {optionalUploadSection}
        </div>
      </div>
    </div>
  );
};

export default BidStipulations;
