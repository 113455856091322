import { useCallback, useEffect, useMemo, useState } from 'react';

import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledBody } from 'baseui/card';
import { Input } from 'baseui/input';
import { ListItem, ListItemLabel } from 'baseui/list';
import { FilterContextProvider } from 'context/FilterContext';
import FilterInputs from 'features/core/filter/FilterInputs';
import { DateTime } from 'luxon';
import prettyBytes from 'pretty-bytes';
import { theme } from 'style/ORSNNTheme';
import { Checkbox, DownloadDocumentButton } from 'ui-kit';
import DealButton from 'ui-kit/DealButton/DealButton';
import { downloadElementAsPDF } from 'utils/pdfUtils';

import { useLazyQuery, useMutation } from '@apollo/client';

import { DealRole } from '__generated__/globalTypes';

import {
  AcceptBid,
  AcceptBidVariables,
} from 'mutation/__generated__/AcceptBid';
import {
  RejectBid,
  RejectBidVariables,
} from 'mutation/__generated__/RejectBid';
import {
  SaveSellerBid,
  SaveSellerBidVariables,
} from 'mutation/__generated__/SaveSellerBid';
import { ACCEPT_BID } from 'mutation/acceptBid';
import { REJECT_BID } from 'mutation/rejectBid';
import { SAVE_SELLER_BID } from 'mutation/saveSellerBid';

import {
  CalculateBidPrice,
  CalculateBidPriceVariables,
} from 'query/__generated__/CalculateBidPrice';
import { CALCULATE_BID_PRICE } from 'query/calculateBidPrice';

import { GET_DEAL_TIMELINE } from '../../fragments';
import BidSummarySection from '../BidSummarySection';
import BuyerBidSummary from '../BuyerBidSummary';
import CarveDiffTable from '../CarveDiffTable';
import {
  basisPointsToPercent,
  formatCalculatedPrice,
  List,
  percentToBasisPoints,
  StyledLabelBody,
  useDebouncedState,
} from '../utils';
import {
  BidCard_BidCard,
  BidCard_BidCard_bid_terms,
} from './__generated__/BidCard';
import SellerCounterOffer from './sellerCounterOffer';
import YieldMatrixAndCashflowTabs from './YieldMatrixAndCashflowTabs';

const DEBOUNCE_DELAY = 1200;
const INPUT_PLACEHOLDER = '100.00';

const ListItemStyleProps = {
  overrides: {
    Root: {
      style: {
        backgroundColor: theme.color.cardBackground,
        maxHeight: '40px',
      },
    },
  },
};

function renderListCol(
  termsDocuments: BidCard_BidCard_bid_terms[],
): JSX.Element[] {
  return termsDocuments.map((doc) => {
    const { name, documentSizeBytes, documentUrl } = doc;
    return (
      <ListItem key={name} {...ListItemStyleProps}>
        <ListItemLabel>
          <StyledLabelBody>
            {`${name} - ${prettyBytes(documentSizeBytes)}`}
            <a target="blank" href={documentUrl}>
              <FontAwesomeIcon icon={faFileDownload} />
            </a>
          </StyledLabelBody>
        </ListItemLabel>
      </ListItem>
    );
  });
}

function renderList(
  termsDocuments: BidCard_BidCard_bid_terms[],
): JSX.Element[] {
  const halfListLength = Math.ceil(termsDocuments.length / 2);
  return [
    termsDocuments.slice(0, halfListLength),
    termsDocuments.slice(halfListLength),
  ].map((halfDocs, index) => (
    <List key={halfDocs[0]?.name ?? index}>{renderListCol(halfDocs)}</List>
  ));
}

type Props = {
  dealId: string;
} & BidCard_BidCard;

const BidCardBody = ({
  dealId,
  bid,
  role,
  can_edit_counteroffer,
  counteroffer_details_section,
}: Props) => {
  const [withCounteroffer, setWithCounteroffer] = useState<boolean>(false);
  const [savedCounteroffer, setSavedCounteroffer] = useState<boolean>(
    bid.seller_bid_basis_points != null,
  );
  const [sellerBidPercent, dSellerBidPercent, setSellerBidPercent] =
    useDebouncedState<string>(
      basisPointsToPercent(bid.seller_bid_basis_points),
      DEBOUNCE_DELAY,
    );

  const [calculateBidPrice, { data }] = useLazyQuery<
    CalculateBidPrice,
    CalculateBidPriceVariables
  >(CALCULATE_BID_PRICE);

  const [rejectBid] = useMutation<RejectBid, RejectBidVariables>(REJECT_BID);
  const [acceptBid] = useMutation<AcceptBid, AcceptBidVariables>(ACCEPT_BID);
  const [saveSellerBid] = useMutation<SaveSellerBid, SaveSellerBidVariables>(
    SAVE_SELLER_BID,
    {
      onCompleted: () => setSavedCounteroffer(true),
    },
  );

  useEffect(() => {
    if (withCounteroffer && dSellerBidPercent.length > 0) {
      const basisPoints = percentToBasisPoints(dSellerBidPercent);
      saveSellerBid({
        variables: {
          input: { deal_id: dealId, seller_bid_basis_points: basisPoints },
        },
      });
      calculateBidPrice({
        variables: {
          input: { deal_id: dealId, bid_basis_points: basisPoints },
        },
      });
    }
  }, [withCounteroffer, dSellerBidPercent, dealId]);

  const handleReject = useCallback(() => {
    setWithCounteroffer(false);
    rejectBid({
      variables: { input: { deal_id: dealId } },
      refetchQueries: [{ query: GET_DEAL_TIMELINE, variables: { id: dealId } }],
    });
  }, [dealId]);

  const handleAccept = useCallback(() => {
    setWithCounteroffer(false);
    acceptBid({
      variables: { input: { deal_id: dealId } },
      refetchQueries: [{ query: GET_DEAL_TIMELINE, variables: { id: dealId } }],
    });
  }, [dealId]);

  const hasCarve = bid.carve != null && bid.carve.length !== 0;
  const filtersWithIds = bid.carve?.map((filter) => ({
    ...filter,
    id: `${filter.field_name}-${filter.operator}`,
  }));

  const carveDetailsSection = hasCarve && (
    <>
      <span>CARVE CRITERIA SPECIFIED</span>
      <div className="pl-4">
        <FilterContextProvider filters={filtersWithIds}>
          {filtersWithIds != null && (
            <FilterInputs dealId={dealId} canEdit={false} />
          )}
        </FilterContextProvider>
      </div>
    </>
  );

  const contractsSection = bid.terms && (
    <ul className="m-0 flex-1 list-none p-0">
      <span>CONTRACTS</span>
      <div className="flex p-2">
        {hasCarve ? renderList(bid.terms) : renderListCol(bid.terms)}
      </div>
    </ul>
  );

  const {
    currentBalance,
    price: sellerBidPrice,
    rate: sellerRate,
  } = formatCalculatedPrice({
    currentBalanceCents: data?.calculateBidPrice.unpaid_balance,
    priceCents: data?.calculateBidPrice.price,
    rate: data?.calculateBidPrice.rate,
  });

  const counterofferSection = can_edit_counteroffer && (
    <>
      <Checkbox
        checked={withCounteroffer}
        onChange={() => setWithCounteroffer((state) => !state)}
        label="Reject with counteroffer"
      />
      {withCounteroffer && (
        <div className="mt-4 flex flex-nowrap gap-x-3">
          <div className="flex w-[50%] flex-col gap-2">
            Define % of UPB
            <Input
              type="number"
              value={sellerBidPercent}
              disabled={!withCounteroffer}
              onChange={(event) => {
                setSavedCounteroffer(false);
                setSellerBidPercent(event.currentTarget.value);
              }}
              placeholder={INPUT_PLACEHOLDER}
              endEnhancer="%"
            />
          </div>
          <div className="flex w-[50%] flex-col gap-2">
            <BidSummarySection
              currentBalance={currentBalance}
              bidPrice={sellerBidPrice}
              pricePercent={sellerBidPercent}
              rate={sellerRate}
            />
          </div>
        </div>
      )}
    </>
  );

  const isBidUnsaved = useMemo(
    () => !savedCounteroffer && dSellerBidPercent !== sellerBidPercent,
    [savedCounteroffer, dSellerBidPercent, sellerBidPercent],
  );

  const handleDownloadPDF = async () => {
    await downloadElementAsPDF('pdf-content', 'BidDetails', 10);
  };

  const buttons = can_edit_counteroffer && (
    <>
      {withCounteroffer ? (
        <>
          <DealButton
            onClick={() => handleReject()}
            disabled={withCounteroffer && isBidUnsaved}
            variant="primary"
            label="submit"
          >
            Submit
          </DealButton>
          <DealButton
            onClick={() => handleReject()}
            disabled={withCounteroffer}
            variant="primary"
            label="accept"
          >
            Accept
          </DealButton>
        </>
      ) : (
        <>
          <DealButton
            onClick={() => handleAccept()}
            disabled={withCounteroffer}
            variant="success"
            label="accept"
          >
            Accept
          </DealButton>
          <DealButton
            onClick={() => handleReject()}
            disabled={withCounteroffer && isBidUnsaved}
            variant="reject"
            label="reject"
          >
            Reject
          </DealButton>
        </>
      )}
    </>
  );

  const buyerPrice = bid.stipulations?.bid_basis_points || 0;

  const buyerBidData = {
    price: basisPointsToPercent(buyerPrice) + '%',
    gwac: bid.carve_summary?.wa_coupon
      ? `${(bid.carve_summary.wa_coupon * 100).toFixed(3)}%`
      : '-',
    upb: bid.price?.unpaid_balance
      ? `$${bid.price.unpaid_balance.toLocaleString()}`
      : '-',
    settleDate: bid.stipulations?.settlement_date_seconds
      ? DateTime.fromSeconds(
          bid.stipulations.settlement_date_seconds,
        ).toLocaleString(DateTime.DATE_SHORT)
      : 'MM/DD/YYYY',
  };

  // TODO: WILL BE ADDED MORE DATA LATER
  const sellerCounterofferData = {
    price: basisPointsToPercent(bid.seller_bid_basis_points) + '%',
    gwac: '-',
    upb: '-',
    settleDate: 'MM/DD/YYYY',
  };

  // showSellerCounterOffer = counteroffer_details_section; NOT SURE WHY BUT WE RECEIVE AS FALSE
  const showSellerCounterOffer = true;

  return (
    <StyledBody>
      <div className="flex flex-col gap-4">
        {role !== DealRole.SELLER && (
          <div className="text-gray-500">
            Seller is reviewing your bid. Please check back later.
          </div>
        )}
        <div className="flex justify-end">
          <DownloadDocumentButton
            fileType="pdf"
            onClick={handleDownloadPDF}
            tooltipText="Download bid details"
          />
        </div>
        <div
          className="mt-4 flex flex-nowrap gap-x-3 bg-background-canvas px-[20px]"
          id="pdf-content"
        >
          <div className="flex w-[50%] flex-col gap-4">
            {carveDetailsSection}
            <span>BID</span>
            <BuyerBidSummary bid={bid} />
            {!hasCarve ? contractsSection : <></>}
            {showSellerCounterOffer && (
              <SellerCounterOffer
                buyerBid={buyerBidData}
                sellerCounteroffer={sellerCounterofferData}
              />
            )}
          </div>
          <div className="flex w-[50%] flex-col gap-2">
            <CarveDiffTable dealId={dealId} filteredPool={bid.carve_summary} />
          </div>
        </div>
        {hasCarve ? contractsSection : <></>}
        {counterofferSection}
        <div className="flex gap-4">{buttons}</div>
      </div>
      {role === DealRole.SELLER && (
        <div className="mt-4">
          <YieldMatrixAndCashflowTabs
            dealId={dealId}
            bidId={bid.bid_id}
            onPriceSelect={setSellerBidPercent}
            buyerPrice={buyerPrice}
          />
        </div>
      )}
    </StyledBody>
  );
};

export default BidCardBody;
export { default as BidCardFragments } from './fragments';
