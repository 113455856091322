import { ChangeEvent, useCallback } from 'react';

import { RadioButton } from 'common-ui/form/RadioButtonField';
import { PercentageInput } from 'common-ui/inputs/Inputs-styled';
import { FIELD_CONFIG } from 'configs/columns';
import {
  basisPointsToPercent,
  percentToBasisPoints,
} from 'features/deals/DealStages/EventActionCards/utils';
import {
  DisplayTable,
  DisplayTableHeaderCell,
  DisplayTableRow,
  DisplayTableRowCell,
  UiInput,
} from 'ui-kit';

import { StipulationFieldName } from '__generated__/globalTypes';

import { BidDetails } from './BidDetails';
import { useBiddingPricingContext } from './BiddingPricingContext';
import { formatCentsToDollars } from './formatting';

type CarveTableProps = {
  bidDetailsList: BidDetails[];
  selectedCarveId: string | null;
  setSelectedCarveId: (carveId: string) => void;
};

const tableHeaderLabels = [
  { label: '', maxW: '60px' },
  { label: 'Carve Name', minW: '160px' },
  { label: 'Bid Px', minW: '100px' },
  { label: 'Servicing Rate', minW: '130px' },
  { label: 'UPB', minW: '200px' },
  { label: 'GWAC' },
  { label: '# Loans' },
  { label: 'WALA' },
  { label: 'WAM' },
  { label: 'LTV' },
  { label: 'DTI' },
  { label: 'DQ' },
];

const CarveTable = ({
  bidDetailsList,
  selectedCarveId,
  setSelectedCarveId,
}: CarveTableProps) => {
  const { bidCard, updateBidDetails } = useBiddingPricingContext();

  const selectedBidDetails = bidDetailsList.find(
    (bidDetails) => bidDetails.carveId === selectedCarveId,
  );

  const handleBidPxInputChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const price = event.target.value ? event.target.value : null;
      if (price === null || !isNaN(parseFloat(price))) {
        const basisPoints = percentToBasisPoints(price || '0');
        if (selectedBidDetails) {
          updateBidDetails(
            {
              ...selectedBidDetails,
              carve: {
                ...selectedBidDetails.carve,
                stipulations: {
                  ...selectedBidDetails.stipulations,
                  bid_basis_points: basisPoints,
                },
              },
            },
            StipulationFieldName.bid_basis_points,
            `${basisPoints}`,
          );
        }
      }
    },
    [updateBidDetails, selectedBidDetails],
  );

  const handleServicingRateInputChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const price = event.target.value ? event.target.value : null;
      if (price === null || !isNaN(parseFloat(price))) {
        const rate = price ? parseFloat(price) : 0;
        if (selectedBidDetails && bidCard.is_servicing_negotiable) {
          updateBidDetails(
            {
              ...selectedBidDetails,
              stipulations: {
                ...selectedBidDetails.stipulations,
                servicingRate: {
                  ...selectedBidDetails.stipulations.servicingRate,
                  isCustom: ![0.25, 0.5].includes(rate),
                  value: rate.toString(),
                  draftValue: rate.toString(),
                },
                servicing_rate: rate,
              },
            },
            StipulationFieldName.servicing_rate,
            price || '0',
          );
        }
      }
    },
    [updateBidDetails, selectedBidDetails, bidCard.is_servicing_negotiable],
  );

  const TableHeader = (
    <>
      {tableHeaderLabels.map((item) => (
        <DisplayTableHeaderCell {...item} />
      ))}
    </>
  );

  return (
    <>
      <DisplayTable header={TableHeader}>
        <>
          {bidDetailsList.map((bid) => {
            const { carve } = bid;
            const isSelected = carve.id === selectedCarveId;
            const bidBasisPoints = carve.stipulations?.bid_basis_points;
            const bidPercent = basisPointsToPercent(bidBasisPoints || 0);
            const servicingRateValue = bid.stipulations.is_servicing_retained
              ? bid.stipulations.servicingRate.value
              : '0';
            const servicingRate = parseFloat(servicingRateValue);

            return (
              <DisplayTableRow
                key={carve.id}
                onClick={() => setSelectedCarveId(carve.id)}
                className={`no-liga-clig hover:bg-[#9a1698] ${isSelected ? 'bg-[#4f1450] text-slate-50' : 'odd:bg-gray-800'}`}
              >
                <DisplayTableRowCell className="justify-center" maxW="60px">
                  <RadioButton
                    checked={carve.id === selectedCarveId}
                    onChange={() => setSelectedCarveId(carve.id)}
                    label={''}
                    name={''}
                    value={''}
                  />
                </DisplayTableRowCell>
                <DisplayTableRowCell className="py-1" minW="160px">
                  {carve.name}
                </DisplayTableRowCell>
                <DisplayTableRowCell
                  className="w-24 pr-1 text-right"
                  minW="100px"
                >
                  {carve.id === selectedCarveId ? (
                    <UiInput
                      size={'small'}
                      variant="dark"
                      suffix={'%'}
                      value={
                        bidBasisPoints != null && parseFloat(bidPercent) !== 0
                          ? parseFloat(bidPercent)
                          : ''
                      }
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleBidPxInputChanged(event)
                      }
                    />
                  ) : (
                    <span className="pr-3">
                      {carve.stipulations?.bid_basis_points != null &&
                      parseFloat(bidPercent) !== 0
                        ? `${bidPercent}%`
                        : '-'}
                    </span>
                  )}
                </DisplayTableRowCell>
                <DisplayTableRowCell
                  className="w-24 overflow-auto pr-1 text-right"
                  minW="130px"
                >
                  {carve.id === selectedCarveId &&
                  bidCard.is_servicing_negotiable &&
                  bid.stipulations?.is_servicing_retained ? (
                    <UiInput
                      size={'small'}
                      variant="dark"
                      suffix={'%'}
                      value={
                        servicingRate != null && servicingRate !== 0
                          ? servicingRate
                          : ''
                      }
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleServicingRateInputChanged(event)
                      }
                    />
                  ) : (
                    <span className="pr-3">
                      {servicingRate != null && servicingRate !== 0
                        ? `${servicingRate}%`
                        : '-'}
                    </span>
                  )}
                </DisplayTableRowCell>
                <DisplayTableRowCell
                  className="overflow-auto py-1"
                  minW="200px"
                >
                  {formatCentsToDollars(carve.carve_summary?.unpaidBalance)}
                </DisplayTableRowCell>
                <DisplayTableRowCell className="py-1">
                  {FIELD_CONFIG.servicing_rate.display(
                    carve.carve_summary?.netCoupon,
                  )}
                </DisplayTableRowCell>
                <DisplayTableRowCell className="py-1">
                  {carve.carve_summary?.loanCount}
                </DisplayTableRowCell>
                <DisplayTableRowCell className="py-1">
                  {carve.carve_summary?.averageAge}
                </DisplayTableRowCell>
                <DisplayTableRowCell className="py-1">
                  {carve.carve_summary?.averageMaturity}
                </DisplayTableRowCell>
                <DisplayTableRowCell className="py-1">
                  {FIELD_CONFIG.ltv.display(carve.carve_summary?.ltv)}
                </DisplayTableRowCell>
                <DisplayTableRowCell className="py-1">
                  {FIELD_CONFIG.dti.display(carve.carve_summary?.dti)}
                </DisplayTableRowCell>
                <DisplayTableRowCell className="py-1">
                  {'-'}
                </DisplayTableRowCell>
              </DisplayTableRow>
            );
          })}
        </>
      </DisplayTable>
    </>
  );
};

export default CarveTable;
