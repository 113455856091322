import React, { useCallback } from 'react';

import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Row } from '@tanstack/react-table';

import { WithSubData } from './DataDisplayTable';
import { RenderCell } from './renderCell';

interface RenderRowProps<T> {
  row: Row<WithSubData<T>>;
  isLightRow: boolean;
  isSelected: boolean;
  width: number;
  isDragging: string;
  columnOrder: string[];
  stylingOptions: {
    rowColor?: string | undefined;
    alternateRowColor?: string | undefined;
    subRowColor?: string | undefined;
    alternateLightRows: boolean;
  };
}

const RenderRowComponent = <T,>({
  row,
  isLightRow,
  stylingOptions,
  isSelected,
  columnOrder,
  width,
  isDragging,
}: RenderRowProps<T>) => {
  const rowColor =
    row.depth > 0
      ? stylingOptions.subRowColor
      : isLightRow
        ? stylingOptions.alternateRowColor
        : stylingOptions.rowColor;

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    if ((event.target as HTMLElement).tagName.toLowerCase() !== 'input') {
      row.getToggleExpandedHandler()();
    }
  };

  const getRowKey = useCallback((row: Row<WithSubData<T>>) => {
    const originalData = row.original;
    // Create a string that combines the row id and a hash of the row's values
    const dataHash = Object.values(originalData).join('|');
    return `${row.id}-${dataHash}`;
  }, []);

  const bgColor = isSelected
    ? 'bg-slate-600'
    : rowColor
      ? rowColor
      : row.depth > 0
        ? 'bg-background-canvas'
        : isLightRow
          ? 'bg-gray-950'
          : 'bg-background-canvas';
  const rowClasses = `border-t border-gray-900 group relative`;
  return (
    <SortableContext
      items={columnOrder}
      strategy={horizontalListSortingStrategy}
    >
      <tr key={getRowKey(row)} onClick={handleRowClick} className={rowClasses}>
        {row.getVisibleCells().map((cell) => (
          <RenderCell
            cell={cell}
            columnOrder={columnOrder}
            key={cell.id}
            width={width}
            bgColor={bgColor}
            isColumnResize={isDragging}
          />
        ))}
      </tr>
    </SortableContext>
  );
};

export const RenderRow = React.memo(
  RenderRowComponent,
) as typeof RenderRowComponent;
