import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useRef,
} from 'react';

import { useMergeRefs } from '@floating-ui/react';
import { isBoolean } from 'utils/typeUtils';

export type IndeterminateCheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
> & {
  indeterminate?: boolean;
  className?: string;
};

export const IndeterminateCheckbox = forwardRef(
  (props: IndeterminateCheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { indeterminate, className, ...rest } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const mergedRef = useMergeRefs([inputRef, ref]);

    useEffect(() => {
      if (inputRef.current && isBoolean(indeterminate)) {
        inputRef.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [inputRef, indeterminate, rest.checked]);

    return (
      <input
        ref={mergedRef}
        type="checkbox"
        className={`
        before:bg-transparent indeterminate:before:clip-path-[inset(37%_12%_37%_12%_round_10%)] relative grid h-[18px] w-[18px] cursor-pointer appearance-none place-content-center
        rounded-[3px]
        border-2 border-slate-200 before:absolute before:left-1/2
        before:top-1/2 before:-translate-x-1/2
        before:-translate-y-1/2 checked:before:text-[11px] checked:before:font-normal checked:before:text-slate-200
        checked:before:content-['✔'] indeterminate:before:h-[10px] indeterminate:before:w-[10px] 
        indeterminate:before:bg-slate-200 indeterminate:before:content-['']
        ${className}
      `}
        {...rest}
      />
    );
  },
);
