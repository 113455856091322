import { FC } from 'react';

import { useSortable } from '@dnd-kit/sortable';

import { Pill, PillProps } from './Pill';

export interface SortablePillProps extends PillProps {
  id: string;
  useTransform?: boolean;
  variant?: 'primary' | 'muted' | 'blue';
}

export const SortablePill: FC<SortablePillProps> = ({
  id,
  onRemove,
  useTransform = true,
  variant = 'primary',
  ...props
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style =
    useTransform && transform
      ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          transition,
        }
      : undefined;

  return (
    <div ref={setNodeRef} style={style}>
      <Pill
        {...props}
        id={id}
        onRemove={onRemove}
        listeners={listeners}
        attributes={attributes}
        variant={variant}
      />
    </div>
  );
};
