import { useState } from 'react';

import { MayHaveLabel } from '@nivo/pie';
import {
  BaseButton,
  ButtonGroup,
  ModalNotStyled,
  SvgPillButton,
  ToggleButton,
} from 'common-ui';
import { PoolSummaryPerformanceSummary } from 'features/loanPool/__generated__/PoolSummaryPerformanceSummary';

import { AssetClass } from '__generated__/globalTypes';

import { StratificationsPerformanceSummary } from './__generated__/StratificationsPerformanceSummary';
import {
  stratificationsConfig,
  StratKey,
  StratRowType,
} from './startifications.config';
import { StratChart } from './StratChart';
import { StratTable } from './StratTable';

export type StratCardContentProps = {
  data: (StratRowType & MayHaveLabel)[];
  config: (typeof stratificationsConfig)[StratKey];
  strat: StratKey;
  assetClass: AssetClass;
  summary: StratificationsPerformanceSummary;
  avgSummary: PoolSummaryPerformanceSummary;
};

export function StratCard({
  strat,
  assetClass,
  summary,
  avgSummary,
  mode = 'card', // Default to 'card'
  displayType = 'chart', // Default to 'chart'
  onMinimizeClick,
}: {
  strat: StratKey;
  assetClass: AssetClass;
  summary: StratificationsPerformanceSummary;
  avgSummary: PoolSummaryPerformanceSummary;
  mode?: 'card' | 'modal';
  displayType?: 'chart' | 'table';
  onMinimizeClick?: () => void;
}) {
  const [isChart, setIsChart] = useState<boolean>(displayType === 'chart');
  const [isPieChart, setIsPieChart] = useState<boolean>(true);
  const config = stratificationsConfig[strat];
  if (!config) return null;

  const contentProps: StratCardContentProps = {
    data: summary[strat],
    config: {
      ...config,
      chart:
        config.label === 'Original Balance'
          ? isPieChart
            ? 'Pie'
            : 'Bar'
          : config.chart,
    } as (typeof stratificationsConfig)[StratKey],
    strat,
    assetClass,
    summary,
    avgSummary,
  };

  const handleToggle = (state: boolean) => {
    setIsPieChart(state);
  };

  return (
    <div
      className={`relative flex flex-col overflow-hidden rounded-md border border-accent-disabled bg-background-surface p-4 hover:border-accent-muted ${
        mode === 'modal' ? 'max-h-[80vh] w-[calc(100vw-40px)]' : 'w-auto'
      } relative aspect-[16/9]`}
    >
      <div
        className={`mb-4 flex items-center justify-between ${
          mode === 'modal' ? 'pr-5' : ''
        }`}
      >
        <span className="font-heebo text-xl font-semibold text-gray-400">
          {config.label}
        </span>
        <div className="flex gap-4">
          <ButtonGroup type="secondary" size="small" label="select card type">
            <BaseButton
              type="primary"
              size="small"
              isSelected={isChart}
              onClick={() => setIsChart(true)}
              label="Chart"
            >
              Chart
            </BaseButton>
            <BaseButton
              isSelected={!isChart}
              onClick={() => setIsChart(false)}
              label="Table"
            >
              Table
            </BaseButton>
          </ButtonGroup>

          {mode !== 'modal' ? (
            <ModalNotStyled
              trigger={<SvgPillButton iconName="expand" label="expand" />}
            >
              {({ closeModal }) => (
                <StratCard
                  strat={strat}
                  assetClass={assetClass}
                  summary={summary}
                  avgSummary={avgSummary}
                  mode="modal"
                  displayType={isChart ? 'chart' : 'table'}
                  onMinimizeClick={closeModal}
                />
              )}
            </ModalNotStyled>
          ) : (
            <SvgPillButton
              iconName="minimize"
              label="minimize"
              onClick={onMinimizeClick}
            />
          )}
        </div>
        {config.label === 'Original Balance' && (
          <div className="absolute bottom-4 left-4 z-[2]">
            <ToggleButton
              initialState={isPieChart}
              checkedLabel="Pie"
              uncheckedLabel="Bar"
              onChange={handleToggle}
            />
          </div>
        )}
      </div>
      <div className="h-1 flex-1">
        {isChart ? (
          <StratChart {...contentProps} />
        ) : (
          <StratTable {...contentProps} />
        )}
      </div>
    </div>
  );
}
