import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { flexRender, Header } from '@tanstack/react-table';

import { SortablePill } from '../Pill';
import { WithSubData } from './DataDisplayTable';
import { Alignment, getCommonPinningStyles, isColumnMeta } from './utils';

interface RenderHeaderComponentProps<T> {
  header: Header<WithSubData<T>, unknown>;
  width: number;
  isColumnResize: string;
  isDraggable?: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<string>>;
}

export const RenderHeaderComponent = <T,>({
  header,
  width,
  isColumnResize,
  setIsDragging,
  isDraggable = false,
}: RenderHeaderComponentProps<T>) => {
  const column = header.column;
  const columnDefMeta = column.columnDef.meta;
  const { alignment, sortable } = (() => {
    if (isColumnMeta(columnDefMeta)) {
      const alignment = columnDefMeta.alignment || 'left';
      const sortable = column.getCanSort();
      return { alignment, sortable };
    }
    return { alignment: Alignment.left, sortable: false };
  })();

  const sortValue = sortable
    ? (column.getIsSorted() || column.getNextSortingOrder()) === 'desc'
      ? 'desc'
      : 'asc'
    : '';

  const handleDragStart = () => {
    setIsDragging(header.column.id);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchend', handleDragEnd);
  };

  const handleDragEnd = () => {
    setIsDragging('');
    document.removeEventListener('mouseup', handleDragEnd);
    document.removeEventListener('touchend', handleDragEnd);
  };

  const { isDragging, transform, transition } = useSortable({
    id: header.id,
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition,
      }
    : undefined;

  const thClasses = `relative group text-${alignment} ${isDragging ? 'bg-gray-700' : 'bg-background-canvas'} cursor-${sortable ? 'pointer' : 'default'} py-[7px] pr-4 pl-2  ${isColumnResize === header.id ? 'border-r border-r-red-400' : 'border-gray-950'}`;
  return (
    <th
      key={header.id}
      className={thClasses}
      style={{
        position: 'relative',
        width: width || header.getSize(),
        ...getCommonPinningStyles(column, 0, true),
        ...style,
      }}
    >
      {!(
        columnDefMeta != null &&
        'headerNoPill' in columnDefMeta &&
        columnDefMeta.headerNoPill
      ) ? (
        <div
          style={{
            display: 'flex',
            justifyContent:
              alignment === 'left'
                ? 'flex-start'
                : alignment === 'right'
                  ? 'flex-end'
                  : 'center',
          }}
          onClick={sortable ? column.getToggleSortingHandler() : undefined}
        >
          <SortablePill
            variant="blue"
            useTransform={false}
            id={header.id}
            label={column.columnDef.header as string}
            isDraggable={isDraggable}
            sortValue={sortValue}
            isSorted={!!column.getIsSorted()}
          />
        </div>
      ) : (
        flexRender(column.columnDef.header, header.getContext())
      )}
      {header.id !== 'selection' && (
        <div
          className="absolute right-0 top-0 h-full w-[7px] cursor-col-resize touch-none select-none border-r-[1px] border-dashed border-red-400 opacity-0 transition-opacity group-hover:opacity-100"
          onMouseDown={(e) => {
            header.getResizeHandler()(e);
            handleDragStart();
          }}
          onTouchStart={(e) => {
            header.getResizeHandler()(e);
            handleDragStart();
          }}
        />
      )}
    </th>
  );
};

export const RenderHeader = React.memo(
  RenderHeaderComponent,
) as typeof RenderHeaderComponent;
