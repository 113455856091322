import React from 'react';

type DisplayTableProps = {
  header: React.ReactNode;
  children: React.ReactNode;
};

export const DisplayTable = ({ header, children }: DisplayTableProps) => {
  return (
    <div className="flex w-full flex-col overflow-hidden">
      <div className="flex">{header}</div>
      {children}
    </div>
  );
};
