import { useMemo, memo, useEffect } from 'react';

import { Card } from 'features/deals/dealCards/CardWrapper';
import DiligenceController from 'features/deals/dealCards/Diligence/DiligenceController';
import { LoansCardBody } from 'features/deals/dealCards/LoansCard';
import { StratCardBody } from 'features/deals/dealCards/StratsCard';
import BiddingAndPricingController from 'features/pages/marketplace/BiddingAndPricing/BiddingAndPricingController';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import { DateTime } from 'luxon';
import { Tooltip } from 'react-tooltip';

import BidCardBody from './BidCardBody';
import CarveDetailsCardBody from './CarveDetailsCardBody';
import CounterpartyCardBody from './CounterpartyCardBody';
import CounterpartyInsightsBody from './CounterpartyInsightsBody';
import DealDocumentsCardBody from './DealDocumentsCardBody';
import SettlementCardBody from './Settlement';
import { findLastIndex } from './utils';
import WireConfirmationCardBody from './WireConfirmationCardBody';
import { GetDealGeneralInfo_deal_listing_UserCompanyListing_seller } from '../../dealCards/gql/__generated__/GetDealGeneralInfo';
import { DealStagesDeal_state } from '../__generated__/DealStagesDeal';
import { GetTimeline_deal_cards } from '../__generated__/GetTimeline';

function renderCardBody(
  card: GetTimeline_deal_cards,
  dealId: string,
  role: string,
  seller: GetDealGeneralInfo_deal_listing_UserCompanyListing_seller,
  dealState: DealStagesDeal_state,
) {
  switch (card.__typename) {
    case 'CounterpartyCard':
      return <CounterpartyCardBody dealId={dealId} {...card} role={role} />;
    case 'CounterpartyInsightsCard':
      return <CounterpartyInsightsBody dealId={dealId} />;
    case 'DealDocumentsCard':
      return <DealDocumentsCardBody dealId={dealId} {...card} />;
    case 'StratificationsCard':
      return <StratCardBody dealId={dealId} {...card} />;
    case 'LoansCard':
      return <LoansCardBody dealId={dealId} {...card} />;
    case 'CarveDetailsCard':
      return <CarveDetailsCardBody dealId={dealId} {...card} />;
    case 'DiligenceCard':
      return <DiligenceController dealId={dealId} {...card} />;
    case 'PlaceBidCard':
      return <BiddingAndPricingController dealId={dealId} {...card} />;
    case 'BidCard':
      return <BidCardBody dealId={dealId} {...card} />;
    case 'SettlementCard':
      return <SettlementCardBody dealId={dealId} seller={seller} {...card} />;
    case 'WireConfirmationCard':
      return (
        <WireConfirmationCardBody
          dealId={dealId}
          dealState={dealState}
          {...card}
        />
      );
    case 'PlaceholderCard':
      return null;
    default:
      return <DefaultCardBody />;
  }
}

const formatLastUpdatedDate = (lastUpdatedDate: string) => {
  const lastUpdatedDateTime = DateTime.fromISO(lastUpdatedDate);
  return `${lastUpdatedDateTime.toRelativeCalendar()} @ ${
    lastUpdatedDateTime.diffNow().as('days') > -1
      ? lastUpdatedDateTime.toFormat('tt')
      : lastUpdatedDateTime.toFormat('F')
  }`;
};

type EventActionCardProps = {
  card: GetTimeline_deal_cards;
  seller: GetDealGeneralInfo_deal_listing_UserCompanyListing_seller;
  dealId: string;
  role: string;
  isDefaultExpanded: boolean;
  isExpanded: boolean;
  expand: () => void;
  dealState: DealStagesDeal_state;
};

const EventActionCard = ({
  card,
  dealId,
  isExpanded,
  expand,
  role,
  seller,
  dealState,
}: EventActionCardProps): JSX.Element => {
  const lastUpdatedDate = useMemo(() => {
    if (
      card.__typename !== 'PlaceholderCard' &&
      card.last_updated_date != null
    ) {
      return formatLastUpdatedDate(card.last_updated_date);
    } else {
      return ``;
    }
  }, [card.last_updated_date, card.__typename]);

  const isPlaceholder = card.__typename === 'PlaceholderCard';
  const toolTip = isPlaceholder ? card.tooltip : '';
  const toolTipId = `${card.title}-tooltip`;

  return (
    <>
      <Card
        active={isExpanded}
        disabled={isPlaceholder}
        data-tooltip-id={toolTipId}
        onClick={() => {
          if (card.__typename !== 'PlaceholderCard') expand();
        }}
      >
        <div className="flex justify-between">
          <h1 className="mb-0 text-[13px]">{card.title}</h1>
          <div>
            <span className="text-xs text-gray-700">{lastUpdatedDate}</span>
          </div>
        </div>
        <div
          className={`${
            isExpanded
              ? 'mt-2 max-h-full overflow-visible'
              : 'mt-0 max-h-0 overflow-hidden'
          }`}
        >
          {renderCardBody(card, dealId, role, seller, dealState)}
        </div>
      </Card>
      {isPlaceholder && (
        <Tooltip place="top" variant="light" id={toolTipId}>
          {toolTip}
        </Tooltip>
      )}
    </>
  );
};

type EventActionCardsProps = {
  dealId: string;
  role: string;
  cards: GetTimeline_deal_cards[];
  seller: GetDealGeneralInfo_deal_listing_UserCompanyListing_seller;
  dealState: DealStagesDeal_state;
};

const EventActionCards = memo(
  ({
    dealId,
    cards,
    role,
    seller,
    dealState,
  }: EventActionCardsProps): JSX.Element => {
    const lastNonPlaceholderIndex = useMemo(
      () =>
        findLastIndex(cards, (card) => card.__typename !== 'PlaceholderCard'),
      [cards],
    );
    const getCardKey = (card: GetTimeline_deal_cards, index: number): string =>
      card.title === 'Bidding and Pricing'
        ? card.title
        : `${card.title}-${index}`;

    const { currentCard, setCurrentCard } = useDealRoomContext();

    useEffect(() => {
      if (lastNonPlaceholderIndex >= 0) {
        const cardKey = getCardKey(
          cards[lastNonPlaceholderIndex],
          lastNonPlaceholderIndex,
        );
        setCurrentCard(cardKey);
      }
    }, []);

    return (
      <div className="h-full flex-grow overflow-auto">
        <div className="flex h-max flex-1 flex-col">
          {cards.map((card, index) => {
            const cardKey = getCardKey(card, index);
            if ('is_under_review' in card && card.is_under_review) {
              setCurrentCard(cardKey);
            }
            return (
              <EventActionCard
                key={cardKey}
                seller={seller}
                role={role}
                card={card}
                dealId={dealId}
                expand={() => setCurrentCard(cardKey)}
                isExpanded={cardKey === currentCard}
                isDefaultExpanded={index === lastNonPlaceholderIndex}
                dealState={dealState}
              />
            );
          })}
        </div>
      </div>
    );
  },
);

const DefaultCardBody = (): JSX.Element => {
  return <div />;
};

export default EventActionCards;
