import { gql } from '@apollo/client';

const GET_WIRE_DETAILS_UPLOAD_URL = gql`
  mutation GetWireDetailsFileUploadURL($input: WireDetailsFileUploadURLInput!) {
    getWireDetailsFileUploadURL(input: $input) {
      URL
    }
  }
`;

export default GET_WIRE_DETAILS_UPLOAD_URL;
