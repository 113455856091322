import { useState } from 'react';

import poolSuccessImgPath from 'assets/pngs/carve-confirmation.png';
import { ModalNotStyled, Spinner } from 'common-ui';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { DialogActions, DialogBodyText } from 'common-ui/Dialog.styles';
import { PillFilters } from 'features/common-elements';
import { LoanTable } from 'features/drilldown/LoanDatatable/LoanTable';
import toast from 'react-hot-toast';
import { Button } from 'ui-kit';
import { DownloadDocumentButton } from 'ui-kit';
import { downloadExcelFromJson } from 'utils/excelUtils';

import CreatePoolForm, { FormValues } from './CreatePoolForm';
import { useCreatePool } from './hooks/useCreatePool';
import { useLoanLevelData } from './hooks/useLoanLevelData';
import { usePortfolioLoans } from './hooks/usePortfolioLoans';
import { useSelection } from './hooks/useSelection';
import { PortfolioLayout } from './Portfolio/PortfolioLayout';
import {
  SelectionStatus,
  SelectionStatusLine,
} from '../../../common-ui/SelectionStatusLine';

const LoanLevelData = () => {
  const {
    gqlAssetClass,
    filterProps,
    navigateToPoolManager,
    queryFilters,
    loansSorting,
    setLoansSorting,
  } = useLoanLevelData();

  // Dialogs
  const poolCreatedDialog = useDialog();
  const errorDialog = useDialog();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { createPool, lastCreatedPool } = useCreatePool();

  const { data, loading, previousData, params, updateParams } =
    usePortfolioLoans(gqlAssetClass, queryFilters, loansSorting);

  const {
    selectedLoans,
    setSelectedLoans,
    selectionStatus,
    setSelectionStatus,
    onClear,
    onSelectPage,
    onSelectAll,
  } = useSelection(params, data);

  if (!gqlAssetClass || !filterProps.filtersConfig) {
    return <PortfolioLayout />;
  }

  const dataToShow = data || previousData;
  const loanCount = dataToShow?.user.company.performanceData.loanCount || 0;
  const unlistedLoansCount =
    dataToShow?.user.company.unlistedLoansPerformanceData.loanCount || 0;

  const potentialPoolSize =
    selectionStatus === SelectionStatus.AllSelected
      ? unlistedLoansCount
      : Object.keys(selectedLoans).length;

  const onSubmitCreatePoolForm = (formValues: FormValues) => {
    createPool(
      formValues,
      gqlAssetClass,
      queryFilters,
      selectedLoans,
      selectionStatus,
    )
      .then(() => {
        poolCreatedDialog.openDialog();
      })
      .catch((error) => {
        toast.error(`Error creating pool: "${error}".`);
        setErrorMessage(
          'There was an error creating the loan pool. Please retry or contact support.',
        );
        errorDialog.openDialog();
      });
  };

  const handleDownloadExcel = () => {
    if (!dataToShow?.user.company.loans) {
      toast.error('No data available for download.');
      return;
    }

    const selectedLoansIds = Object.keys(selectedLoans).map(
      (id) => id.split(':')[1],
    );

    const dowloadData = dataToShow.user.company.loans.filter((loan) =>
      selectedLoansIds.length
        ? selectedLoansIds.includes(loan.id)
        : loan.listing === null,
    );

    downloadExcelFromJson(dowloadData, 'Loan Data', 'Loan Data.xlsx');
  };

  //if need to select loans which are not in a pool
  // const filterSelectableLoans = (
  //   newSelection: Updater<RowSelectionState>,
  //   loans: GetDrilldown_user_company_loans[],
  // ): Updater<RowSelectionState> => {
  //   return Object.fromEntries(
  //     Object.entries(newSelection).filter(([key]) => {
  //       const loanId = key.split(':')[1];
  //       const loan = loans.find((loan) => loan.id === loanId);
  //       return !loan?.listing;
  //     }),
  //   );
  // };

  return (
    <PortfolioLayout
      filterRow={
        <PillFilters
          {...filterProps}
          filtersConfig={filterProps.filtersConfig}
        />
      }
      actions={
        <div className="flex items-start gap-2">
          <ModalNotStyled
            trigger={
              <Button
                size="small"
                type="primary_dark"
                disabled={potentialPoolSize === 0}
                label="select loans"
                className="w-max"
              >
                {potentialPoolSize === 0
                  ? 'Select loans to create a pool'
                  : `Create pool of ${potentialPoolSize.toLocaleString()} loans`}
              </Button>
            }
          >
            {({ closeModal }) => (
              <CreatePoolForm
                closeModal={closeModal}
                createPool={onSubmitCreatePoolForm}
              />
            )}
          </ModalNotStyled>
          <div>
            <DownloadDocumentButton
              onClick={handleDownloadExcel}
              fileType="excel"
              tooltipText="Download Excel"
              tooltipPosition="left"
              className="mr-4"
              disabled={selectionStatus === SelectionStatus.AllSelected}
            />
          </div>
        </div>
      }
    >
      {dataToShow ? (
        <>
          <SelectionStatusLine
            status={selectionStatus}
            numberSelectedOnPage={Object.keys(selectedLoans).length}
            totalItems={unlistedLoansCount}
            onClear={onClear}
            onSelectAll={onSelectAll}
          />
          <LoanTable
            data={dataToShow?.user.company.loans ?? []}
            assetClass={gqlAssetClass}
            strings={{ empty: 'No Loans Found' }}
            selection={{
              selected: selectedLoans,
              onSelectionChange: (newSelection) => {
                //if need to select loans which are not in a pool
                // const filteredSelection = filterSelectableLoans(
                //   newSelection,
                //   dataToShow.user.company.loans,
                // );

                setSelectedLoans(newSelection);
                setSelectionStatus(SelectionStatus.None);
              },
              onHeaderCheckboxChange: (checked) => {
                if (checked) {
                  onSelectPage();
                } else {
                  onClear();
                }
              },
            }}
            sorting={{
              state: loansSorting,
              onSortingChanged: setLoansSorting,
            }}
            pagination={{
              updateParams: updateParams,
              queryParams: params,
              totalItems: loanCount,
            }}
          />
        </>
      ) : null}
      <Spinner loading={loading} />

      {/* For debugging, show list of selected account ids */}
      {/* <div>Selected Loans:</div>
      <div>{JSON.stringify(selectedLoans)}</div> */}

      <Dialog dialog={poolCreatedDialog}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={poolSuccessImgPath}
            className="h-[232px]"
            alt="bid succeeded"
          />
          <div className="mb-2 mt-[19px] text-center text-[24px] font-medium leading-[36px] text-white">
            Pool Created
          </div>
          <div className="mb-[32px] w-[300px] text-[16px] leading-[24px] tracking-[0px] text-white ">
            "{lastCreatedPool?.friendlyName}" is now available. Would you like
            to go to the Pool Manager?
          </div>
          <div className="flex w-[150px] justify-between gap-[30px]">
            <Button
              size="medium"
              type="secondary"
              label="no"
              onClick={() => poolCreatedDialog.closeDialog()}
            >
              No
            </Button>
            <Button
              size="medium"
              label="yes"
              onClick={() => {
                poolCreatedDialog.closeDialog();
                navigateToPoolManager();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog dialog={errorDialog}>
        <>
          <div>{errorMessage}</div>
          <DialogActions>
            <Button
              size="medium"
              label="cancel"
              onClick={() => errorDialog.closeDialog()}
            >
              OK
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </PortfolioLayout>
  );
};

export default LoanLevelData;

//this is the DELETE loan query
// const DELETE_LOAN = gql`
//   mutation DeleteLoan($id: ID!) {
//     deleteLoan(id: $id)
//   }
// `;

// const [deleteLoan, { called, loading: loadingDeleteLoan }] =
//   useMutation<DeleteLoan>(DELETE_LOAN);

// deleteLoan({
//   variables: { id: row.id },
//   update: (cache) => {
//     const normalizedId = cache.identify({
//       id: row.id,
//       __typename: assetClassToTypename(gqlAssetClass),
//     });
//     cache.evict({ id: normalizedId });
//     cache.gc();
//   },
// });
