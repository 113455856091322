import React from 'react';

import { IconFileTypePdf, IconInfoCircle } from '@tabler/icons-react';
import { IndeterminateCheckbox } from 'common-ui';
import { Tooltip } from 'react-tooltip';
import { LinkButton, FileListRow } from 'ui-kit';

import { GetContractsForDeal_getContractsForDeal as Contract } from 'query/__generated__/GetContractsForDeal';

import { SettlementCard_SettlementCard } from './__generated__/SettlementCard';
import { DocumentStatus, getDocumentStatus } from './settlement_utils';

interface DocumentRowProps {
  document: Contract;
  role: SettlementCard_SettlementCard['role'];
  selectedDocumentId: string | null;
  onRowClick: (contractId: string) => void;
  onDownload: (
    contractId: string,
    contractVersion: string,
    error?: string | null,
  ) => void;
  onDelete: (contractId: string, contractVersion: string) => void;
  onCheckboxChange: (
    id: string,
    contractVersion: string,
    isReady: boolean,
  ) => void;
  isCompleted: boolean | undefined;
}

const DocumentRow: React.FC<DocumentRowProps> = ({
  document,
  role,
  selectedDocumentId,
  onRowClick,
  onDownload,
  onDelete,
  onCheckboxChange,
  isCompleted,
}) => {
  const {
    buyerSigned,
    sellerSigned,
    readyToSignBuyer,
    readyToSignSeller,
    sellerReferenceText,
    buyerReferenceText,
  } = document.data;

  const { status, canDeleteOrSelect, showCheckbox } = getDocumentStatus({
    buyerSigned,
    sellerSigned,
    readyToSignBuyer,
    readyToSignSeller,
    role,
    sellerReferenceText,
    buyerReferenceText,
    error: document.contractSigningError,
  });

  return (
    <FileListRow
      onClick={() => onRowClick(document.contractId)}
      isSelected={selectedDocumentId === document.contractId}
    >
      <div className="w-[35%] truncate px-2 text-slate-200 ">
        {document.originalFileName}
      </div>
      <div className="flex w-[30%] items-center text-left text-slate-200">
        <span
          className={`${status === DocumentStatus.FAILED_TO_SIGN ? 'text-red-400' : 'text-yellow-500'} text-xxs`}
        >
          {status}
        </span>
        {status === DocumentStatus.FAILED_TO_SIGN && (
          <>
            <IconInfoCircle
              stroke={2}
              size={18}
              className="ml-2 text-red-400"
              data-tooltip-id="error"
            />
            <Tooltip id="error" className="max-w-[300px]" place="left">
              <p>
                Contract signing error - our engineers were notified and will
                fix it or reach out shortly.
              </p>
            </Tooltip>
          </>
        )}
      </div>
      <div className="flex w-[10%] justify-end gap-3">
        <LinkButton
          icon={<IconFileTypePdf size={16} />}
          text="Download"
          iconPosition="before"
          onClick={() =>
            onDownload(
              document.contractId,
              document.contractVersion,
              document.contractSigningError,
            )
          }
          className="text-slate-200"
        />
      </div>
      <div
        className={`flex w-[${role === 'SELLER' && !isCompleted ? '10%' : '35%'}] items-center justify-end`}
      >
        <IconInfoCircle
          stroke={2}
          size={18}
          className="mr-2"
          data-tooltip-id="info"
        />
        <IndeterminateCheckbox
          checked={role === 'BUYER' ? !!readyToSignBuyer : !!readyToSignSeller}
          onChange={() =>
            onCheckboxChange(
              document.contractId,
              document.contractVersion,
              role === 'BUYER' ? !!readyToSignBuyer : !!readyToSignSeller,
            )
          }
          disabled={!showCheckbox}
        />
        <Tooltip id="info" className="max-w-[300px]" place="left">
          <p>
            Checking this box confirms that the document is ready to be signed.
          </p>
        </Tooltip>
      </div>

      {role === 'SELLER' && !isCompleted && (
        <div className="flex w-[15%] items-center justify-end pr-2">
          <LinkButton
            text="Remove"
            disabled={!canDeleteOrSelect}
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation();
              onDelete(document.contractId, document.contractVersion);
            }}
          />
        </div>
      )}
    </FileListRow>
  );
};

export default DocumentRow;
