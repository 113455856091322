import { gql } from '@apollo/client';

import BidStipulationFragments from './BidStipulations.fragments';
import CarveTableFragments from './CarveTable.fragments';

const BiddingAndPricingControllerFragments = {
  TimelineCard: gql`
    fragment BiddingAndPricingControllerCard on TimelineCard {
      ... on PlaceBidCard {
        bid {
          stipulations {
            bid_basis_points
          }
          bid_id
          seller_bid_basis_points
          price {
            rate
            price
            unpaid_balance
          }
        }
        assetClass: asset_class
        ...CarveTablePlaceBidCard
        ...BidStipulationsPlaceBidCard
      }
    }
    ${CarveTableFragments.PlaceBidCard}
    ${BidStipulationFragments.PlaceBidCard}
  `,
};

export default BiddingAndPricingControllerFragments;
