import React, { ReactNode } from 'react';

import { IconX } from '@tabler/icons-react';
import { Button } from 'baseui/button';
import { Option } from 'features/common-elements';
import { Form, useField, useFormikContext } from 'formik';
import { MultiValue, SingleValue } from 'react-select';
import { styled } from 'style/ORSNNTheme';

import { Dropdown } from '../Dropdown';
import { UiInput, UiInputProps } from '../Input';
import { ErrorMessage } from './ErrorMessage';
import closeIcon from '../../assets/svgs/close-gray.svg';

const isMultiValue = (
  value: SingleValue<Option> | MultiValue<Option>,
): value is MultiValue<Option> => {
  return Array.isArray(value);
};

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url(${closeIcon});
  background-size: cover;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
`;

interface SubmitButtonProps {
  label: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ label }) => {
  return (
    <SubmitButtonContainer>
      <Button size="compact" type="submit">
        {label}
      </Button>
    </SubmitButtonContainer>
  );
};

export interface StandardFormProps {
  closeModal: () => void;
  children: ReactNode;
}

const Error = styled.div`
  grid-column: 2;
  color: red;
  margin-top: 5px;
`;

const StandardForm: React.FC<StandardFormProps> = ({
  closeModal,
  children,
}) => {
  return (
    <Form>
      <div className="relative rounded-[8px] border border-pink-500 bg-slate-950 px-6 py-2 text-[14px] text-slate-400">
        <IconX
          onClick={closeModal}
          className="t absolute right-[16px] top-[16px] h-5 w-5 cursor-pointer"
        />
        {children}
      </div>
    </Form>
  );
};

interface FormikDropdownFieldProps {
  name: string;
  options: Option[];
  isMulti?: boolean;
  defaultValue?: Option;
}

interface FormikTextInputProps extends Partial<UiInputProps> {
  name: string;
}

const Label = styled.label`
  align-self: center;
`;

const FormikTextInput = ({
  name,
  variant = 'primary',
  size = 'small',
  ...rest
}: FormikTextInputProps) => {
  const [field, meta] = useField(name);

  return (
    <div>
      <UiInput size={size} variant={variant} {...field} {...rest} />
      <ErrorMessage>
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      </ErrorMessage>
    </div>
  );
};

const FormikDropdownField: React.FC<FormikDropdownFieldProps> = ({
  name,
  options,
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  // Find the selected option from the value in the field
  const selectedOption = Array.isArray(field.value)
    ? options.filter((option) => field.value.includes(option.value))
    : options.find((option) => option.value === field.value);

  return (
    <div>
      <Dropdown
        variant={'gray' as 'gray'}
        options={options}
        value={
          Array.isArray(selectedOption)
            ? selectedOption?.[0]?.label
            : selectedOption?.label
        }
        onValueChange={(option) => {
          const value = option?.value;
          setFieldValue(name, value || '');
          helpers.setTouched(true);
        }}
      />
      <ErrorMessage>
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      </ErrorMessage>
    </div>
  );
};

export {
  StandardForm,
  CloseButton,
  Error,
  Label,
  FormikTextInput,
  FormikDropdownField,
  SubmitButton,
};
