type BidSummarySectionProps = {
  currentBalance: string;
  bidPrice: string;
  pricePercent: string;
  rate: string;
};

const BidSummarySection = ({
  currentBalance,
  bidPrice,
  pricePercent,
  rate,
}: BidSummarySectionProps): JSX.Element => {
  const stats = [
    { label: 'UPB of Carve', value: currentBalance },
    { label: 'Price', value: bidPrice },
    { label: 'Price as % of UPB', value: pricePercent },
    { label: 'Net Yield at Price', value: rate },
  ];

  return (
    <div className="mt-3 w-full">
      <h3 className="mb-3 text-sm uppercase text-slate-200">Bid Summary</h3>
      {stats.map(({ label, value }) => (
        <div
          key={label}
          className="flex justify-between border-t border-slate-600 px-3 py-2"
        >
          <span className="text-sm text-slate-200">{label}</span>
          <span className="text-sm text-slate-200">{value}</span>
        </div>
      ))}
    </div>
  );
};

export default BidSummarySection;
