import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { Accordion, Subheader, Tab, Tabs } from 'ui-kit';

import { SettlementCard_SettlementCard } from './__generated__/SettlementCard';
import { SettlementPrices, SettlementPricesProps } from './SettlementPrices';
import TransactionDocuments from './TransactionDocuments';
import { GetDealGeneralInfo_deal_listing_UserCompanyListing_seller } from '../../../dealCards/gql/__generated__/GetDealGeneralInfo';

type SettlementCardBodyProps = {
  dealId: string;
  seller: GetDealGeneralInfo_deal_listing_UserCompanyListing_seller;
} & SettlementCard_SettlementCard;

const SettlementCardBody: React.FC<SettlementCardBodyProps> = (props) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (tabIdx: number) => {
    if (!!tabIdx && !props?.prices?.isVisibleToBuyer) {
      toast.error(
        'Please fill out and send clean and dirty price before proceeding to Transaction Document upload.',
      );
      return;
    }
    setActiveTab(tabIdx);
  };

  useEffect(() => {
    props?.prices?.isVisibleToBuyer && setActiveTab(1);
  }, [props?.prices?.isVisibleToBuyer]);

  return (
    <div className="flex flex-col gap-6">
      <Tabs activeTab={activeTab} onTabChange={handleTabChange}>
        <Tab label="Prices">
          <Accordion label={'Prices'} initialIsOpen={true}>
            {!!props?.prices && (
              <SettlementPrices {...(props as SettlementPricesProps)} />
            )}
          </Accordion>
        </Tab>
        <Tab label="Transaction Documents">
          <Subheader>{`Review ${props.role === 'SELLER' ? 'and Upload' : ''} Final Versions of the Settlement Documents`}</Subheader>
          <TransactionDocuments
            dealId={props.dealId}
            role={props.role}
            isCompleted={props.is_complete}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default SettlementCardBody;
export { default as SettlementCardFragments } from './fragments';
