import { IconPlus } from '@tabler/icons-react';
import { MultiValue, SingleValue } from 'react-select';

import {
  PillButtonCombobox,
  PillButtonComboboxProps,
} from './PillButtonCombobox';
import { IconButton } from '../../ui-kit';
import { usePopout, FloatingPortal } from '../hooks/usePopout';

export function DropdownPillComboboxSingleAdd<T extends object>({
  buttonLabel,
  onValueSelected,
  ...comboProps
}: Omit<
  PillButtonComboboxProps<T, false>,
  'onCloseBtnClick' | 'onValueSelected'
> & {
  buttonLabel: string;
  onValueSelected: (value: T) => void;
}) {
  const {
    refs,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    floatingStyles,
    setIsOpen,
  } = usePopout();

  function onSelected(value: SingleValue<T> | MultiValue<T>) {
    onValueSelected(value as T);
  }

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <IconButton
          label={buttonLabel}
          tooltipText="Add Filters"
          className="h-8 w-8 rounded-full border border-gray-700 bg-gray-950  p-[7px] hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-pink-400"
          variant="icon-only"
          icon={<IconPlus size={16} className="text-gray-200" />}
        />
      </div>

      {isOpen ? (
        <FloatingPortal>
          <div
            className="z-10"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <PillButtonCombobox<T, false>
              {...comboProps}
              onClose={() => setIsOpen(false)}
              onValueSelected={onSelected}
            />
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
}
