import React from 'react';

type DisplayTableRowCellProps = {
  children?: React.ReactNode | string;
  minW?: string;
  maxW?: string;
  className?: string;
  fontClassName?: string;
};

export const DisplayTableRowCell = ({
  children,
  minW = '20px',
  maxW = '600px',
  className,
  fontClassName,
}: DisplayTableRowCellProps) => {
  const fontClassNames =
    fontClassName || 'font-pt-mono text-[14px] leading-[20px] p-1';
  const classNames = `flex flex-1 min-w-[${minW}] max-w-[${maxW}] items-center  overflow-hidden ${fontClassNames} ${className}`;
  return (
    <div className={classNames} style={{ minWidth: minW, maxWidth: maxW }}>
      {children}
    </div>
  );
};
