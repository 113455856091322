import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query GetUserInfo {
    user {
      id
      email
      given_name
      family_name
      address
      role
      title
      company {
        id
      }
    }
  }
`;
