import { CSSProperties } from 'react';

import { Column } from '@tanstack/react-table';
import { isBoolean, isEnumMember, isType } from 'utils/typeUtils';

import { WithSubData } from './DataDisplayTable';

export enum Alignment {
  left = 'left',
  center = 'center',
  right = 'right',
}

export type ColumnMeta = {
  alignment: Alignment;
  numeric: boolean;
  headerNoPill?: boolean;
};

export const isColumnMeta = isType<ColumnMeta>({
  numeric: isBoolean,
  alignment: isEnumMember(Alignment),
});

export const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const getCommonPinningStyles = <T,>(
  column: Column<WithSubData<T>, unknown>,
  zIndex = 0,
  isHeader = false,
): CSSProperties => {
  const isPinned = column.getIsPinned();

  return {
    left: `${column.getStart('left')}px`,
    position: isPinned || isHeader ? 'sticky' : undefined,
    width: column.getSize(),
    top: isHeader ? 0 : undefined,
    zIndex: isHeader ? zIndex + 2 : isPinned ? zIndex + 1 : zIndex,
  };
};
