import React from 'react';

import { IconCheck, IconMinus } from '@tabler/icons-react';

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  onChange: (event: unknown) => void;
  label?: string;
  size?: 'default' | 'small';
  icon?: 'check' | 'minus';
  type?: 'default' | 'gray';
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  disabled,
  onChange,
  label,
  size = 'default',
  type = 'default',
  icon = 'check',
}) => {
  const sizeClasses =
    size === 'small' ? 'h-3 w-3 text-[12px]' : 'h-[18px] w-[18px] text-[14px]';

  const borderClasses = {
    default: `border-[2px] ${
      disabled && checked
        ? 'border-gray-800'
        : checked
          ? 'border-white'
          : disabled
            ? 'border-gray-800'
            : 'border-gray-700'
    }`,
    gray: `border-[2px] ${
      disabled && checked
        ? 'border-gray-800'
        : checked
          ? 'border-white'
          : disabled
            ? 'border-gray-800'
            : 'border-gray-700'
    }`,
  };

  const iconColor = disabled ? 'text-gray-600' : 'text-white';

  const labelClasses = `${
    size === 'small' ? 'text-[12px]' : 'text-[14px]'
  } font-heebo text-medium ml-2 ${disabled ? 'text-gray-800' : 'text-white'}`;

  const renderIcon = () => {
    if (!checked) return null;
    return icon === 'check' ? (
      <IconCheck stroke={2} className={iconColor} />
    ) : (
      <IconMinus stroke={2} className={iconColor} />
    );
  };

  return (
    <div className="flex cursor-pointer items-center">
      <div
        className={`flex items-center justify-center rounded-[3px] ${sizeClasses} ${borderClasses[type]} ${
          disabled ? '' : 'hover:bg-gray-800'
        } transition-all duration-300`}
        onClick={!disabled ? onChange : undefined}
      >
        {renderIcon()}
      </div>
      {label && <span className={labelClasses}>{label}</span>}
    </div>
  );
};
