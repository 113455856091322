import React from 'react';

type DisplayTableHeaderProps = {
  children?: React.ReactNode;
  label?: string;
  minW?: string;
  maxW?: string;
  className?: string;
  fontClassName?: string;
};

export const DisplayTableHeaderCell = ({
  children,
  label,
  minW = '20px',
  maxW = '400px',
  className,
  fontClassName,
}: DisplayTableHeaderProps) => {
  const fontClassNames =
    fontClassName || 'text-[12px] leading-[20px] px-1 py-3';
  const classNames = `flex-1 min-w-[${minW}] max-w-[${maxW}] ${fontClassNames} ${className}`;
  return (
    <div className={classNames} style={{ minWidth: minW, maxWidth: maxW }}>
      {label}
      {children}
    </div>
  );
};
