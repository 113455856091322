import React, { useState } from 'react';

import { IconChevronDown } from '@tabler/icons-react';

type AccordionProps = {
  children: React.ReactNode;
  label: string;
  initialIsOpen?: boolean;
  hasBorder?: boolean;
};
export const Accordion = ({
  children,
  label,
  initialIsOpen,
  hasBorder = true,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen || false);

  const borderClass = hasBorder
    ? `rounded-[8px] border border-gray-900 p-3`
    : '';
  const containerClass = `w-full ${borderClass}`;

  return (
    <div className={containerClass}>
      <div className={`w-full ${hasBorder ? '' : 'p-2'}`}>
        <div
          className="flex w-full items-center justify-between text-[14px] text-slate-400"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{label}</span>
          <span
            className={`transition-transform ${isOpen ? 'rotate-180' : ''}`}
          >
            <IconChevronDown size={16} />
          </span>
        </div>
        {isOpen && children}
      </div>
    </div>
  );
};
