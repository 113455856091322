import { useMemo } from 'react';

import { basisPointsToPercent } from 'features/deals/DealStages/EventActionCards/utils';

import { formatCentsToDollars } from '../formatting';
import BidSummaryTemplate from './BidSummaryTemplate';

interface BidSummaryProps {
  netCoupon: number;
  unpaidBalance: number;
  netYield?: number;
  spread?: number;
  yieldDuration?: number;
  spreadDuration?: number;
  participationPercent: number | null;
  bidBasisPoints?: number | null;
  servicingRate: number;
  defaultAssumptionType: string;
  cdr?: number;
  prepaymentAssumptionType?: string;
  cpr?: number;
}

export interface BidSummaryInfo {
  netYield?: number;
  yieldDuration?: number;
  spread?: number;
  spreadDuration?: number;
  purchaseDollarPrice: number;
}

const BidSummary = ({
  netCoupon,
  unpaidBalance,
  netYield,
  spread,
  yieldDuration,
  spreadDuration,
  participationPercent,
  bidBasisPoints,
  servicingRate,
  defaultAssumptionType,
  cdr,
  prepaymentAssumptionType,
  cpr,
}: BidSummaryProps) => {
  const gwac = netCoupon * 100;

  const netWeightedAverageCoupon = useMemo(
    () => (servicingRate > 0 ? gwac - servicingRate : gwac),
    [gwac, servicingRate],
  );

  // 0 is false in JS
  const calculatedPurchaseDollarPrice = useMemo(() => {
    if (
      bidBasisPoints !== null &&
      bidBasisPoints !== undefined &&
      participationPercent !== null &&
      participationPercent !== undefined
    ) {
      const priceDecimal =
        parseFloat(basisPointsToPercent(bidBasisPoints)) / 100;
      let finalPrice = priceDecimal * unpaidBalance;

      if (participationPercent > 0) {
        finalPrice =
          priceDecimal * unpaidBalance * (participationPercent / 100);
      }

      return formatCentsToDollars(finalPrice);
    }
    return '-';
  }, [bidBasisPoints, unpaidBalance, participationPercent]);

  const calculatedNetYieldAtPrice = useMemo(() => {
    if (netWeightedAverageCoupon && bidBasisPoints) {
      const pricePercent = parseFloat(basisPointsToPercent(bidBasisPoints));
      return pricePercent !== 0
        ? ((100 * netWeightedAverageCoupon) / pricePercent).toFixed(3) + '%'
        : '-';
    }
    return '-';
  }, [netWeightedAverageCoupon, bidBasisPoints]);

  const getAverageDuration = useMemo(() => {
    if (yieldDuration == null) {
      return spreadDuration;
    }
    if (spreadDuration == null) {
      return yieldDuration;
    }
    return (yieldDuration + spreadDuration) / 2;
  }, [yieldDuration, spreadDuration]);

  const bidPrice = useMemo(() => {
    return bidBasisPoints !== null && bidBasisPoints !== undefined
      ? basisPointsToPercent(bidBasisPoints)
      : '';
  }, [bidBasisPoints]);

  const safeNetYield = !isNaN(Number(netYield))
    ? Number(netYield).toFixed(3)
    : '';

  return (
    <BidSummaryTemplate
      netWeightedAverageCoupon={netWeightedAverageCoupon.toFixed(3)}
      gwac={gwac.toFixed(3)}
      unpaidBalance={formatCentsToDollars(unpaidBalance)}
      bidPrice={bidPrice}
      netYieldAtPrice={calculatedNetYieldAtPrice}
      netYield={safeNetYield}
      spread={spread?.toFixed(0) || ''}
      duration={getAverageDuration?.toFixed(3) || ''}
      defaultAssumptionType={defaultAssumptionType || 'CDR'}
      cdr={cdr || ''}
      prepaymentAssumptionType={prepaymentAssumptionType || ''}
      cpr={cpr || ''}
      purchaseDollarPrice={calculatedPurchaseDollarPrice}
    />
  );
};

export default BidSummary;
