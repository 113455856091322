import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Header,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { TableQueryParameters } from 'hooks/useTableQueryParams';
import { CSVLink } from 'react-csv';
import isEqual from 'react-fast-compare';
import { Button, PaginationControls, Table } from 'ui-kit';

import { Empty, EmptyAction } from './Empty';
import { RenderHeader } from './renderHeader';
import { RenderRow } from './renderRow';
import {
  createCheckBoxSelectionColumn,
  radioButtonSelectionColumn,
} from './selectionColumn';
import { Alignment, ColumnMeta } from './utils';

export interface FlattenedRow {
  id: string;
  loan_count: number;
  wa_borrower_credit_score: number;
  wa_coupon: number;
  wa_loan_age_months: number;
  wa_ltv: number;
  wa_remaining_loan_terms_months: number;
  name: string;
  assetClass: string;
  upb: number;
  wac: number;
  createdDate: string;
  providedName: string;
  is_public?: boolean;
  deal_id: string;
  deal_counterparty_name: string;
  deal_createdDate: string;
  deal_state_status: string;
  deal_state_label: string;
}

function flattenData<T>(rows: Row<WithSubData<T>>[]) {
  return rows.map((row) => {
    // Determine if the row itself is a listing or deals need to be accessed
    const listing =
      row.original.__typename === 'PublicListing'
        ? row.original
        : row.original.deals?.[0]?.listing;

    // Extract deal information if available directly or from the deals array
    const deal =
      row.original.__typename === 'Deal'
        ? row.original
        : row.original.deals?.[0];

    return {
      'Loan Pool Name': listing?.providedName || '',
      'Last Viewed': listing?.lastViewed || '',
      Offered: listing?.offered || '',
      'Pool Status': deal?.state?.label || '',
      'Date Created': deal?.createdDate || listing?.createdDate || '',
      Counterparty: deal?.counterparty?.name || '',
      'Asset Class': listing?.assetClass || '',
      UPB: listing?.upb || '',
      '# Loans': listing?.loan_count || '',
      GWAC: listing?.wa_coupon || '',
      WALA: listing?.wa_loan_age_months || '',
      WAM: listing?.wa_remaining_loan_terms_months || '',
      FICO: listing?.wa_borrower_credit_score || '',
      LTV: listing?.wa_ltv || '',
    };
  });
}

export type WithSubData<T> = T & {
  last_updated_date: string;
  __typename: string;
  id?: string;
  deals?: any;
  subData?: WithSubData<T>[];

  getIsLastColumn?: (s: string) => boolean;
};

export type DataDisplayTableStylingOptions = {
  rowColor?: string;
  alternateRowColor?: string;
  subRowColor?: string;
  alternateLightRows?: boolean;
};

export type DataDisplayTableProps<T, V> = {
  data: WithSubData<T>[];
  columns: ColumnDef<WithSubData<T>, V>[];
  noDataMessage: string;
  emptyAction?: EmptyAction;
  stylingOptions?: DataDisplayTableStylingOptions;
  selection?: {
    selected: RowSelectionState;
    onSelectionChange: OnChangeFn<RowSelectionState>;
    onHeaderCheckboxChange?: (checked: boolean) => void;
    enableMultiRowSelection?: boolean;
  };
  sorting?: {
    state: SortingState;
    onSortingChanged: OnChangeFn<SortingState>;
    isManual?: boolean;
  };
  getRowId?: (row: WithSubData<T>) => string;
  pagination?: {
    updateParams: (newParams: Partial<TableQueryParameters<any, any>>) => void;

    queryParams: TableQueryParameters<any, any>;
    totalItems?: number;
  };
  downloadCSV?: boolean;
  isDraggable?: boolean;
};

function useDeepCompareMemoObject<T>(value: T): T {
  const ref = useRef<T>();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current as T;
}

function _DataDisplayTable<T, V>({
  data,
  columns: propColumns,
  noDataMessage,
  emptyAction,
  stylingOptions: propStylingOptions,
  selection,
  sorting,
  getRowId,
  pagination,
  downloadCSV,
  isDraggable = false,
}: DataDisplayTableProps<T, V>) {
  /* eslint-disable */
  const memoizedProps = useDeepCompareMemoObject({
    data,
    propColumns,
    noDataMessage,
    emptyAction,
    propStylingOptions,
    selection,
    sorting,
    getRowId,
    pagination,
  });
  const [isDragging, setIsDragging] = useState<string>('');

  const stylingOptions = useMemo(
    () => ({
      alternateLightRows: true,
      ...memoizedProps.propStylingOptions,
    }),
    [memoizedProps.propStylingOptions],
  );

  const selectionColumn = useMemo(() => {
    if (!memoizedProps.selection) return undefined;

    if (memoizedProps.selection.enableMultiRowSelection === false) {
      return radioButtonSelectionColumn as ColumnDef<WithSubData<T>, V>;
    }

    return createCheckBoxSelectionColumn(
      memoizedProps.selection.onHeaderCheckboxChange || (() => console.log(1)),
    ) as ColumnDef<WithSubData<T>, V>;
  }, [memoizedProps.selection]);

  const columns = useMemo(() => {
    const cols: ColumnDef<WithSubData<T>, V>[] = [...memoizedProps.propColumns];
    if (selectionColumn) {
      cols.unshift(selectionColumn);
    }
    return cols;
  }, [selectionColumn, memoizedProps.propColumns]);

  const handleRowSelectionChange: OnChangeFn<RowSelectionState> = useCallback(
    (updaterOrValue) => {
      if (
        memoizedProps.selection &&
        memoizedProps.selection.onSelectionChange
      ) {
        const newSelection =
          typeof updaterOrValue === 'function'
            ? updaterOrValue(memoizedProps.selection.selected)
            : updaterOrValue;
        memoizedProps.selection.onSelectionChange(newSelection);
      }
    },
    [memoizedProps.selection],
  );

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      if (memoizedProps.sorting) {
        const newSorting =
          typeof updaterOrValue === 'function'
            ? updaterOrValue(memoizedProps.sorting.state)
            : updaterOrValue;
        memoizedProps.sorting.onSortingChanged(newSorting);
      }
    },
    [memoizedProps.sorting],
  );

  const [expanded, setExpanded] = React.useState({});

  useEffect(() => {
    if (columns) {
      const order = columns?.map((col) => col.id || '');
      setColumnOrder(order);
      setColumnSizing(Object.fromEntries(order.map((item) => [item, 30])));
    }
  }, [columns]);

  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [columnSizing, setColumnSizing] = useState({});

  const table = useReactTable({
    data: memoizedProps.data,
    columns,
    state: {
      expanded,
      rowSelection: memoizedProps.selection?.selected || {},
      sorting: memoizedProps.sorting?.state || [],
      columnOrder,
      columnSizing,
      columnPinning: {
        left: ['selection'],
      },
    },
    columnResizeMode: 'onChange',
    onColumnSizingChange: setColumnSizing,
    enableColumnResizing: true,
    onColumnOrderChange: setColumnOrder,
    enableRowSelection: !!memoizedProps.selection,
    onRowSelectionChange: handleRowSelectionChange,
    onExpandedChange: setExpanded,
    onSortingChange: handleSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row: WithSubData<T>) => row.subData ?? [],
    enableMultiRowSelection:
      memoizedProps.selection?.enableMultiRowSelection !== false,
    manualSorting: memoizedProps.sorting?.isManual,
    getRowId: memoizedProps.getRowId,
  });

  const rows = table.getRowModel().rows;

  const selectedRows = useMemo(() => {
    return rows.filter((row) => selection?.selected[row.id]);
  }, [rows, selection?.selected]);

  const selectedRowsIds = useMemo(() => {
    return selectedRows.map((row) => row.id);
  }, [selectedRows]);

  const flattenedData = useMemo(() => {
    return downloadCSV ? flattenData(selectedRows) : [];
  }, [selectedRows, downloadCSV]);
  /* eslint-enable */ //fixed React Hook "useMemo" is called in function
  return (
    <div className="m-0 flex h-full w-full flex-col overflow-y-auto border-none p-0">
      <div className="flex flex-grow flex-col overflow-auto">
        {downloadCSV && selectedRows.length > 0 && (
          <div className="mb-2 flex justify-end">
            <CSVLink data={flattenedData} filename="active_trades.csv">
              <Button type="primary" size="small" label="Download CSV">
                Download CSV
              </Button>
            </CSVLink>
          </div>
        )}
        <Table
          className="!bg-gray-950"
          setColumnOrder={setColumnOrder}
          disabledColumnsIds={['selection']}
          isDraggableColumns
          style={{
            width: table.getTotalSize(),
          }}
          headers={table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="relative">
              <SortableContext
                items={columnOrder}
                strategy={horizontalListSortingStrategy}
              >
                {headerGroup.headers.map(
                  (header: Header<WithSubData<T>, unknown>) => {
                    return (
                      <RenderHeader
                        isColumnResize={isDragging}
                        setIsDragging={setIsDragging}
                        header={header}
                        key={header.id}
                        isDraggable={isDraggable}
                        width={table.getState().columnSizing[header.id]}
                      />
                    );
                  },
                )}
              </SortableContext>
            </tr>
          ))}
          tbody={
            table.getRowModel().rows.length === 0 ? (
              <tr>
                <td>
                  <Empty
                    message={memoizedProps.noDataMessage}
                    action={memoizedProps.emptyAction}
                  />
                </td>
              </tr>
            ) : (
              table
                .getRowModel()
                .rows.map((row, index) => (
                  <RenderRow
                    isSelected={selectedRowsIds.includes(row.id)}
                    key={row.id}
                    isDragging={isDragging}
                    columnOrder={columnOrder}
                    row={row}
                    isLightRow={
                      stylingOptions.alternateLightRows
                        ? index % 2 !== 0
                        : false
                    }
                    stylingOptions={stylingOptions}
                    width={table.getState().columnSizing[row.id]}
                  />
                ))
            )
          }
        />
      </div>
      {memoizedProps.pagination && (
        <PaginationControls
          rowSelected={selectedRows.length}
          currentPage={memoizedProps.pagination.queryParams.pageNumber}
          pageSize={memoizedProps.pagination.queryParams.pageSize}
          totalItems={memoizedProps.pagination.totalItems}
          update={(page: number, pageSize: number) => {
            memoizedProps.pagination?.updateParams({
              pageNumber: page,
              pageSize,
            });
          }}
        />
      )}
    </div>
  );
}

const createDataSignature = (data: WithSubData<unknown>[]): string => {
  return data.map((item) => `${item.id}:${item.last_updated_date}`).join('|');
};

const arePropsEqual = (
  prevProps: DataDisplayTableProps<unknown, unknown>,
  nextProps: DataDisplayTableProps<unknown, unknown>,
): boolean => {
  // Compare data signatures
  const prevDataSignature = createDataSignature(prevProps.data);
  const nextDataSignature = createDataSignature(nextProps.data);

  if (prevDataSignature !== nextDataSignature) {
    console.log(
      'DataDisplayTable: data or status changed',
      prevDataSignature,
      nextDataSignature,
    );
    return false;
  }

  // Compare columns (shallow comparison of column definitions)
  if (
    prevProps.columns.length !== nextProps.columns.length ||
    !prevProps.columns.every(
      (col, index) => col.id === nextProps.columns[index].id,
    )
  ) {
    console.log('DataDisplayTable: columns changed');
    return false;
  }

  // Compare selection (shallow comparison)
  if (prevProps.selection?.selected !== nextProps.selection?.selected) {
    console.log('DataDisplayTable: selection changed');
    return false;
  }

  // Compare sorting (shallow comparison)
  if (prevProps.sorting?.state !== nextProps.sorting?.state) {
    console.log('DataDisplayTable: sorting changed');
    return false;
  }

  // Compare pagination (shallow comparison of relevant fields)
  if (
    prevProps.pagination?.queryParams.pageNumber !==
      nextProps.pagination?.queryParams.pageNumber ||
    prevProps.pagination?.queryParams.pageSize !==
      nextProps.pagination?.queryParams.pageSize ||
    prevProps.pagination?.totalItems !== nextProps.pagination?.totalItems
  ) {
    console.log('DataDisplayTable: pagination changed');
    return false;
  }

  // If none of the above checks triggered a re-render, consider the props equal
  return true;
};

export const DataDisplayTable = React.memo(_DataDisplayTable, arePropsEqual);

export type TableEmptyAction = EmptyAction;
export type TableColumnAlignment = Alignment;
export type TableColumnMeta = ColumnMeta;
