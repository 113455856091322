import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';

import { IconCheckbox, IconInfoCircle } from '@tabler/icons-react';
import { uploadConfig } from 'app-level/config/uploadConfig';
import { BaseButton, ModalNotStyled } from 'common-ui';
import { useDialog } from 'common-ui/Dialog';
import { PillFilters } from 'features/common-elements';
import { LoanDatatableLoan } from 'features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import { useFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { TableQueryParameters } from 'hooks/useTableQueryParams';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import { FileUploadProgressBar } from 'ui-kit';
import DealButton from 'ui-kit/DealButton/DealButton';

import { useMutation, useQuery } from '@apollo/client';

import {
  AssetClass,
  DealRole,
  ParentType,
  FileType,
} from '__generated__/globalTypes';

import { MARK_COLLATERAL_REVIEW_COMPLETE } from 'mutation/diligenceMutations';

import {
  GetDealDiligence,
  GetDealDiligence_deal_diligence,
} from 'query/__generated__/GetDealDiligence';
import { GET_DILIGENCE_AI } from 'query/diligence';

import { Row, SmallRowLabel, SpaceBetween, TabContent } from './commonStyles';
import DiligenceLoanDocumentDrawer, {
  DocumentsProps,
} from './DiligenceLoanDocumentDrawer';
import DiligencePopulationMetrics, {
  DiligencePopulationMetricsProps,
} from './DiligencePopulationMetrics';
import { DiligenceStatus } from './DiligenceStatus';
import { FileDiligenceStatus } from './FileStatus';
import { LoanDiligenceStatus } from './LoanDiligenceStatus';
import { useLoansSelectedForDiligence } from './queries/useLoansSelectedForDiligence';
import { QuickStatusDialog } from './QuickStatusDialog';
import ReconciliationReport from './ReconciliationReport';
import { ReviewDiligenceTable } from './ReviewDiligenceTable';

// Reprt will be added in RC2
// const formatDisplayDate = (date: Date) => {
//   const now = DateTime.now();
//   const dateTime = DateTime.fromJSDate(date);

//   if (dateTime.hasSame(now, 'day')) {
//     return `Today @ ${dateTime.toFormat('h:mm a')} PT`;
//   } else if (dateTime.hasSame(now.minus({ days: 1 }), 'day')) {
//     return `Yesterday @ ${dateTime.toFormat('h:mm a')} PT`;
//   } else {
//     return `${dateTime.toFormat('M.d.yyyy')} @ ${dateTime.toFormat('h:mm a')} PT`;
//   }
// };

const aiPoolInterval = 10000;

export interface ReviewDiligenceProps {
  dealId: string;
  dealName: string;
  assetClass: AssetClass;
  role: DealRole;
  reviewDiligenceMetrics: DiligencePopulationMetricsProps;
  should_show_summaries_tab: boolean;
  queryParams: TableQueryParameters<any, any>;

  updateQueryParams: (params: Partial<TableQueryParameters<any, any>>) => void;
  totalDiligenceItems?: number;
  diligenceData: GetDealDiligence_deal_diligence[];
  diligenceDeal: GetDealDiligence;
  handleLoanDiligenceStatusChanged: (
    loanId: string,
    status: LoanDiligenceStatus,
  ) => void;
  documents: DocumentsProps; // Collateral documents
  userId: string;
}

export const ReviewDiligenceTab: React.FC<ReviewDiligenceProps> = ({
  dealId,
  dealName,
  reviewDiligenceMetrics,
  role,
  queryParams,
  updateQueryParams,
  totalDiligenceItems,
  assetClass,
  diligenceData,
  diligenceDeal,
  handleLoanDiligenceStatusChanged,
  documents,
  userId,
  should_show_summaries_tab,
}) => {
  const { setDocumentSelectedForView } = useDealRoomContext();
  const { deal, setActionTargetLoanId, selectedDiligenceForDialog } =
    useReviewDiligenceTab(diligenceDeal);

  const portfolioFiltersConfig = useFilterConfig(assetClass, {
    parentType: ParentType.DEAL,
    parentId: dealId,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [drawerLoanId, setDrawerLoanId] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [uploadFileName, setUploadFileName] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [fileUploadProgress, setFileUploadProgress] = useState<number>(0);

  const { data: aiDiligenceData } = useQuery(GET_DILIGENCE_AI, {
    variables: { input: { dealId } },
    skip: role !== DealRole.SELLER,
    pollInterval: aiPoolInterval,
  });

  const [markCollateralReviewComplete] = useMutation(
    MARK_COLLATERAL_REVIEW_COMPLETE,
    {
      onCompleted: () => {
        toast.success('Collateral review marked as complete!');
      },
      onError: (error) => {
        toast.error(
          `Error marking collateral review complete: ${error.message}`,
        );
      },
    },
  );

  useEffect(() => {
    if (aiDiligenceData?.getDiligenceAI?.processingStatus) {
      const { activeZipFileName, customerPercentage } =
        aiDiligenceData.getDiligenceAI.processingStatus;

      if (activeZipFileName) {
        setFileName(activeZipFileName);
      }

      setProgress(customerPercentage || 0);
    } else {
      setFileName('');
      setProgress(0);
    }
  }, [aiDiligenceData]);

  const quickStatusDialog = useDialog();

  const handleQuickStatusChange = (
    dealId: string,
    loanId: string,
    fileName: string,
    status: FileDiligenceStatus,
  ) => {
    documents.onFileStatusSelect(dealId, loanId, fileName, status);
  };

  const handleQuickStatusClick = (loanId: string) => {
    setActionTargetLoanId(loanId);
    quickStatusDialog.openDialog();
  };

  const handleViewClick = (loanId: string) => {
    setDrawerLoanId(loanId);
    setIsDrawerOpen(true);
  };

  const handleLoanSelectedInDrawer = (loanId: string) => {
    setDrawerLoanId(loanId);
  };

  const handleCloseDrawer = () => {
    setDocumentSelectedForView(false);
    setIsDrawerOpen(false);
  };

  const handleFileSuccess = (file?: File) => {
    toast.success(
      <div className="flex flex-col">
        <p>Upload was successful</p>
        <p>Your .zip '{file?.name}' was successfully uploaded.</p>
      </div>,
      {
        duration: 4000,
        position: 'bottom-right',
      },
    );
  };

  const onFileUploadProgress = (progress: number, file?: File) => {
    setFileUploadProgress(progress);
    setUploadFileName(file?.name || '');
  };

  const sections = [
    {
      title: 'Document Completeness',
      data: [
        { label: 'Loan App', percentage: '99.998%', value: '23,220/23,223' },
        { label: 'Third Party Appraisal', percentage: '0%', value: '0/23,223' },
        {
          label: 'Document Name',
          percentage: '95.998%',
          value: '23,220/23,223',
        },
      ],
    },
    {
      title: 'Key Fields',
      data: [
        { label: 'FICO', percentage: '99.998%', value: '23,220/23,223' },
        {
          label: 'DTI at Origination',
          percentage: '99.998%',
          value: '23,220/23,223',
        },
      ],
    },
    {
      title: '',
      data: [
        {
          label: 'Interest Rate',
          percentage: '95.998%',
          value: '23,220/23,223',
        },
        {
          label: 'LTV at Origination',
          percentage: '89.99%',
          value: '23,220/23,223',
        },
      ],
    },
  ];

  const renderZipUploadModal = (trigger: React.ReactElement) => {
    return (
      <ModalNotStyled trigger={trigger}>
        {({ closeModal }) => (
          <div className="w-full max-w-[647px] rounded-lg border border-pink-500 bg-background-canvas p-6 font-heebo text-white shadow-lg">
            <div className="flex items-center justify-end">
              <button
                className="text-gray-500 hover:text-gray-300"
                onClick={closeModal}
              >
                ✕
              </button>
            </div>
            <h2 className="mt-2 flex justify-center text-xl font-semibold">
              Collateral Batch Upload
            </h2>
            <p className="mb-8 mt-8 flex justify-center text-center">
              Each collateral document will be processed and mapped to each
              respective Loan ID.
            </p>

            <FileUpload
              parentId={dealId}
              fileType={FileType.OTHER}
              allowedFileTypes={uploadConfig.diligenceZip.allowedFileTypes}
              onFileSuccess={(file) => handleFileSuccess(file)}
              width="auto"
              height="auto"
              showButtons={false}
              timeToHideProgress={2}
              isCollateral={true}
              vertical={true}
              allowedTypesMessage={uploadConfig.diligenceZip.message}
              onProgressCallback={onFileUploadProgress}
              showProgress={false}
              uploadedBy={userId}
              disabled={!!fileName}
            />

            {(uploadFileName || fileName) && (
              <div className="mt-4 flex flex-col items-center border-t border-slate-500 p-4">
                {fileUploadProgress < 100 && !fileName ? (
                  <FileUploadProgressBar
                    fileName={uploadFileName}
                    progress={fileUploadProgress}
                    width="100%"
                  />
                ) : (
                  <div className="mt-2 text-green-500">
                    File uploaded successfully!
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </ModalNotStyled>
    );
  };

  const ProgressMessage = memo(
    ({
      isDiligenceInProgress,
      error,
    }: {
      isDiligenceInProgress: boolean;
      error?: string | null;
    }) => {
      if (error) {
        return (
          <div className="flex items-center gap-2 text-red-400">
            <IconInfoCircle
              stroke={2}
              size={24}
              className="text-red-400"
              data-tooltip-id="error-tooltip"
            />
            <span>There was an error with your data.</span>
            <Tooltip id="error-tooltip" className="max-w-[300px]" place="left">
              <p>
                There was an error with your data. Our Engineering team was
                notified and is actively fixing the issue now.
              </p>
            </Tooltip>
          </div>
        );
      }

      const iconColorClass = isDiligenceInProgress
        ? 'text-blue-400'
        : 'text-yellow-400';
      const tooltipId = isDiligenceInProgress
        ? 'ai-diligence'
        : 'processing-zip';
      const tooltipText = isDiligenceInProgress
        ? 'The AI diligence checks are currently being performed on your uploaded documents.'
        : 'Documents are being processed and matched to loans. This may take a few minutes.';

      return (
        <div className="flex items-center">
          <IconInfoCircle
            stroke={2}
            size={24}
            className={iconColorClass}
            data-tooltip-id={tooltipId}
          />
          <Tooltip id={tooltipId} className="max-w-[300px]" place="left">
            <p>{tooltipText}</p>
          </Tooltip>
        </div>
      );
    },
  );

  // TODO: need to add fetch data for it
  const showReconciliationReport = false;

  return (
    <div className="relative">
      {should_show_summaries_tab && (
        <div
          className="absolute inset-0 z-10 bg-black bg-opacity-20"
          onClick={(e) => e.stopPropagation()}
        />
      )}
      <div className="mb-8">
        {showReconciliationReport && (
          <ReconciliationReport sections={sections} />
        )}
      </div>
      <TabContent>
        <Row>
          <SmallRowLabel>Diligence Population</SmallRowLabel>
          <DiligencePopulationMetrics metrics={reviewDiligenceMetrics} />
        </Row>
        <Row>
          <SmallRowLabel>Collateral File Status</SmallRowLabel>
          <DiligenceStatus diligenceData={diligenceData} />
        </Row>
        <Row>
          <SpaceBetween>
            <div>
              {portfolioFiltersConfig && (
                <PillFilters
                  filtersConfig={portfolioFiltersConfig}
                  currentFilters={queryParams.filters}
                  setCurrentFilters={(filters) =>
                    updateQueryParams({ ...queryParams, filters })
                  }
                />
              )}
            </div>
          </SpaceBetween>
        </Row>
        {role === DealRole.BUYER && (
          <Row className="flex justify-end">
            <ModalNotStyled
              trigger={
                <DealButton
                  label="Mark Collateral Review Complete"
                  Icon={<IconCheckbox />}
                  iconPosition="right"
                  disabled={should_show_summaries_tab}
                  variant="success_secondary"
                >
                  Mark Collateral Review Complete
                </DealButton>
              }
            >
              {({ closeModal }) => (
                <div className="w-full max-w-[500px] rounded-lg border border-pink-500 bg-background-canvas p-6 font-heebo text-white shadow-lg">
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold">
                      Mark Collateral Review Complete
                    </h2>
                    <button
                      className="text-gray-500 hover:text-gray-300"
                      onClick={closeModal}
                    >
                      ✕
                    </button>
                  </div>
                  <p className="my-4">
                    Are you sure you are finished reviewing the collateral and
                    ready to see the final sum?
                  </p>
                  <div className="flex justify-end gap-2">
                    <BaseButton onClick={closeModal} label="Cancel">
                      Cancel
                    </BaseButton>
                    <BaseButton
                      onClick={() => {
                        markCollateralReviewComplete({
                          variables: {
                            input: {
                              deal_id: dealId,
                            },
                          },
                        });
                        closeModal();
                      }}
                      label="Confirm"
                      className="bg-purple-600 text-white"
                    >
                      Confirm
                    </BaseButton>
                  </div>
                </div>
              )}
            </ModalNotStyled>
          </Row>
        )}
        {role === DealRole.SELLER && (
          <Row className="justify-end">
            <div className="flex items-center gap-4">
              {fileName && (
                <>
                  <ProgressMessage
                    isDiligenceInProgress={
                      !!aiDiligenceData?.getDiligenceAI?.processingStatus
                        ?.diligenceInProgress
                    }
                    error={
                      aiDiligenceData?.getDiligenceAI?.processingStatus?.error
                    }
                  />
                  {!aiDiligenceData?.getDiligenceAI?.processingStatus
                    ?.error && (
                    <FileUploadProgressBar
                      fileName={fileName}
                      progress={progress * 100}
                    />
                  )}
                </>
              )}
              {!fileName &&
                renderZipUploadModal(
                  <BaseButton
                    type="secondary"
                    size="compact"
                    label="Batch Upload"
                    className="px-[6px] py-[2px]"
                  >
                    Batch Upload: Collateral Files (.zip)
                  </BaseButton>,
                )}
            </div>
          </Row>
        )}

        {/* Reprt will be added in RC2 */}
        {/* <Row>
          <GenericDropdown
            position="left"
            label="View Reports"
          >
            <div className="p-3 bg-background-canvas rounded-lg border border-pink-500 shadow-lg w-[384px]">
              <p className="flex justify-start font-heebo">Generated reports for Deal-ID</p>
              <span className="text-xs text-slate-400 mt-3 mb-[10px]">Total: 3 Reports</span>
              <div>
                <div className="flex justify-between items-center mb-3 text-xs">
                  <span className="text-gray-50">Deal-1</span>
                  <span className="text-gray-600">{formatDisplayDate(new Date('02.03.2023'))}</span>
                  <LinkButton text="Download (.CSV)" url="#"/>
                </div>
                <div className="flex justify-between items-center mb-3 text-xs">
                  <span className="text-gray-50">Deal-1</span>
                  <span className="text-gray-600">{formatDisplayDate(new Date(Date.now() - 86400000))}</span>
                  <LinkButton text="Download (.CSV)" url="#"/>
                </div>
                <div className="flex justify-between items-center mb-3 text-xs">
                  <span className="text-gray-50">Deal-1</span>
                  <span className="text-gray-600">{formatDisplayDate(new Date())}</span>
                  <LinkButton text="Download (.CSV)" url="#"/>
                </div>
              </div>
            </div>
          </GenericDropdown>
        </Row> */}

        <ReviewDiligenceTable
          role={role}
          onLoanDiligenceStatusChanged={handleLoanDiligenceStatusChanged}
          deal={deal}
          assetClass={assetClass}
          sorting={{
            state: queryParams.sortings,
            onSortingChanged: (newSortings) => {
              updateQueryParams({ sortings: newSortings });
            },
          }}
          onViewClick={handleViewClick}
          onQuickStatusClick={handleQuickStatusClick}
          pagination={{
            updateParams: updateQueryParams,
            queryParams,
            totalItems: deal?.diligence_performance_summary.loanCount,
          }}
        />
      </TabContent>

      <QuickStatusDialog
        loanName={selectedDiligenceForDialog?.loan.account_id || 'Unknown Loan'}
        collateral={selectedDiligenceForDialog?.collateral || []}
        dialog={quickStatusDialog}
        onStatusChange={handleQuickStatusChange}
      />
      {isDrawerOpen && (
        <>
          <div
            className="fixed inset-0 z-10 bg-black bg-opacity-50"
            onClick={handleCloseDrawer}
          />
          <div
            className={`fixed right-0 top-0 z-20 h-full w-[1070px] transform bg-background-surface shadow-lg transition-transform duration-300 ease-in-out ${
              isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <DiligenceLoanDocumentDrawer
              role={role}
              dealName={dealName}
              diligenceSelector={{
                diligence: diligenceData,
                selectedLoanId: drawerLoanId,
                onSelectLoan: handleLoanSelectedInDrawer,
              }}
              documents={documents}
              userId={userId}
              dealId={dealId}
            />
          </div>
        </>
      )}
    </div>
  );
};

const useReviewDiligenceTab = (data: GetDealDiligence) => {
  const [actionTargetLoanId, setActionTargetLoanId] = useState<string | null>(
    null,
  );

  const diligenceLoans = useMemo(
    () =>
      data?.deal?.diligence.map(
        (diligence) => diligence.loan as LoanDatatableLoan,
      ) || [],
    [data?.deal?.diligence],
  );

  const selectedDiligenceForDialog = useMemo(
    () =>
      data?.deal?.diligence.find(
        (diligence) => diligence.loan.id === actionTargetLoanId,
      ),
    [data?.deal?.diligence, actionTargetLoanId],
  );

  const setActionTargetLoanIdCallback = useCallback((id: string | null) => {
    setActionTargetLoanId(id);
  }, []);

  return useMemo(
    () => ({
      deal: data?.deal,
      diligenceLoans,
      selectedDiligenceForDialog,
      actionTargetLoanId,
      setActionTargetLoanId: setActionTargetLoanIdCallback,
    }),
    [
      data?.deal,
      diligenceLoans,
      selectedDiligenceForDialog,
      actionTargetLoanId,
      setActionTargetLoanIdCallback,
    ],
  );
};
