import { CashFlowModelingInputs } from 'features/drilldown/cashflows/CashFlowModelingInputs';
import { CashFlowsValidValues } from 'features/drilldown/cashflows/configurations/cashflow-inputs';
import Chart from 'features/drilldown/cashflows/modelingChart/Chart';
import MonthlyTable from 'features/drilldown/cashflows/MonthlyTable';
import SummaryTable from 'features/drilldown/cashflows/SummaryTable';
import toast from 'react-hot-toast';
import { DownloadDocumentButton, Spinner } from 'ui-kit';
import { downloadExcelFromJson } from 'utils/excelUtils';

import { GetShortCarves_deal_carves_carve_summary } from 'query/__generated__/GetShortCarves';

interface CashFlowSummaryDisplayProps {
  onSubmit: (values: CashFlowsValidValues) => void;
  formValues: CashFlowsValidValues;
  selectedCarveSummary: GetShortCarves_deal_carves_carve_summary | null;
  loading: boolean;
  withServicingRate?: boolean;
}

const CashFlowSummaryDisplay: React.FC<CashFlowSummaryDisplayProps> = ({
  onSubmit,
  formValues,
  selectedCarveSummary,
  loading,
  withServicingRate,
}) => {
  const cashFlowData = {
    cashFlows: { ...selectedCarveSummary?.cashFlows },
    current_balance_cents: selectedCarveSummary?.unpaidPrincipalBalance || 0,
  };

  const handleDownloadExcel = () => {
    if (
      !cashFlowData ||
      !Array.isArray(cashFlowData.cashFlows.monthlyCashFlow)
    ) {
      toast.error('No data available for download.');
      return;
    }

    downloadExcelFromJson(
      cashFlowData.cashFlows.monthlyCashFlow,
      'Monthly Cashflows',
      'CashFlowSummary.xlsx',
    );
  };

  const monthlyCashFlow = cashFlowData?.cashFlows?.monthlyCashFlow || null;
  return (
    <>
      <div className="flex justify-between pt-4">
        <CashFlowModelingInputs
          formValues={formValues}
          onSubmit={onSubmit}
          withServicingRate={withServicingRate}
        />
        <div>
          <DownloadDocumentButton
            onClick={handleDownloadExcel}
            fileType="excel"
            tooltipText="Download Excel"
            tooltipPosition="left"
            className="mr-4"
          />
        </div>
      </div>
      <div className="mt-4">
        <Spinner loading={loading} />
        <div className="my-5 font-heebo text-lg font-normal leading-6 text-gray-300">
          Cashflow Projections
        </div>
        <div className="mt-5 h-[318px] w-full font-heebo text-lg font-normal leading-6">
          {monthlyCashFlow && <Chart cashFlowMonths={monthlyCashFlow} />}
        </div>
        <div className="mt-5 flex flex-nowrap gap-x-3">
          <div>
            <div className="my-5 font-heebo text-lg font-normal leading-6 text-gray-300">
              Cashflow Matrix
            </div>
            <SummaryTable
              cashFlowTotals={cashFlowData?.cashFlows?.totals || null}
              performanceSummary={cashFlowData}
            />
          </div>
          {monthlyCashFlow && (
            <MonthlyTable monthlyCashFlows={monthlyCashFlow} />
          )}
        </div>
      </div>
    </>
  );
};

export default CashFlowSummaryDisplay;
