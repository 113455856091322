import React, { useCallback } from 'react';

import { Tooltip } from 'react-tooltip';

interface IconButtonProps {
  icon: React.ReactNode;
  onClick?: () => void;
  tooltipText?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  ariaLabel?: string;
  variant?: 'default' | 'icon-only' | 'table-info';
  disabled?: boolean;

  [key: string]: unknown;
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  tooltipText,
  tooltipPosition = 'top',
  className,
  ariaLabel = 'icon button',
  variant = 'default',
  disabled,
  ...rest
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) return;

      e.preventDefault();
      onClick?.();
    },
    [disabled, onClick],
  );

  const disabledClassName = disabled
    ? 'cursor-not-allowed text-slate-400 opacity-30'
    : '';

  const commonClass =
    'flex cursor-pointer items-center justify-center transition-all duration-300';

  const variantClasses = {
    default:
      'rounded-md border border-transparent-0 p-2  bg-transparent  hover:border-pink-500 hover:bg-gray-900',
    ['icon-only']: ' bg-transparent  hover:border-pink-500 hover:bg-gray-900',
    ['table-info']: 'h-6 w-6 bg-slate-700 rounded-[6px]',
  };

  const buttonClass = `${className} ${disabledClassName} ${variantClasses[variant]} ${commonClass}`;

  return (
    <button
      type="button"
      aria-label={ariaLabel}
      data-tooltip-id={tooltipText ? tooltipText : undefined}
      className={buttonClass}
      onClick={handleClick}
      {...rest}
    >
      {icon}
      {tooltipText && (
        <Tooltip
          id={tooltipText}
          className="max-w-[300px]"
          place={tooltipPosition}
        >
          {tooltipText}
        </Tooltip>
      )}
    </button>
  );
};
