import React from 'react';

type DisplayTableRowProps = {
  children?: React.ReactNode;
  className?: string;
  onClick: () => void;
};

export const DisplayTableRow = ({
  children,
  className,
  onClick,
}: DisplayTableRowProps) => {
  const classNames = `flex ${className}`;
  return (
    <div className={classNames} onClick={onClick}>
      {children}
    </div>
  );
};
