import { useState } from 'react';

import { RowSelectionState } from '@tanstack/react-table';
import { SelectionStatus } from 'common-ui/SelectionStatusLine';
import { createQueryFilters } from 'context/DataContext';
import { parseRowId } from 'features/drilldown/LoanDatatable/LoanTable';

import { useMutation } from '@apollo/client';

import {
  AssetClass,
  Filter,
  FilterableField,
  FilterOperator,
  OriginationType,
} from '__generated__/globalTypes';

import {
  CreateListing,
  CreateListingVariables,
} from 'mutation/__generated__/CreateListing';
import { CREATE_LISTING } from 'mutation/createListing';

import { FormValues } from '../CreatePoolForm';
import { unlistedFilters } from './usePortfolioLoans';

export interface LastCreatedPool {
  serverId: string;
  generatedName: string;
  friendlyName: string;
}

export const useCreatePool = () => {
  const [createListing] = useMutation<CreateListing, CreateListingVariables>(
    CREATE_LISTING,
  );
  const [lastCreatedPool, setLastCreatedPool] =
    useState<LastCreatedPool | null>();

  const createPool = async (
    formValues: FormValues,
    gqlAssetClass: AssetClass,
    queryFilters: Filter[],
    selectedLoans: RowSelectionState,
    selectionStatus: SelectionStatus,
  ) => {
    if (formValues.poolName === 'error') {
      throw new Error('Simulated pool creation error');
    }

    let filters: Filter[];

    if (selectionStatus === SelectionStatus.AllSelected) {
      // If all loans are selected, use the current query filters with unlistedFilters to avoid listed loans to be added
      filters = [
        ...unlistedFilters,
        ...createQueryFilters(gqlAssetClass, queryFilters),
      ];
    } else {
      // If not all loans are selected, create the pool with the selected loans
      const accountIdList = Object.keys(selectedLoans).map(
        (key) => parseRowId(key).accountId,
      );

      const filter: Filter = {
        field_name: FilterableField.account_id,
        operator: FilterOperator.IS,
        operandList: accountIdList,
      };

      filters = [filter];
    }

    return createListing({
      variables: {
        name: formValues.poolName,
        assetClass: gqlAssetClass,
        filters: filters,
        originationType: mapFormValueToBackendOriginationType(
          formValues.originationType,
        ),
      },
    }).then((res) => {
      const poolGeneratedName = res.data?.createListing?.name;
      const poolServerId = res.data?.createListing?.id;
      setLastCreatedPool({
        serverId: poolServerId || '',
        generatedName: poolGeneratedName || '',
        friendlyName: formValues.poolName,
      });
    });
  };

  return { createPool, lastCreatedPool };
};

const mapFormValueToBackendOriginationType = (
  formValue: string,
): OriginationType => {
  switch (formValue) {
    case 'direct':
      return OriginationType.DIRECT;
    case 'indirect':
      return OriginationType.INDIRECT;
    case 'indirect_fintech':
      return OriginationType.INDIRECT_FINTECH;
    case 'mixed':
      return OriginationType.MIXED;
    case 'n_a':
      return OriginationType.N_A;
  }
  return OriginationType.N_A;
};
