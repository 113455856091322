import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { flexRender } from '@tanstack/react-table';
import { Cell as CellDef } from '@tanstack/react-table';
import { alignments, getCommonPinningStyles, WithSubData } from 'ui-kit';

import { Alignment, isColumnMeta } from './utils';

interface RenderCellProps<T> {
  cell: CellDef<WithSubData<T>, unknown>;
  columnOrder: string[];
  width: number;
  isColumnResize: string;
  bgColor: string;
}

const ZIndex_VALUE = 0;
const RenderCellComponent = <T,>({
  cell,
  columnOrder,
  width,
  isColumnResize,
  bgColor,
}: RenderCellProps<T>) => {
  const { column } = cell;
  const columnDefMeta = column.columnDef.meta;

  const { isDragging, setNodeRef, transform, transition } = useSortable({
    id: column.id,
  });
  const { alignment, numeric } = (() => {
    if (isColumnMeta(columnDefMeta)) {
      return {
        alignment: columnDefMeta.alignment || Alignment.left,
        numeric: columnDefMeta.numeric || false,
      };
    }
    return { alignment: Alignment.left, numeric: false };
  })();
  const tdClasses = `py-[18px] pl-2 pr-[10px]  ${isColumnResize === column.id ? 'border-r-2 border-r-red-400' : ''} ${isDragging ? 'bg-gray-600' : bgColor}`;
  return (
    <td
      className={tdClasses}
      style={{
        ...(transform && {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          transition,
          zIndex: 100,
        }),
        ...getCommonPinningStyles(column, ZIndex_VALUE),
      }}
    >
      <div
        ref={setNodeRef}
        style={{
          minWidth: width || cell.column.getSize(),
          width: 'fit-content',
        }}
        data-index={columnOrder.indexOf(column.id)}
        className={`just flex items-center whitespace-nowrap font-pt-mono text-[14px] leading-tight text-white justify-${alignments[alignment]}`}
      >
        {flexRender(column.columnDef.cell, cell.getContext())}
      </div>
    </td>
  );
};

export const RenderCell = React.memo(
  RenderCellComponent,
) as typeof RenderCellComponent;
