import { useEffect } from 'react';

import EventActionCards from 'features/deals/DealStages/EventActionCards';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';

import { DealStatus } from '__generated__/globalTypes';

import { DealStagesDeal } from './__generated__/DealStagesDeal';
import { GetDealGeneralInfo_deal_listing } from '../dealCards/gql/__generated__/GetDealGeneralInfo';

const POLLING_INTERVAL = 5000;

type DealStagesProps = {
  deal: DealStagesDeal & {
    role: string;
    listing: GetDealGeneralInfo_deal_listing;
  };
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
};

const DealStages = ({ deal, startPolling, stopPolling }: DealStagesProps) => {
  const { dealId, isDocumentSelectedForView } = useDealRoomContext();

  useEffect(() => {
    if (deal?.state.status === DealStatus.COMPLETE) {
      stopPolling();
      return;
    }

    if (isDocumentSelectedForView) {
      stopPolling();
    } else {
      startPolling(POLLING_INTERVAL);
    }

    return () => {
      stopPolling();
    };
  }, [
    deal?.state.status,
    isDocumentSelectedForView,
    startPolling,
    stopPolling,
  ]);

  const { cards } = deal;
  if (cards.length === 0) {
    return <></>;
  }

  return (
    <div className="flex overflow-hidden">
      <EventActionCards
        dealId={dealId}
        cards={cards}
        role={deal.role}
        seller={deal.listing.seller}
        dealState={deal.state}
      />
    </div>
  );
};

export default DealStages;

export function getCardId(card: { title: string }, index: number): string {
  return `${card.title.replace(/ /g, '_')}_${index}`;
}
