import { Ref, useCallback, useRef } from 'react';

import { PillButtonProps } from 'common-ui/Buttons';
import { ComboDropdownValuePill } from 'common-ui/Combobox/ComboDropdownValuePill';
import Select, { Props, SelectInstance, InputProps } from 'react-select';
import { useTheme } from 'styled-components';

export type PillDropdownProps<T> = Pick<
  Props<T, false>,
  'getOptionLabel' | 'getOptionValue' | 'options' | 'value' | 'onChange'
> & {
  iconOnClick?: PillButtonProps['iconOnClick'];
  iconName?: PillButtonProps['iconName'];
  description: PillButtonProps['description'];
  className?: string;
  controlStyles?: React.CSSProperties;
};

export function PillDropdown<T>(props: PillDropdownProps<T>) {
  const ref = useRef<SelectInstance<T, false>>(null);
  const theme = useTheme();
  const { className, ...rest } = props;
  const ControlComp = useCallback(
    (controlProps: InputProps<T, false>) => {
      const label = ref.current?.getOptionLabel(
        controlProps.getValue()[0] || ({} as T),
      );

      // Manually map event handlers to match HTMLButtonElement types
      const inputProps = {
        onClick:
          controlProps.onClick as React.MouseEventHandler<HTMLButtonElement>,
        onMouseDown:
          controlProps.onMouseDown as React.MouseEventHandler<HTMLButtonElement>,
        onKeyDown:
          controlProps.onKeyDown as React.KeyboardEventHandler<HTMLButtonElement>,
        onFocus:
          controlProps.onFocus as React.FocusEventHandler<HTMLButtonElement>,
        onBlur:
          controlProps.onBlur as React.FocusEventHandler<HTMLButtonElement>,
        'aria-label': controlProps['aria-label'],
        'aria-labelledby': controlProps['aria-labelledby'],
        'aria-describedby': controlProps['aria-describedby'],
        'aria-invalid': controlProps['aria-invalid'],
        'aria-errormessage': controlProps['aria-errormessage'],
        tabIndex: controlProps.tabIndex,
        role: controlProps.role,
      };

      return (
        <ComboDropdownValuePill
          className={className}
          ref={controlProps.innerRef as Ref<HTMLButtonElement>}
          {...inputProps}
          description={props.description}
          value={label}
          iconName={props.iconName}
          iconOnClick={props.iconOnClick}
          isActive={controlProps.selectProps.menuIsOpen}
        />
      );
    },
    [props.description, props.iconName, props.iconOnClick],
  );

  return (
    <Select
      ref={ref}
      {...rest}
      components={{
        Input: ControlComp,
        SingleValue: () => null,
        Placeholder: () => null,
        IndicatorsContainer: () => null,
      }}
      styles={{
        menu: (css) => ({
          ...css,
          background: 'white',
          width: 'fit-content',
          borderRadius: '4px',
          backgroundColor: theme.color.black,
          color: theme.color.fgDefault,
          font: theme.typography.primaryXSmall,
          border: `solid 1px ${theme.color.accentEmphasis}`,
          boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)',
          zIndex: 10,
        }),
        option: (css, optionProps) => ({
          ...css,
          padding: '4px 8px',
          backgroundColor:
            optionProps.isSelected || optionProps.isFocused
              ? theme.color.slate300
              : 'inherit',
          ':first-child': {
            borderRadius: '4px 4px 0px 0px',
          },
          ':last-child': {
            borderRadius: '0px 0px 4px 4px',
          },
        }),
        control: (css) => ({
          ...css,
          ...(props?.controlStyles || {}),
        }),
      }}
      closeMenuOnSelect={true}
      unstyled={true}
      openMenuOnClick={true}
    />
  );
}
