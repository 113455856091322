import { gql } from '@apollo/client';

import { CarveDiffTableFragments } from '../CarveDiffTable';

const CarveDetailsCardFragments = {
  TimelineCard: gql`
    fragment CarveDetailsCard on TimelineCard {
      ... on CarveDetailsCard {
        can_edit_carve
        deal_performance_summary {
          ...CarveSummary
        }
        carves {
          id
          name
          carve {
            field_name
            operator
            operand
            operandList
          }
          carve_summary {
            ...CarveSummary
          }
        }
        carve_criteria_section
        upb_criteria_section
      }
    }
    ${CarveDiffTableFragments.PerformanceSummary}
  `,
};

export default CarveDetailsCardFragments;
