import { useEffect } from 'react';

export type KeyCallback = {
  key: string;
  action: () => void;
};

export const useKeyListener = (keyCallbacks: KeyCallback[]) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      keyCallbacks.forEach(({ key, action }) => {
        if (e.key === key) {
          action();
        }
      });
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [keyCallbacks]);
};
