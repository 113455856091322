// useLoans.ts
import { FilterValue, filtersToQueryFilters } from 'features/common-elements';
import {
  PortfolioFilterConfig,
  useFilterConfig,
} from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import useTableQueryParams from 'hooks/useTableQueryParams';

import { useQuery } from '@apollo/client';

import {
  AssetClass,
  SortDirection,
  SortableField,
  ParentType,
} from '__generated__/globalTypes';

import {
  GetDealLoans,
  GetDealLoansVariables,
} from '../../gql/__generated__/GetDealLoans';
import { GET_DEAL_LOANS_QUERY } from '../../gql/inquiryQueries';
import {
  DiligenceCard_DiligenceCard_bid,
  DiligenceCard_DiligenceCard_bid_carve,
} from '../__generated__/DiligenceCard';

export const useLoans = (
  dealId: string,
  assetClass: AssetClass,
  bid: DiligenceCard_DiligenceCard_bid,
) => {
  const { params: queryParams, updateParams } = useTableQueryParams<
    FilterValue<PortfolioFilterConfig>,
    SortableField
  >();

  const portfolioFiltersConfig = useFilterConfig(assetClass, {
    parentType: ParentType.DEAL,
    parentId: dealId,
  });
  const queryFilters = portfolioFiltersConfig
    ? filtersToQueryFilters<PortfolioFilterConfig>(
        queryParams.filters,
        portfolioFiltersConfig,
      )
    : [];

  const carveFilters = !!bid?.carve
    ? bid?.carve.map((item: DiligenceCard_DiligenceCard_bid_carve) => ({
        field_name: item.field_name,
        operator: item.operator,
        operand: item.operand,
        operandList: item.operandList,
      }))
    : [];

  const { loading, error, data } = useQuery<
    GetDealLoans,
    GetDealLoansVariables
  >(GET_DEAL_LOANS_QUERY, {
    variables: {
      id: dealId,
      sort: {
        sort_direction: queryParams.sortings[0]?.desc
          ? SortDirection.desc
          : SortDirection.asc,
        sort_field: queryParams.sortings[0]?.id,
      },
      pagination: {
        offset: (queryParams.pageNumber - 1) * queryParams.pageSize,
        page_size: queryParams.pageSize,
      },
      filters: [...queryFilters, ...carveFilters],
    },
    fetchPolicy: 'no-cache',
  });

  const { data: allData } = useQuery<GetDealLoans, GetDealLoansVariables>(
    GET_DEAL_LOANS_QUERY,
    {
      variables: {
        id: dealId,
        sort: {},
        pagination: {
          offset: 0,
          page_size: data?.deal?.performance_summary?.loanCount,
        },
        filters: [...queryFilters, ...carveFilters],
      },
      fetchPolicy: 'no-cache',
    },
  );

  return {
    loading,
    error,
    data,
    allData,
    queryParams,
    updateParams,
    filters: [...queryFilters, ...carveFilters],
  };
};
