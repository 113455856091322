import { useCallback, useEffect, useMemo, useState } from 'react';

import { StyledBody } from 'baseui/card';
import { CashFlowsFormWithDataDisplay } from 'features/drilldown/cashflows/CashFlows';
import { CashFlowsValidValues } from 'features/drilldown/cashflows/configurations/cashflow-inputs';
import { getUseDealCashflows } from 'features/drilldown/cashflows/gql/useDealCashflows_gql';
import { Tab, Tabs } from 'ui-kit';
import { useDebouncedCallback } from 'use-debounce';
import { isNotNullOrUndefined } from 'utils/typeUtils';

import { useMutation, useQuery } from '@apollo/client';

import {
  DefaultAssumptionType,
  PrepaymentAssumptionType,
  StipulationFieldName,
  StipulationToggleName,
} from '__generated__/globalTypes';

import {
  GetCarves,
  GetCarvesVariables,
  GetCarves_deal_carves,
} from 'query/__generated__/GetCarves';
import { GET_CARVES } from 'query/getCarves';

import { BiddingAndPricingControllerCard_PlaceBidCard } from './__generated__/BiddingAndPricingControllerCard';
import {
  ToggleCarveStipulation,
  ToggleCarveStipulationVariables,
} from './__generated__/ToggleCarveStipulation';
import {
  UpdateCarveStipulation,
  UpdateCarveStipulationVariables,
} from './__generated__/UpdateCarveStipulation';
import {
  UpdateCustomStipulations,
  UpdateCustomStipulationsVariables,
} from './__generated__/UpdateCustomStipulations';
import { BidActionButtons } from './BidActionButtons';
import { BidDetails } from './BidDetails';
import BiddingPricingContext from './BiddingPricingContext';
import BidStipulations from './BidStipulations';
import {
  TOGGLE_CARVE_STIPULATION,
  UPDATE_CARVE_STIPULATIONS,
  UPDATE_CUSTOM_STIPULATIONS,
} from './BidStipulations.fragments';
import CarveTable from './CarveTable';
import { DealRoomCard } from '../DealRoom.styles';
import YieldMatrix from './YieldMatrix/YieldMatrix';

const useUpdateStips = (dealId: string) => {
  const refetchQueries = useMemo(() => {
    const getCarvesVariables: GetCarvesVariables = {
      deal_id: dealId,
    };
    return [{ query: GET_CARVES, variables: getCarvesVariables }];
  }, [dealId]);

  const [updateCarveStipulations] = useMutation<
    UpdateCarveStipulation,
    UpdateCarveStipulationVariables
  >(UPDATE_CARVE_STIPULATIONS, {
    refetchQueries,
  });

  const [updateCustomStipulations] = useMutation<
    UpdateCustomStipulations,
    UpdateCustomStipulationsVariables
  >(UPDATE_CUSTOM_STIPULATIONS, {
    refetchQueries,
  });

  const [toggleCarveStipulation] = useMutation<
    ToggleCarveStipulation,
    ToggleCarveStipulationVariables
  >(TOGGLE_CARVE_STIPULATION, {
    refetchQueries,
  });

  return {
    toggleCarveStipulation: useDebouncedCallback(toggleCarveStipulation, 250),
    updateCarveStipulations: useDebouncedCallback(
      updateCarveStipulations,
      1000,
    ),
    updateCustomStipulations: useDebouncedCallback(
      updateCustomStipulations,
      1000,
    ),
  };
};

const useCarvesAndBids = (dealId: string) => {
  const {
    toggleCarveStipulation,
    updateCarveStipulations,
    updateCustomStipulations,
  } = useUpdateStips(dealId);
  const [bidDetailsMap, setBidDetailsMap] = useState<
    Record<string, BidDetails>
  >({});
  const [selectedCarveId, setSelectedCarveId] = useState<string | null>(null);

  const { data: carvesData } = useQuery<GetCarves, GetCarvesVariables>(
    GET_CARVES,
    {
      variables: {
        deal_id: dealId,
      },
      pollInterval: 5000,
    },
  );

  const carves: GetCarves_deal_carves[] = useMemo(() => {
    const carves = (carvesData?.deal?.carves || []).filter(
      isNotNullOrUndefined,
    );

    setBidDetailsMap((prev) => {
      const newBidDetailsMap: Record<string, BidDetails> = carves.reduce(
        (acc, carve) => ({
          ...acc,
          [carve.id]: (prev[carve.id] && {
            ...prev[carve.id],
            stipulations: {
              ...carve.stipulations,
              ...prev[carve.id].stipulations,
            },
          }) || {
            carveId: carve.id,
            carve,
            cpr: 0,
            cdr: 0,
            lsr: 0,
            delay: 0,
            participationPercent: {
              isCustom: false,
              value: '100',
              draftValue: '',
            },
            armRateProjection: 'flat',
            stipulations: {
              ...carve.stipulations,
              custom1: carve.stipulations?.custom_stipulations?.[0] || '',
              custom2: carve.stipulations?.custom_stipulations?.[1] || '',
              custom3: carve.stipulations?.custom_stipulations?.[2] || '',
              document_ids: [],
              servicingRate: {
                isCustom: ![0.25, 0.5].includes(
                  carve.stipulations?.servicing_rate || 0.25,
                ),
                value: carve.stipulations?.servicing_rate || 0.25,
                draftValue: [0.25, 0.5].includes(
                  carve.stipulations?.servicing_rate || 0,
                )
                  ? ''
                  : `${carve.stipulations?.servicing_rate}`,
              },
            },
          },
        }),
        {},
      );
      return newBidDetailsMap;
    });

    return carves;
  }, [carvesData, setBidDetailsMap]);

  const selectedCarve = useMemo(
    () => carves.find((carve) => carve.id === selectedCarveId),
    [carves, selectedCarveId],
  );

  useEffect(() => {
    if (!selectedCarveId && carves.length > 0) {
      setSelectedCarveId(carves[0].id);
    }
  }, [carves, selectedCarveId, setSelectedCarveId]);

  const onBidDetailsChanged = useCallback(
    (
      updatedDetails: BidDetails,
      name?: StipulationFieldName,
      value?: string | number,
    ) => {
      setBidDetailsMap((prev) => ({
        ...prev,
        [updatedDetails.carveId]: updatedDetails,
      }));
      name &&
        value &&
        updateCarveStipulations({
          variables: {
            carveId: updatedDetails.carveId,
            fieldName: name,
            value: value.toString(),
          },
        });
    },
    [updateCarveStipulations],
  );

  const updateCustomStips = useCallback(
    (carveId: string, customStipulations: string[]) => {
      setBidDetailsMap((prev) => ({
        ...prev,
        [carveId]: {
          ...prev[carveId],
          stipulations: {
            ...prev[carveId].stipulations,
            custom1: customStipulations[0] || '',
            custom2: customStipulations[1] || '',
            custom3: customStipulations[2] || '',
          },
        },
      }));
      updateCustomStipulations({
        variables: {
          carveId,
          customStipulations: customStipulations.filter(Boolean),
        },
      });
    },
    [updateCustomStipulations, setBidDetailsMap],
  );

  const setStipFlag = useCallback(
    (fieldName: StipulationToggleName, value: boolean) => {
      if (!selectedCarveId) {
        return;
      }
      setBidDetailsMap((prev) => ({
        ...prev,
        [selectedCarveId]: {
          ...prev[selectedCarveId],
          stipulations: {
            ...prev[selectedCarveId].stipulations,
            [fieldName]: value,
          },
        },
      }));
      toggleCarveStipulation({
        variables: {
          carveId: selectedCarveId,
          fieldName,
          value: value,
        },
      });
    },
    [selectedCarveId, toggleCarveStipulation],
  );

  return {
    carves,
    selectedCarve,
    selectedCarveId,
    setSelectedCarveId,
    bidDetailsMap,
    onBidDetailsChanged,
    updateCustomStips,
    carvesData,
    setStipFlag,
  };
};

type BiddingAndPricingControllerProps = {
  dealId: string;
} & BiddingAndPricingControllerCard_PlaceBidCard;

const BiddingAndPricingController = (
  props: BiddingAndPricingControllerProps,
) => {
  const { dealId, assetClass } = props;

  const {
    selectedCarve,
    selectedCarveId,
    setSelectedCarveId,
    bidDetailsMap,
    onBidDetailsChanged,
    updateCustomStips,
    carvesData,
    setStipFlag,
  } = useCarvesAndBids(dealId);

  const currentBidDetails = selectedCarveId
    ? bidDetailsMap[selectedCarveId]
    : null;

  const useCashflowsSummary = getUseDealCashflows(
    assetClass,
    dealId,
    carvesData?.deal?.carves?.find((c) => c?.id === selectedCarveId)?.carve ||
      [],
  );

  const onCashflowsSubmission = (validatedFormValues: CashFlowsValidValues) => {
    if (currentBidDetails) {
      const servicingValue = `${validatedFormValues.servicingRate}`;
      const servicingFloat = parseFloat(servicingValue);
      onBidDetailsChanged(
        {
          ...currentBidDetails,
          ...validatedFormValues,
          stipulations: {
            ...currentBidDetails.stipulations,
            servicingRate: {
              ...currentBidDetails.stipulations.servicingRate,
              isCustom: ![0.25, 0.5].includes(servicingFloat),
              value: servicingValue,
              draftValue: servicingValue,
            },
            servicing_rate: servicingFloat,
          },
          delay: validatedFormValues.daysDelay,
        },
        StipulationFieldName.servicing_rate,
        servicingValue,
      );
    }
  };

  const selectedCarveBidDetails = selectedCarve
    ? bidDetailsMap[selectedCarve.id]
    : undefined;

  const formValues = {
    cdr: currentBidDetails?.cdr || 0,
    cpr: currentBidDetails?.cpr || 0,
    daysDelay: currentBidDetails?.delay || 0,
    lsr: currentBidDetails?.lsr || 0,
    servicingRate: currentBidDetails
      ? parseFloat(currentBidDetails.stipulations.servicingRate.value)
      : 0,
    rateType: currentBidDetails?.rateType,
    defaultAssumptionType:
      currentBidDetails?.defaultAssumptionType || DefaultAssumptionType.CDR,
    prepaymentAssumptionType:
      currentBidDetails?.prepaymentAssumptionType ||
      PrepaymentAssumptionType.CPR,
  };

  const cashFlowsDataDisplayProps = {
    onSubmit: onCashflowsSubmission,
    formValues,
    useSummaryData: useCashflowsSummary,
  };

  const bidDetailsList = Object.values(bidDetailsMap);

  const render = (
    <StyledBody>
      <DealRoomCard>
        <p>
          Select a carve, below, to price and save your bid. You may send one or
          more bids at a time.
        </p>
        <div className="flex justify-between">
          <div className="mb-4 mt-5 text-sm font-normal uppercase leading-tight text-slate-200">
            Bid carve selection
          </div>
          <BidActionButtons />
        </div>
        <CarveTable
          bidDetailsList={bidDetailsList}
          selectedCarveId={selectedCarveId}
          setSelectedCarveId={setSelectedCarveId}
        />
        <div className="mt-6">
          <Tabs>
            <Tab label="Yield Matrix">
              <YieldMatrix />
            </Tab>
            <Tab label="Cashflows">
              <CashFlowsFormWithDataDisplay
                onSubmit={cashFlowsDataDisplayProps.onSubmit}
                formValues={formValues}
                useSummaryData={cashFlowsDataDisplayProps.useSummaryData}
                withServicingRate
              />
            </Tab>
            <Tab label="Bid Stipulations">
              <BidStipulations dealId={dealId} />
            </Tab>
          </Tabs>
        </div>
      </DealRoomCard>
    </StyledBody>
  );

  return (
    <BiddingPricingContext.Provider
      value={{
        dealId,
        updateBidDetails: onBidDetailsChanged,
        updateCustomStips,
        bidDetails: selectedCarveBidDetails,
        cashFlowsDataDisplayProps,
        bidCard: props,
        selectedCarve,
        setStipFlag,
      }}
    >
      {render}
    </BiddingPricingContext.Provider>
  );
};

export default BiddingAndPricingController;
