import React from 'react';

import { Formik } from 'formik';
import {
  Button,
  ErrorMessage,
  FormikDropdownField,
  FormikTextInput,
  StandardForm,
  UiInput,
} from 'ui-kit';
import * as Yup from 'yup';

import { KeyListener } from '../../../hooks/useKeyListener/KeyListener';

export interface FormValues {
  poolName: string;
  originationType: string;
}

const validationSchema = Yup.object().shape({
  poolName: Yup.string().required('Pool name is required'),
  originationType: Yup.string().required('Origination type is required'),
});

export interface CreatePoolFormProps {
  createPool: (formValues: FormValues) => void;
  closeModal: () => void;
}

const originationTypeOptions = [
  {
    label: 'Direct',
    value: 'direct',
  },
  {
    label: 'Indirect',
    value: 'indirect',
  },
  {
    label: 'Indirect: Fintech',
    value: 'indirect_fintech',
  },
  {
    label: 'Mixed',
    value: 'mixed',
  },
  {
    label: 'Non Applicable',
    value: 'n_a',
  },
];

const initialValues = {
  poolName: '',
  originationType: originationTypeOptions[0].value,
};

const CreatePoolForm: React.FC<CreatePoolFormProps> = ({
  createPool,
  closeModal,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      createPool(values);
      closeModal();
    }}
  >
    {({ submitForm }) => (
      <KeyListener
        listenerData={[
          { key: 'Enter', action: submitForm },
          { key: 'Return', action: submitForm },
        ]}
      >
        <StandardForm closeModal={closeModal}>
          <div className="mb-2 py-2 text-[18px] font-bold leading-6 text-white">
            Create A Pool
          </div>
          <div className="py-2 text-[14px] font-normal leading-5 text-slate-200">
            POOL CREATION
          </div>
          <div
            id="subheader"
            className="mb-2 py-2 text-[14px] font-normal leading-5 text-slate-200"
          >
            Name the selected carve and save your Pool.
          </div>

          <div className="mb-4 grid w-full grid-cols-2 gap-4">
            <label htmlFor="poolName" className="flex items-center">
              Create A Pool Name
            </label>
            <FormikTextInput
              name="poolName"
              placeholder="Enter Name..."
              size={'small' as 'small'}
              variant="light"
              containerClass="bg-gray-950"
            />

            <label htmlFor="originationType" className="flex items-center">
              Select Origination Type
            </label>

            <FormikDropdownField
              name="originationType"
              options={originationTypeOptions}
            />
          </div>
          <div className="mt-4 flex w-full justify-end py-4">
            <Button buttonType="submit" type="primary_light">
              Create Pool
            </Button>
          </div>
        </StandardForm>
      </KeyListener>
    )}
  </Formik>
);

export default CreatePoolForm;
