import React, { Dispatch, SetStateAction } from 'react';

import {
  DraggableColumnsTable,
  DraggableColumnsTableProps,
} from './components/DraggableTable';
import { SimpleTable, SimpleTableProps } from './components/SimpleTable';

interface TableProps {
  isDraggableColumns?: boolean;
  headers: React.ReactNode;
  tbody?: React.ReactNode;
  headerClasses?: string;
  disabledColumnsIds?: string[];
  setColumnOrder?: Dispatch<SetStateAction<string[]>>;

  [key: string]: any;
}

export const Table = ({
  isDraggableColumns = false,
  headerClasses = '',
  headers,
  setColumnOrder,
  tbody,
  disabledColumnsIds = [],
  ...rest
}: TableProps) => {
  const TableComponent = isDraggableColumns
    ? DraggableColumnsTable
    : SimpleTable;

  const tableProps = setColumnOrder
    ? { setColumnOrder, disabledColumnsIds }
    : {};

  return (
    <TableComponent
      {...(tableProps as DraggableColumnsTableProps & SimpleTableProps)}
      {...rest}
    >
      <thead className={`${headerClasses} bg-background-canvas`}>
        {headers}
      </thead>
      <tbody>{tbody}</tbody>
    </TableComponent>
  );
};
