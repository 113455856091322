import { isNotNullOrUndefined } from 'utils/typeUtils';

import { useQuery } from '@apollo/client';

import {
  GetCollateralNotes,
  GetCollateralNotesVariables,
  GetCollateralNotes_collateralNotes_files_notes as CollateralNotesType,
} from 'query/__generated__/GetCollateralNotes';
import { GET_COLLATERAL_NOTES } from 'query/collateral';

export const useCollateralNotes = (
  dealID: string,
  loanID: string,
  fileName: string,
) => {
  const { data, loading, refetch } = useQuery<
    GetCollateralNotes,
    GetCollateralNotesVariables
  >(GET_COLLATERAL_NOTES, {
    variables: { dealID, loanID, fileName },
    pollInterval: 10000,
  });

  const notes =
    data?.collateralNotes?.files
      ?.flatMap((file) => file?.notes)
      ?.filter(isNotNullOrUndefined) || [];

  return { notes, loading, refetch };
};

export const useCollateralNotesForDeal = (dealID: string) => {
  const { data, loading, error } = useQuery<
    GetCollateralNotes,
    GetCollateralNotesVariables
  >(GET_COLLATERAL_NOTES, {
    variables: { dealID },
    fetchPolicy: 'cache-and-network',
  });

  const notes =
    data?.collateralNotes?.files
      ?.flatMap(
        (file) =>
          file?.notes?.map((note) => ({
            ...note,
            collateralID: file.file.collateralID,
          })) || [],
      )
      .filter(
        (note): note is CollateralNotesType & { collateralID: string | null } =>
          note !== null,
      ) || [];

  const notesMap = notes.reduce<Record<string, boolean>>((map, note) => {
    if (note.collateralID) {
      map[note.collateralID] = true;
    }
    return map;
  }, {});

  return { notes, notesMap, loading, error };
};
