import React, { useEffect, useRef, useState } from 'react';

import { TIME_LABELS } from 'constants/timeLabels';
import {
  basisPointsToPercent,
  percentToBasisPoints,
} from 'features/deals/DealStages/EventActionCards/utils';
import { ChartSkeletonLoader } from 'features/pages/market-data/components/ChartSkeleton';
import { URLMapDeprecated } from 'features/pages/market-data/shared';
import BidSummary from 'features/pages/marketplace/BiddingAndPricing/BidSummary/BidSummary';
import MaturityData from 'features/pages/marketplace/BiddingAndPricing/YieldMatrix/MaturityData';
import {
  binarySearchMatrix,
  isWithinOrderedMatrix,
} from 'features/pages/marketplace/BiddingAndPricing/YieldMatrix/utils';
import YieldToPriceChart from 'features/pages/marketplace/BiddingAndPricing/YieldToPriceChart';
import { GetCashflowByPool_userCompanyListing_performance_summary_cashFlows } from 'features/pages/portfolio/CashFlowModeling/__generated__/GetCashflowByPool';

import { GetMarketData } from 'query/__generated__/GetMarketData';

import { CashFlowsValidValues } from './configurations/cashflow-inputs';
import { GetDealCashFlows_deal_performanceSummary_cashFlows } from './gql/__generated__/GetDealCashFlows';
import { GetPortfolioCashFlows_user_company_performanceSummary_cashFlows } from './gql/__generated__/GetPortfolioCashFlows';

interface YieldMatrixModelingProps {
  formValues: CashFlowsValidValues;
  onFormChange: (validatedFormValues: CashFlowsValidValues) => void;
  marketData?: GetMarketData;
  unpaidBalance: number;
  cashFlows?:
    | GetCashflowByPool_userCompanyListing_performance_summary_cashFlows
    | GetDealCashFlows_deal_performanceSummary_cashFlows
    | GetPortfolioCashFlows_user_company_performanceSummary_cashFlows
    | undefined;
  netCoupon?: number;
}

const YieldMatrixModeling: React.FC<YieldMatrixModelingProps> = ({
  formValues,
  onFormChange,
  marketData,
  unpaidBalance = 0,
  cashFlows,
  netCoupon = 0,
}) => {
  const [chartWidth, setChartWidth] = useState(800);
  const chartParentRef = useRef<HTMLDivElement>(null);
  const [bidBasisPoints, setBidBasisPoints] = useState(-5000);
  const [ycChartData, setYcChartData] = useState(null);
  const [ycChartDataMap, setYcChartDataMap] = useState<URLMapDeprecated>({});
  const [ycChartDataUrl, setYcChartDataUrl] = useState(
    'sofr_yc_chart_data.json',
  );
  const [spreadDuration, setSpreadDuration] = useState<number | undefined>(
    undefined,
  );
  const [spread, setSpread] = useState<number | undefined>(undefined);
  const [yieldDuration, setYieldDuration] = useState<number | undefined>(
    undefined,
  );
  const [netYield, setNetYield] = useState<number | undefined>(undefined);

  // Fetch market data URLs
  useEffect(() => {
    if (marketData && marketData.marketData) {
      const urlMap: URLMapDeprecated = {};
      Object.entries(marketData.marketData.urlMap).forEach(([key, value]) => {
        urlMap[key] = value;
      });
      setYcChartDataMap(urlMap);
    }
  }, [marketData]);

  // Fetch YC chart data
  useEffect(() => {
    if (ycChartDataUrl && ycChartDataMap[ycChartDataUrl]) {
      fetch(ycChartDataMap[ycChartDataUrl])
        .then((response) => response.json())
        .then((data) => {
          if (ycChartDataUrl === 'sofr_yc_chart_data.json') {
            setYcChartData({
              ...data,
              labels: TIME_LABELS,
            });
          } else {
            setYcChartData(data);
          }
        });
    }
  }, [ycChartDataUrl, ycChartDataMap]);

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartParentRef.current) {
        const parentWidth = chartParentRef.current.offsetWidth;
        setChartWidth(parentWidth - 310);
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);
    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  // Calculate yield from matrix when bidBasisPoints changes
  useEffect(() => {
    if (cashFlows?.price_yield_matrix) {
      const bidPrice = parseFloat(basisPointsToPercent(bidBasisPoints)) / 100;
      const purchasePriceCents = bidPrice * unpaidBalance;

      if (
        isWithinOrderedMatrix(
          purchasePriceCents,
          cashFlows.price_yield_matrix.yield_matrix,
        )
      ) {
        const yieldMatrix = cashFlows.price_yield_matrix.yield_matrix;
        const yieldData = binarySearchMatrix(yieldMatrix, purchasePriceCents);
        setNetYield(yieldData.rate * 100);
        setYieldDuration(yieldData.duration);
      }

      if (
        isWithinOrderedMatrix(
          purchasePriceCents,
          cashFlows.price_yield_matrix.spread_matrix,
        )
      ) {
        const spreadMatrix = cashFlows.price_yield_matrix.spread_matrix;
        const spreadData = binarySearchMatrix(spreadMatrix, purchasePriceCents);
        setSpread(spreadData.rate * 10_000);
        setSpreadDuration(spreadData.duration);
      }
    }
  }, [bidBasisPoints, cashFlows, unpaidBalance]);

  const bidPx = basisPointsToPercent(bidBasisPoints);

  const handlePointClick = (payload: any) => {
    const price = parseFloat(payload.price.toFixed(3));
    const basisPoints = percentToBasisPoints(price.toString());


    setBidBasisPoints(basisPoints);
  };

  return (
    <div>
      <MaturityData
        ycChartData={ycChartData}
        setYcChartDataUrl={setYcChartDataUrl}
        formValues={formValues}
        onCurveSelect={onFormChange}
      />

      <div className="flex flex-col gap-8 md:flex-row" ref={chartParentRef}>
        <div className="w-full">
          {cashFlows?.price_yield_matrix ? (
            <YieldToPriceChart
              chartData={cashFlows.price_yield_matrix || []}
              upb={unpaidBalance}
              bidPx={bidPx}
              width={chartWidth - 25}
              onPointClick={handlePointClick}
            />
          ) : (
            <ChartSkeletonLoader height={500} width={chartWidth - 25} />
          )}
        </div>

        <div className="min-w-[300px] pr-4">
          <BidSummary
            netCoupon={netCoupon}
            unpaidBalance={unpaidBalance}
            netYield={netYield}
            spread={spread}
            yieldDuration={yieldDuration}
            spreadDuration={spreadDuration}
            participationPercent={100}
            bidBasisPoints={bidBasisPoints}
            servicingRate={Number(formValues.servicingRate)}
            defaultAssumptionType={formValues.defaultAssumptionType}
            cdr={formValues.cdr}
            prepaymentAssumptionType={formValues.prepaymentAssumptionType}
            cpr={formValues.cpr}
          />
        </div>
      </div>
    </div>
  );
};

export default YieldMatrixModeling;
