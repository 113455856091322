import { IconInfoCircle } from '@tabler/icons-react';
import { INFO_BID_SUMMARY_LIST } from 'configs/messages';
import { ModalNotStyled } from 'ui-kit';

interface BidSummaryTemplateProps {
  netWeightedAverageCoupon: string;
  gwac: string;
  unpaidBalance: string;
  bidPrice: string;
  netYieldAtPrice: string;
  netYield: string;
  spread: string;
  duration: string;
  defaultAssumptionType: string;
  cdr: number | string;
  prepaymentAssumptionType: string;
  cpr: number | string;
  purchaseDollarPrice: string;
}

const DetailRow =
  'border-t border-slate-700 flex justify-between px-3 py-1.5 text-slate-200 font-heebo text-sm font-normal leading-5';

const BidSummaryTemplate: React.FC<BidSummaryTemplateProps> = ({
  netWeightedAverageCoupon,
  gwac,
  unpaidBalance,
  bidPrice,
  netYieldAtPrice,
  netYield,
  spread,
  duration,
  defaultAssumptionType,
  cdr,
  prepaymentAssumptionType,
  cpr,
  purchaseDollarPrice,
}) => (
  <>
    <div>
      <ModalNotStyled
        trigger={
          <div className="flex justify-end p-1">
            <IconInfoCircle stroke={2} className="text-white" />
          </div>
        }
      >
        {({ closeModal }) => (
          <div className="w-full min-w-[430px] max-w-[650px] rounded-md border border-accent-muted bg-gray-950 p-4 text-foreground-default">
            <div className="mb-2 flex items-center justify-between">
              <h2 className="text-xl font-semibold">Bid Summary Details</h2>
              <button
                className="text-gray-500 hover:text-gray-300"
                onClick={closeModal}
              >
                ✕
              </button>
            </div>
            <ul className="m-0 list-none p-0">
              {INFO_BID_SUMMARY_LIST.map((item, index) => (
                <li key={index} className="border-t border-gray-700 px-2 py-1">
                  <div className="pr-24 text-sm font-bold text-gray-100">
                    {item.key}
                  </div>
                  {item.key !== item.infoText && (
                    <div className="text-sm font-bold text-gray-400">
                      {item.infoText}
                    </div>
                  )}
                  <div className="text-sm text-gray-400">
                    {item.explanation}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </ModalNotStyled>
    </div>
    <div className={DetailRow}>
      <div>UPB</div>
      <div className="word-break pl-2 text-right">{unpaidBalance}</div>
    </div>
    <div className={DetailRow}>
      <div>GWAC</div>
      <div className="word-break pl-2 text-right">{gwac}%</div>
    </div>
    <div className={DetailRow}>
      <div>NWAC</div>
      <div className="word-break pl-2 text-right">
        {netWeightedAverageCoupon}%
      </div>
    </div>
    <div className={DetailRow}>
      <div>Price</div>
      <div className="word-break pl-2 text-right">{bidPrice || '-'}%</div>
    </div>
    <div className={DetailRow}>
      <div>Net Yield @ Price</div>
      <div className="word-break pl-2 text-right">{netYieldAtPrice}</div>
    </div>
    <div className={DetailRow}>
      <div>Yield to Maturity</div>
      <div className="word-break pl-2 text-right">{netYield || '-'}%</div>
    </div>
    <div className={DetailRow}>
      <div>Spread to Curve</div>
      <div className="word-break pl-2 text-right">{spread || '-'} bps</div>
    </div>
    <div className={DetailRow}>
      <div>Duration</div>
      <div className="word-break pl-2 text-right">{duration || '-'}</div>
    </div>
    <div className={DetailRow}>
      <div>{defaultAssumptionType || 'CDR'}</div>
      <div className="word-break pl-2 text-right">{cdr || '-'}%</div>
    </div>
    <div className={DetailRow}>
      <div>{prepaymentAssumptionType || 'CPR'}</div>
      <div className="word-break pl-2 text-right">{cpr || '-'}%</div>
    </div>
    <div className={DetailRow}>
      <div>Purchase Dollar Price</div>
      <div className="word-break pl-2 text-right">{purchaseDollarPrice}</div>
    </div>
  </>
);

export default BidSummaryTemplate;
