import React, { useEffect, useRef, useState } from 'react';

import { TIME_LABELS } from 'constants/timeLabels';
import {
  basisPointsToPercent,
  percentToBasisPoints,
} from 'features/deals/DealStages/EventActionCards/utils';
import { CashFlowModelingInputs } from 'features/drilldown/cashflows/CashFlowModelingInputs';
import { CashFlowsValidValues } from 'features/drilldown/cashflows/configurations/cashflow-inputs';
import { ChartSkeletonLoader } from 'features/pages/market-data/components/ChartSkeleton';
import { URLMapDeprecated } from 'features/pages/market-data/shared';
import BidSummary from 'features/pages/marketplace/BiddingAndPricing/BidSummary/BidSummary';
import MaturityData from 'features/pages/marketplace/BiddingAndPricing/YieldMatrix/MaturityData';
import YieldToPriceChart, {
  YieldMatrixDataPoint,
} from 'features/pages/marketplace/BiddingAndPricing/YieldToPriceChart';

import { useQuery } from '@apollo/client';

import { GetMarketData } from 'query/__generated__/GetMarketData';
import { GetShortCarves_deal_carves_carve_summary } from 'query/__generated__/GetShortCarves';
import { GET_MARKET_DATA } from 'query/getMarketData';

interface YieldCurveData {
  labels: string[];
  series: { [key: string]: number[] };
}

interface YieldMatrixBidDetailsProps {
  onPriceSelect: (value: string) => void;
  formValues: CashFlowsValidValues;
  onFormChange: (values: CashFlowsValidValues) => void;
  carveSummaryData: GetShortCarves_deal_carves_carve_summary | null;
  loading: boolean;
  buyerPrice: number;
}

const YieldMatrixBidDetails: React.FC<YieldMatrixBidDetailsProps> = ({
  onPriceSelect,
  formValues,
  onFormChange,
  carveSummaryData,
  loading,
  buyerPrice,
}) => {
  const [chartWidth, setChartWidth] = useState(800);
  const chartParentRef = useRef<HTMLDivElement>(null);
  const [bidBasisPoints, setBidBasisPoints] = useState(buyerPrice || 0);
  const [ycChartData, setYcChartData] = useState<YieldCurveData | null>(null);
  const [ycChartDataMap, setYcChartDataMap] = useState<URLMapDeprecated>({});
  const [ycChartDataUrl, setYcChartDataUrl] = useState(
    'sofr_yc_chart_data.json',
  );
  const [spreadDuration, setSpreadDuration] = useState<number | undefined>(
    undefined,
  );
  const [spread, setSpread] = useState<number | undefined>(undefined);
  const [yieldDuration, setYieldDuratio] = useState<number | undefined>(
    undefined,
  );

  const handleFormChange = (newValues: Partial<CashFlowsValidValues>) => {
    onFormChange({ ...formValues, ...newValues });
  };

  const {
    data: marketData,
    loading: marketDataLoading,
    error: marketDataError,
  } = useQuery<GetMarketData>(GET_MARKET_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.marketData) {
        const urlMap: URLMapDeprecated = {};
        Object.entries(data.marketData.urlMap).forEach(([key, value]) => {
          urlMap[key] = value;
        });
        setYcChartDataMap(urlMap);
      }
    },
  });

  const unpaidBalance = carveSummaryData?.unpaidPrincipalBalance || 0;

  useEffect(() => {
    if (ycChartDataUrl && ycChartDataMap[ycChartDataUrl]) {
      fetch(ycChartDataMap[ycChartDataUrl])
        .then((response) => response.json())
        .then((data) => {
          if (ycChartDataUrl === 'sofr_yc_chart_data.json') {
            setYcChartData({ ...data, labels: TIME_LABELS });
          } else {
            setYcChartData(data);
          }
        });
    }
  }, [ycChartDataUrl, ycChartDataMap]);

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartParentRef.current) {
        const parentWidth = chartParentRef.current.offsetWidth;
        setChartWidth(parentWidth - 310);
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);
    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  const bidPx = basisPointsToPercent(bidBasisPoints);

  const handlePointClick = (payload: YieldMatrixDataPoint) => {
    const price = parseFloat(payload.price.toFixed(3));
    const basisPoints = percentToBasisPoints(price.toString());

    setBidBasisPoints(basisPoints);
    onPriceSelect(price.toString() || '');
    setSpreadDuration(payload.duration);
    setSpread(payload['Spread to spot']);
    setYieldDuratio(payload.Yield);
  };

  return (
    <div>
      <div className="flex gap-[40px] pb-[15px]">
        <CashFlowModelingInputs
          formValues={formValues}
          onSubmit={handleFormChange}
          withServicingRate
        />
      </div>
      <MaturityData
        ycChartData={ycChartData}
        setYcChartDataUrl={setYcChartDataUrl}
        formValues={formValues}
        onCurveSelect={handleFormChange}
      />

      <div className="flex flex-col gap-8 md:flex-row" ref={chartParentRef}>
        <div style={{ width: `${chartWidth}px` }} className="w-full">
          {loading ? (
            <ChartSkeletonLoader height={500} width={chartWidth - 25} />
          ) : (
            <YieldToPriceChart
              chartData={carveSummaryData?.cashFlows?.price_yield_matrix || []}
              upb={unpaidBalance}
              bidPx={bidPx}
              width={chartWidth - 25}
              onPointClick={handlePointClick}
            />
          )}
        </div>

        <div className="min-w-[300px] pr-4">
          <BidSummary
            netCoupon={carveSummaryData?.netCoupon || 0}
            unpaidBalance={carveSummaryData?.unpaidPrincipalBalance || 0}
            netYield={
              formValues.defaultAssumptionType === 'CDR'
                ? formValues.cdr
                : formValues.cpr
            }
            spread={spread}
            yieldDuration={yieldDuration}
            spreadDuration={spreadDuration}
            participationPercent={Number(formValues.servicingRate)}
            bidBasisPoints={bidBasisPoints}
            servicingRate={Number(formValues.servicingRate)}
            defaultAssumptionType={formValues.defaultAssumptionType}
            cdr={formValues.cdr}
            prepaymentAssumptionType={formValues.prepaymentAssumptionType}
            cpr={formValues.cpr}
          />
        </div>
      </div>
    </div>
  );
};

export default YieldMatrixBidDetails;
