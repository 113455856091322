import React, { PropsWithChildren } from 'react';

import { useCurrent2ndLevelRuoteConfig } from 'app-level/navigation';

import PortfolioSummary from '../PortfolioSummary';
import { SideNav } from './SideNav/SideNav';

export function PortfolioLayout(
  props: PropsWithChildren<{
    summeryChildren?: React.ReactNode;
    filterRow?: React.ReactNode;
    hideSummery?: boolean;
    actions?: React.ReactNode;
  }>,
) {
  const routeConfig = useCurrent2ndLevelRuoteConfig('portfolio');
  const showSideNav = routeConfig?.usesAssetClass;

  return (
    <>
      <div className="min-w-[14px]" style={{ gridArea: 'sidebar' }}>
        {showSideNav ? <SideNav /> : null}
      </div>
      <div className="p-3" style={{ gridArea: 'summery' }}>
        {!props.hideSummery ? <PortfolioSummary /> : null}
        {props.summeryChildren || null}
        <div className="wrap flex justify-between">
          <div className="w-full">{props.filterRow || null}</div>
          <div>{props.actions || null}</div>
        </div>
      </div>
      <div className="h-full overflow-auto rounded-lg bg-background-canvas p-3 grid-area-page">
        {props.children || null}
      </div>
    </>
  );
}
