import React from 'react';

import {
  IconChevronLeft,
  IconChevronRight,
  IconDots,
} from '@tabler/icons-react';

import { IconButton } from '../Buttons';

export interface PageNumberBoxesProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const LEFT_THRESHOLD: number = 5;
const RIGHT_THRESHOLD: number = 5;

export const getPages = (
  currentPage: number,
  totalPages: number,
): (number | 'dots')[] => {
  // Short-circuits for cases where totalPages <= 9
  if (totalPages <= 9)
    return Array.from({ length: totalPages }, (_, i) => i + 1);

  const pages: (number | 'dots')[] = [];

  const middlePage =
    currentPage <= LEFT_THRESHOLD
      ? LEFT_THRESHOLD
      : currentPage >= totalPages - RIGHT_THRESHOLD
        ? totalPages - RIGHT_THRESHOLD
        : currentPage;

  pages.push(1);
  pages.push(middlePage > 5 ? 'dots' : 2);
  pages.push(middlePage - 2);
  pages.push(middlePage - 1);
  pages.push(middlePage);
  pages.push(middlePage + 1);
  pages.push(middlePage + 2);
  pages.push(middlePage < totalPages - 4 ? 'dots' : totalPages - 1);
  pages.push(totalPages);

  return pages;
};

export const PageNumberBoxes: React.FC<PageNumberBoxesProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages;

  const pages = getPages(currentPage, totalPages);

  return (
    <div className="flex select-none items-center gap-[5px]">
      {isPrevDisabled ? (
        <div className="opacity-20">
          <IconButton
            className="h-6 w-6 hover:border-transparent-0"
            icon={
              <div className="flex h-6 w-6 cursor-pointer items-center justify-center">
                <IconChevronLeft />
              </div>
            }
          />
        </div>
      ) : (
        <div onClick={() => onPageChange(currentPage - 1)}>
          <IconButton
            className="h-6 w-6 hover:border-transparent-0"
            icon={
              <div className="flex h-6 w-6 cursor-pointer items-center justify-center">
                <IconChevronLeft />
              </div>
            }
          />
        </div>
      )}

      {pages.map((page, index) => {
        const activeClass =
          currentPage === page
            ? 'border-white bg-white text-brand-950'
            : 'border-gray-800 bg-gray-950 text-gray-600';
        return page === 'dots' ? (
          <div
            className="flex h-6 w-6 items-center justify-center text-[16px] font-bold text-white"
            key={`dots-${index}`}
          >
            <IconDots />
          </div>
        ) : (
          <div
            className={`${activeClass} flex-column flex h-6 w-auto min-w-6 cursor-pointer items-center justify-center rounded-[1px] border px-1 py-0 hover:bg-white`}
            key={page}
            onClick={() => onPageChange(page)}
          >
            {page}
          </div>
        );
      })}

      {isNextDisabled ? (
        <div className="opacity-20">
          <IconButton
            className="h-6 w-6 hover:border-transparent-0"
            icon={
              <div className="flex h-6 w-6 cursor-pointer items-center justify-center">
                <IconChevronRight />
              </div>
            }
          />
        </div>
      ) : (
        <div onClick={() => onPageChange(currentPage + 1)}>
          {/*<div className="flex h-6 w-6 cursor-pointer items-center justify-center">*/}
          {/*  <IconChevronRight />*/}
          {/*</div>*/}
          <IconButton
            className="h-6 w-6 hover:border-transparent-0"
            icon={
              <div className="flex h-6 w-6 cursor-pointer items-center justify-center">
                <IconChevronRight />
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};
