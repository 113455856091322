import { createQueryFilters } from 'context/DataContext';
import { ASSET_CLASS_IDS, AssetClassId } from 'types/assetClass';

import { gql, useQuery } from '@apollo/client';

import { Filter } from '__generated__/globalTypes';

import {
  ChartFragments,
  MonthlyTableFragments,
  PortfolioSummaryMetrics,
  SummaryTableFragments,
} from './cashflowDataFragments';
import { CashFlowsValidValues } from '../configurations/cashflow-inputs';
import {
  GetPortfolioCashFlows,
  GetPortfolioCashFlowsVariables,
} from './__generated__/GetPortfolioCashFlows';

const PORTFOLIO_QUERY = gql`
  query GetPortfolioCashFlows(
    $cdr: Float!
    $cpr: Float!
    $lsr: Float!
    $daysDelay: Int
    $filters: [Filter!]
    $rateType: RateType
    $prepaymentAssumptionType: PrepaymentAssumptionType
    $defaultAssumptionType: DefaultAssumptionType
    $servicingRate: Float
  ) {
    user {
      id
      company {
        id
        ... on UserCompany {
          id
          performanceSummary: performance_summary(filters: $filters) {
            cashFlows: cash_flows(
              conditionalDefaultRate: $cdr
              conditionalPrepaymentRate: $cpr
              lossSeverityRate: $lsr
              prepaymentAssumptionType: $prepaymentAssumptionType
              defaultAssumptionType: $defaultAssumptionType
              servicingRate: $servicingRate
              daysDelay: $daysDelay
              rateType: $rateType
            ) {
              totals {
                ...SummaryTableCashFlowTotals
              }
              monthlyCashFlows: monthly_cash_flows {
                ...ChartCashFlowMonth
                ...MonthlyTableCashFlowMonth
              }
              price_yield_matrix {
                spread_matrix
                yield_matrix
              }
            }
            ...PortfolioSummaryMetrics
            ...SummaryTablePerformanceSummary
          }
        }
      }
    }
  }
  ${PortfolioSummaryMetrics.performanceSummaryMetrics}
  ${SummaryTableFragments.cashFlowTotals}
  ${SummaryTableFragments.performanceSummary}
  ${MonthlyTableFragments.cashFlowMonth}
  ${ChartFragments.cashFlowMonth}
`;

export function getUsePortfolioCashflow(
  assetClassId: AssetClassId,
  filters: Filter[],
) {
  const assetClass = ASSET_CLASS_IDS[assetClassId];

  const usePortfolioCashflow_gql = (userInputs: CashFlowsValidValues) => {
    const { data, loading, error } = useQuery<
      GetPortfolioCashFlows,
      GetPortfolioCashFlowsVariables
    >(PORTFOLIO_QUERY, {
      variables: {
        cdr: userInputs.cdr / 100,
        cpr: userInputs.cpr / 100,
        lsr: userInputs.lsr / 100,
        daysDelay: +userInputs.daysDelay,
        servicingRate:
          userInputs.servicingRate != null
            ? userInputs.servicingRate / 100
            : null,
        rateType: userInputs.rateType,
        prepaymentAssumptionType: userInputs.prepaymentAssumptionType,
        defaultAssumptionType: userInputs.defaultAssumptionType,
        filters: createQueryFilters(assetClass, filters),
      },
    });

    return {
      loading,
      error,
      data: data?.user.company.performanceSummary,
    };
  };
  return usePortfolioCashflow_gql;
}
