import React, { useRef, useEffect } from 'react';

import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

export interface DropdownOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  options: DropdownOption[];
  placeholder?: string;
  onValueChange?: (value: DropdownOption) => void;
  isOpen?: boolean;
  onToggleOpen?: (open: boolean) => void;
  value?: string;
  iconColor?: string;
  menuClasses?: string;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom';
  variant?: 'primary' | 'gray';
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder = 'Select...',
  onValueChange,
  isOpen: controlledIsOpen,
  onToggleOpen,
  className,
  value,
  iconColor = 'pink-400',
  menuPlacement = 'bottom',
  menuClasses = '',
  variant = 'primary',
  disabled = false,
  ...props
}) => {
  const [internalIsOpen, setInternalIsOpen] = React.useState(false);
  const isOpen = controlledIsOpen ?? internalIsOpen;
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    if (!disabled) {
      const newState = !isOpen;
      setInternalIsOpen(newState);
      onToggleOpen?.(newState);
    }
  };

  const handleSelect = (option: DropdownOption) => {
    if (option.isDisabled) return;
    onValueChange?.(option);
    setInternalIsOpen(false);
    onToggleOpen?.(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setInternalIsOpen(false);
      onToggleOpen?.(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const topValue = options.length * 50; // Calculate in pixels
  const placementClass =
    menuPlacement === 'bottom' ? 'mt-2' : `top-[-${topValue}px]`;
  const menuStyles =
    menuPlacement === 'bottom' ? {} : { top: `-${options.length * 37}px` };

  const disabledClass = disabled ? 'cursor-not-allowed' : '';

  const optionsArr = menuPlacement === 'bottom' ? options : options.reverse();

  const baseContainerClass = 'relative text-m border text-white px-4';

  const containerVariantClass = {
    primary: 'rounded-md border-pink-500 bg-gray-950 py-2',
    gray: 'h-[28px] rounded-[4px] py-1 border-slate-400 bg-gray-950 text-[14px]',
  };
  const baseMenuClass =
    'absolute left-0 right-0 z-10 overflow-hidden shadow-lg';

  const menuVariantClass = {
    primary: 'rounded-md border border-pink-500 bg-gray-950 ',
    gray: 'rounded-[6px] border border-gray-950 bg-gray-950 ',
  };

  return (
    <div
      ref={dropdownRef}
      className={`${className} ${baseContainerClass} ${disabledClass}  ${containerVariantClass[variant]}`}
      {...props}
    >
      <button
        type="button"
        disabled={disabled}
        onClick={toggleOpen}
        className={`${disabledClass} flex w-full items-center justify-between`}
      >
        <span>{value || placeholder}</span>
        {isOpen ? (
          <IconChevronUp className={`text-${iconColor}`} size={20} />
        ) : (
          <IconChevronDown className={`text-${iconColor}`} size={20} />
        )}
      </button>
      {isOpen && (
        <ul
          style={menuStyles}
          className={`${placementClass} ${menuClasses} ${baseMenuClass} ${menuVariantClass[variant]}`}
        >
          {optionsArr.map((option) => (
            <li
              key={option.value}
              className={`px-4 py-2 text-sm ${
                option.isDisabled
                  ? 'cursor-default text-gray-500'
                  : 'cursor-pointer text-white hover:bg-gray-900'
              }`}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
