import {
  ChartFragments,
  MonthlyTableFragments,
  SummaryTableFragments,
} from 'features/drilldown/cashflows/gql/cashflowDataFragments';
import BidStipulationFragments from 'features/pages/marketplace/BiddingAndPricing/BidStipulations.fragments';
import CarveTableFragments from 'features/pages/marketplace/BiddingAndPricing/CarveTable.fragments';

import { gql } from '@apollo/client';

export const GET_CARVES = gql`
  query GetCarves($deal_id: ID!, $bid_id: ID) {
    deal(id: $deal_id) {
      id
      carves(bid_id: $bid_id) {
        id
        name
        carve {
          field_name
          operator
          operand
          operandList
        }
        ...BidStipulationsDealCarve
        ...CarveTableDealCarve
      } # TODO see if we can just remove this chunk of performance_summary and pull it from where we need it
      performance_summary {
        unpaidBalance: current_balance_cents
        originalBalance: original_balance_cents
        loanCount: loan_count
        averageBalance: wa_current_balance_cents
        averageOriginalBalance: wa_original_balance_cents
        netCoupon: wa_coupon
        servicingRate: wa_servicing_rate
        averageAge: wa_age_months
        averageMaturity: wa_remaining_loan_terms_months
        fico: wa_borrower_credit_score
        dti: wa_dti
        ltv: wa_ltv
      }
    }
  }
  ${BidStipulationFragments.DealCarve}
  ${CarveTableFragments.DealCarve}
`;

export const GET_CARVES_FOR_BID_DETAILS = gql`
  query GetShortCarves(
    $deal_id: ID!
    $bid_id: ID!
    $defaultAssumptionType: DefaultAssumptionType!
    $conditionalDefaultRate: Float!
    $lossSeverityRate: Float!
    $prepaymentAssumptionType: PrepaymentAssumptionType!
    $conditionalPrepaymentRate: Float!
  ) {
    deal(id: $deal_id) {
      carves(bid_id: $bid_id) {
        id
        carve_summary {
          unpaidPrincipalBalance: current_balance_cents
          cashFlows: cash_flows(
            defaultAssumptionType: $defaultAssumptionType
            conditionalDefaultRate: $conditionalDefaultRate
            lossSeverityRate: $lossSeverityRate
            prepaymentAssumptionType: $prepaymentAssumptionType
            conditionalPrepaymentRate: $conditionalPrepaymentRate
          ) {
            totals {
              ...SummaryTableCashFlowTotals
            }
            monthlyCashFlow: monthly_cash_flows {
              ...ChartCashFlowMonth
              ...MonthlyTableCashFlowMonth
            }
            price_yield_matrix {
              spread_matrix
              yield_matrix
            }
          }
          originalBalance: original_balance_cents
          loanCount: loan_count
          averageBalance: wa_current_balance_cents
          averageOriginalBalance: wa_original_balance_cents
          netCoupon: wa_coupon
          servicingRate: wa_servicing_rate
          averageAge: wa_age_months
          averageMaturity: wa_remaining_loan_terms_months
          fico: wa_borrower_credit_score
          dti: wa_dti
          ltv: wa_ltv
        }
        stipulations {
          bid_basis_points
        }
      }
    }
  }
  ${MonthlyTableFragments.cashFlowMonth}
  ${ChartFragments.cashFlowMonth}
  ${SummaryTableFragments.cashFlowTotals}
`;
