import React from 'react';

import classNames from 'classnames';

interface SpinnerProps {
  loading?: boolean; // Whether to show the spinner
  fullScreen?: boolean; // Whether the spinner covers the full screen
  relative?: boolean; // Whether the spinner is relative to its container
  size?: number; // Custom size for the spinner (in pixels)
  color?: string; // Custom color for the spinner
  overlay?: boolean; // Whether to show the background overlay
}

export const Spinner: React.FC<SpinnerProps> = ({
  loading = false,
  fullScreen = false,
  relative = false,
  size = 96,
  overlay = false,
}) => {
  if (!loading) return null;

  const overlayClasses = classNames('flex items-center justify-center', {
    'fixed inset-0 z-50': fullScreen,
    'absolute z-10 w-full h-full': relative,
    'bg-black bg-opacity-60': overlay,
  });

  const spinnerStyle = {
    height: `${size}px`,
    width: `${size}px`,
    borderWidth: `${size / 8}px`,
    borderTopColor: '#ea27c2',
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    borderRightColor: 'transparent',
  };

  return (
    <div
      className={overlayClasses}
      style={{ maxHeight: '100vh', overflow: 'hidden' }}
    >
      <div
        className="animate-spin rounded-full border-solid"
        style={spinnerStyle}
      ></div>
    </div>
  );
};
