import React from 'react';

import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  IconMenu2,
  IconCircleXFilled,
  IconPlus,
  IconArrowNarrowUp,
  IconArrowNarrowDown,
} from '@tabler/icons-react';
import clsx from 'clsx';

export type PillVariant = 'primary' | 'muted' | 'blue';

export interface PillProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  label?: string;
  value?: string;
  variant?: PillVariant;
  isLoading?: boolean;
  isActive?: boolean;
  isDraggable?: boolean;
  isAnimate?: boolean;
  isRemovable?: boolean;
  isAddableToList?: boolean;
  onRemove?: (id?: string) => void;
  onAdd?: (id?: string) => void;
  listeners?: SyntheticListenerMap;
  attributes?: DraggableAttributes;
  id?: string;
  sortValue?: string;
  isSorted?: boolean;
}

export const Pill = React.forwardRef<HTMLDivElement, PillProps>(
  (
    {
      label,
      value,
      isRemovable = false,
      isAddableToList = false,
      attributes,
      onRemove,
      onAdd,
      isDraggable = false,
      isAnimate = false,
      isSorted = false,
      sortValue,
      className,
      isLoading,
      listeners,
      isActive,
      variant = 'primary',
      id,
      ...rest
    },
    ref,
  ) => {
    const pillVariantClasses = {
      primary: 'bg-gray-950 border-gray-800',
      muted: 'bg-gray-950 border-gray-800', // need to clear
      blue: 'bg-slate-700 border-gray-800',
    };

    const pillClassName = clsx(
      className,
      pillVariantClasses[variant],
      'px-4 py-2 flex items-center select-none flex-row gap-2 rounded-full relative  border-[0.5px] max-w-fit whitespace-nowrap group',
      {
        '!border-pink-500': isActive,
        'animate-shake': isAnimate,
      },
    );

    const handleKeyDown = (
      event: React.KeyboardEvent,
      callback?: () => void,
    ) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        callback?.();
      }
    };

    if (isLoading) {
      return (
        <div className="flex w-28 animate-pulse select-none flex-row items-center justify-between gap-2 rounded-full border-[0.5px] border-gray-800 bg-gray-950 px-4 py-2">
          <div className="h-4 w-1/5 rounded bg-gray-700"></div>
          <div className="h-4 w-10/12 rounded bg-gray-700"></div>
        </div>
      );
    }

    return (
      <div ref={ref} className={pillClassName} {...rest}>
        <span className="font-heebo text-xs font-light text-gray-500">
          {label}
        </span>
        <span className="font-pt-mono text-xs font-light text-gray-50">
          {value}
        </span>
        {isSorted && sortValue && (
          <>
            {sortValue === 'asc' ? (
              <IconArrowNarrowUp size="14px" />
            ) : (
              <IconArrowNarrowDown size="14px" />
            )}
          </>
        )}

        {isDraggable && (
          <button
            className="rounded text-gray-600 opacity-0 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50 group-hover:opacity-100"
            {...listeners}
            {...attributes}
            aria-label={`Drag ${label}`}
          >
            <IconMenu2 size={12} />
          </button>
        )}
        {isRemovable && (
          <button
            onClick={() => onRemove?.(id)}
            onKeyDown={(e) => handleKeyDown(e, () => onRemove?.(id))}
            aria-label={`Remove ${label}`}
            className="absolute -right-1 -top-1 rounded-full text-gray-600 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50"
          >
            <IconCircleXFilled size={16} className="hover:text-pink-400" />
          </button>
        )}
        {isAddableToList && (
          <button
            onClick={() => onAdd?.(id)}
            onKeyDown={(e) => handleKeyDown(e, () => onAdd?.(id))}
            aria-label={`Add ${label}`}
            className="ml-1 rounded-full text-gray-600 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50"
          >
            <IconPlus size={16} className="hover:text-pink-400" />
          </button>
        )}
      </div>
    );
  },
);
