import { formatCentsToDollars } from 'features/pages/marketplace/BiddingAndPricing/formatting';
import { DateTime } from 'luxon';

import { BidCard_BidCard_bid } from './BidCardBody/__generated__/BidCard';
import { basisPointsToPercent } from './utils';

type BidSummarySectionProps = {
  bid: BidCard_BidCard_bid;
};

const BuyerBidSummary = ({ bid }: BidSummarySectionProps): JSX.Element => {
  const hasDiligenceCompletionDate =
    bid.stipulations?.has_diligence_completion_date_seconds;
  const diligenceCompletionDate =
    bid.stipulations?.diligence_completion_date_seconds;
  const hasSettlementDate = bid.stipulations?.has_settlement_date_seconds;
  const settlementDate = bid.stipulations?.settlement_date_seconds;
  const bidBasisPoints = bid.stipulations?.bid_basis_points || 0;
  const servicing = Number(bid.stipulations?.servicing_rate) || 0;
  const gwac = bid.carve_summary?.wa_coupon || 0;
  const nwac = 100 * gwac - servicing;

  const netYieldAtPrice = () => {
    const pricePercent = parseFloat(basisPointsToPercent(bidBasisPoints));
    return pricePercent !== 0
      ? ((100 * nwac) / pricePercent).toFixed(3) + '%'
      : '-';
  };

  const hasCustomStipulations =
    (bid.stipulations?.custom_stipulations?.length ?? 0) > 0;

  if (bid.carve_summary == null) return <></>;

  const stats: { label: string; value: string | JSX.Element[] }[] = [];

  const unpaidBalance = bid?.price?.unpaid_balance || 0;
  const participationPercent = Number(servicing || 0);

  const purchasePrice = () => {
    const priceDecimal = parseFloat(basisPointsToPercent(bidBasisPoints)) / 100;

    let finalPrice = priceDecimal * unpaidBalance;

    if (participationPercent > 0) {
      finalPrice = priceDecimal * unpaidBalance * (participationPercent / 100);
    }

    return formatCentsToDollars(finalPrice);
  };

  // Add required stats
  stats.push({
    label: 'Price',
    value: `${basisPointsToPercent(bidBasisPoints)}%`,
  });

  if (bid.stipulations?.is_servicing_retained) {
    stats.push({
      label: 'Servicing Fee',
      value: `${bid.stipulations?.servicing_rate}%`,
    });
  } else {
    stats.push({ label: 'Servicing', value: 'Released' });
  }

  stats.push({ label: 'Net Yield @ Price', value: netYieldAtPrice() });

  // Add optional stats only if they exist
  if (bid.stipulations?.has_percent_collateral_diligence) {
    stats.push({
      label: 'Percent Collateral Diligence',
      value: `${bid.stipulations?.percent_collateral_diligence}%`,
    });
  }

  if (bid.stipulations?.has_min_net_yield_to_buyer) {
    stats.push({
      label: 'Min Net Yield to Buyer',
      value: `${bid.stipulations?.min_net_yield_to_buyer}%`,
    });
  }

  if (purchasePrice) {
    stats.push({
      label: 'Purchase Dollar Price',
      value: purchasePrice(),
    });
  }

  if (hasDiligenceCompletionDate && diligenceCompletionDate) {
    stats.push({
      label: 'Diligence Completion Date',
      value: DateTime.fromSeconds(diligenceCompletionDate).toLocaleString(
        DateTime.DATE_SHORT,
      ),
    });
  }

  if (hasSettlementDate && settlementDate) {
    stats.push({
      label: 'Settlement Date',
      value: DateTime.fromSeconds(settlementDate).toLocaleString(
        DateTime.DATE_SHORT,
      ),
    });
  }

  if (hasCustomStipulations) {
    stats.push({
      label: 'Custom Stipulations',
      value:
        bid.stipulations?.custom_stipulations
          ?.filter(Boolean)
          .map((stip, index) => <div key={stip + index}>{stip}</div>) ?? [],
    });
  }

  return (
    <div className="mt-3 w-full">
      <h3 className="mb-3 text-sm uppercase text-slate-200">Offer Details</h3>
      {stats.map(({ label, value }) => (
        <div
          key={label}
          className="flex justify-between border-t border-slate-600 px-3 py-2"
        >
          <span className="text-sm text-slate-200">{label}</span>
          <span className="text-sm text-slate-200">{value}</span>
        </div>
      ))}
    </div>
  );
};

export default BuyerBidSummary;
