import React from 'react';

const TYPES = {
  primary: 'primary',
  primary_green: 'primary_green',
  primary_light: 'primary_light',
  primary_dark: 'primary_dark',
  secondary: 'secondary',
  secondary_green: 'secondary_green',
  tertiary: 'tertiary',
  container: 'container',
  text_button: 'text_button',
};

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  extraLarge: 'extra-large',
};

export type ButtonProps = {
  label: string;
  children?: React.ReactNode;
  type?: (typeof TYPES)[keyof typeof TYPES];
  size?: (typeof SIZES)[keyof typeof SIZES];
  disabled?: boolean;
  isSelected?: boolean;
  isIconRight?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  [key: string]: any;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = TYPES.primary,
      size = SIZES.large,
      disabled,
      children,
      label,
      isIconRight,
      onClick,
      className,
      buttonType = 'button',
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const buttonSizeClasses = {
      [SIZES.large]: `text-[16px] leading-[20px] py-[10px] px-[16px]`,
      [SIZES.extraLarge]: `text-[18px] leading-[24px] py-[14px] px-[16px]`,
      [SIZES.medium]: `text-[14px] leading-[16px] py-[10px] px-[16px]`,
      [SIZES.small]: `text-[12px] leading-[16px] py-[6px] px-[16px]`,
    };

    const buttonBaseClass = `${className} min-h-[42px]  rounded-[4px] flex justify-evenly items-center cursor-pointer ${type !== TYPES.container ? buttonSizeClasses[size] : ''} ${isIconRight ? 'flex-row-reverse' : ''}`;

    const buttonPrimaryClass = `${
      disabled
        ? 'bg-gray-800 border-gray-800 text-gray-500 hover:cursor-not-allowed'
        : 'bg-brand-400 border-2 border-brand-400 text-brand-950 shadow-[0px_0px_1px_rgba(48,49,51,0.05),0px_4px_8px_rgba(48,49,51,0.1)] outline-none hover:bg-brand-800 hover:border-brand-800 hover:text-white active:bg-brand-500 active:border-brand-500 focus:border-brand-500'
    }`;

    const buttonPrimaryLightClass = `${
      disabled
        ? 'bg-gray-800 border-gray-800 text-gray-500 hover:cursor-not-allowed'
        : 'bg-brand-500 border-2 border-brand-500 text-white shadow-[0px_0px_1px_rgba(48,49,51,0.05),0px_4px_8px_rgba(48,49,51,0.1)] outline-none hover:bg-brand-800 hover:border-brand-800 hover:text-white active:bg-brand-500 active:border-brand-500 focus:border-brand-500'
    }`;

    const buttonPrimaryDarkClass = `${
      disabled
        ? 'bg-gray-900 border-gray-700 text-gray-500 hover:cursor-not-allowed'
        : 'bg-gray-950 border border-gray-700 text-white shadow-[0px_0px_1px_rgba(48,49,51,0.05),0px_4px_8px_rgba(48,49,51,0.1)] outline-none hover:bg-gray-900'
    }`;

    const buttonSecondaryClass = `${
      disabled
        ? 'bg-gray-600 border border-gray-600 text-brand-950 hover:cursor-not-allowed'
        : 'bg-transparent border border-pink-400 text-brand-400 hover:border-pink-800 hover:text-brand-800 border-brand-500 active:text-brand-500 '
    }`;

    const buttonTertiaryClass = `${
      disabled
        ? 'border border-gray200 bg-brand-950 text-gray200 hover:cursor-not-allowed'
        : 'bg-gray-900 text-gray-400 border border-gray-900 hover:border-black hover:text-slate-50 active:border-slate-50 active:text-slate-50'
    }`;

    const buttonPrimaryGreenClass = `${
      disabled
        ? 'bg-gray-600 border-green-600 text-white hover:cursor-not-allowed'
        : 'border-green-600 bg-green-600 text-white hover:border-green-700 hover:bg-green-700 hover:text-white'
    }`;

    const buttonSecondaryGreenClass = `${
      disabled
        ? 'bg-gray-600 border border-green-600 text-white hover:cursor-not-allowed'
        : 'border border-green-600 text-white hover:border-green-700 hover:bg-green-700 hover:text-white'
    }`;

    const buttonTextClass = `${
      disabled
        ? 'bg-gray-600 border border-green-600 text-white hover:cursor-not-allowed'
        : 'text-white underline !px-0 !py-0'
    }`;

    const buttonContainerClass = `${disabled ? 'bg-none p-0' : 'border-none'}`;

    const buttonClasses = {
      [TYPES.primary]: `${buttonBaseClass} ${buttonPrimaryClass} `,
      [TYPES.primary_light]: `${buttonBaseClass} ${buttonPrimaryLightClass}`,
      [TYPES.primary_dark]: `${buttonBaseClass} ${buttonPrimaryDarkClass}`,
      [TYPES.secondary]: `${buttonBaseClass} ${buttonSecondaryClass}`,
      [TYPES.tertiary]: `${buttonBaseClass} ${buttonTertiaryClass}`,
      [TYPES.primary_green]: `${buttonBaseClass} ${buttonPrimaryGreenClass}`,
      [TYPES.secondary_green]: `${buttonBaseClass} ${buttonSecondaryGreenClass}`,
      [TYPES.container]: `${buttonBaseClass} ${buttonContainerClass}`,
      [TYPES.text_button]: `${buttonBaseClass} ${buttonTextClass}`,
    };

    return (
      <button
        ref={ref}
        onClick={onClick}
        type={buttonType}
        aria-label={label}
        className={buttonClasses[type]}
        disabled={!!disabled}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
