export const TIME_LABELS = [
  '1 Mo',
  '2 Mo',
  '3 Mo',
  '6 Mo',
  '1 Yr',
  '2 Yr',
  '3 Yr',
  '5 Yr',
  '10 Yr',
  '20 Yr',
  '30 Yr',
] as const;
