import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const downloadElementAsPDF = async (
  elementId: string,
  filename: string,
  padding: number = 10,
): Promise<void> => {
  const element = document.getElementById(elementId);
  if (!element) {
    console.error(`Element with ID "${elementId}" not found.`);
    return;
  }

  const canvas = await html2canvas(element, { scale: 2 });
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');

  const pageWidth = pdf.internal.pageSize.getWidth();
  const availableWidth = pageWidth - 2 * padding;

  const imgHeight = (canvas.height * availableWidth) / canvas.width;

  pdf.addImage(imgData, 'PNG', padding, padding, availableWidth, imgHeight);
  pdf.save(`${filename}.pdf`);
};
