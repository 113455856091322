import React, { useCallback, useEffect, useState } from 'react';

import { RowSelectionState } from '@tanstack/react-table';
import { SvgIcon } from 'common-ui';
import { PillFilters } from 'features/common-elements';
import { LoanDatatableLoan } from 'features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';
import {
  generateRowId,
  parseRowId,
} from 'features/drilldown/LoanDatatable/LoanTable';
import { useFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { TableQueryParameters } from 'hooks/useTableQueryParams';
import { Button } from 'ui-kit';

import { ApolloError } from '@apollo/client';

import { AssetClass, Filter, ParentType } from '__generated__/globalTypes';

import { TabContent, Row, SmallRowLabel } from './commonStyles';
import { useLoanDiligence } from './contexts/LoanDiligenceProvider';
import { RowId } from './DiligenceController';
import DiligencePopulationMetrics, {
  DiligencePopulationMetricsProps,
} from './DiligencePopulationMetrics';
import SelectLoansForDiligenceTable from './SelectLoansForDiligenceTable';

export interface SelectLoansProps {
  dealId: string;
  assetClass: AssetClass;
  loans: LoanDatatableLoan[];
  allLoans: LoanDatatableLoan[];
  totalLoans?: number;
  is_complete: boolean;
  loansError?: ApolloError;
  selectLoansMetrics: DiligencePopulationMetricsProps;

  queryParams: TableQueryParameters<any, any>;

  updateQueryParams: (params: Partial<TableQueryParameters<any, any>>) => void;
  selectedLoansSnapshot: RowId[];
  switchToReviewDiligence?: () => void;
  filters: Filter[];
}

// TODO(kentskinner): these need to be moved to a common location, or perhaps it makes sense to pass them down in QueryParameters.
export const rowsPerPageOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const SelectLoansTab: React.FC<SelectLoansProps> = ({
  assetClass,
  dealId,
  loans,
  allLoans,
  totalLoans,
  selectLoansMetrics,
  queryParams,
  updateQueryParams,
  selectedLoansSnapshot,
  switchToReviewDiligence,
  is_complete,
  filters,
}) => {
  // State for keeping track all of selected loans (across all pages)
  const [selectedLoans, setSelectedLoans] = useState<RowSelectionState>({});
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const loanDiligence = useLoanDiligence();
  const numSelected = Object.values(selectedLoans).filter((v) => v).length;

  useEffect(() => {
    const newSelectedLoans: RowSelectionState = {};
    selectedLoansSnapshot.forEach((rowId) => {
      newSelectedLoans[rowId] = true;
    });
    setSelectedLoans(newSelectedLoans);
  }, [selectedLoansSnapshot]);

  // Derived values
  const portfolioFiltersConfig = useFilterConfig(assetClass, {
    parentType: ParentType.DEAL,
    parentId: dealId,
  });
  const { pageSize } = queryParams;

  // Select all loans depends on attributes or all loans on the current page/all loans in the list
  const handleSelectAllRows = useCallback(
    (loansArr: LoanDatatableLoan[]) => {
      setSelectedLoans((currentSelection) => {
        const newSelection = { ...currentSelection };
        loansArr.forEach((loan) => {
          newSelection[generateRowId(loan.account_id, loan.id)] = true;
        });
        return newSelection;
      });
    },
    [allLoans, loans],
  );

  // Mark all selected loans for diligence
  const handleSelectForDiligence = useCallback(() => {
    const loanIds = Object.keys(selectedLoans).map(
      (key) => parseRowId(key).loanId,
    );
    let queryFilters: Filter[] | null;
    if (loanIds.length === allLoans.length || isAllSelected) {
      queryFilters = filters;
    } else {
      const notSelectedLoansAccountIds = allLoans
        .filter((loan) => !loanIds.includes(loan.id))
        .map((loan) => loan.account_id);
      queryFilters =
        notSelectedLoansAccountIds &&
        notSelectedLoansAccountIds?.length < loanIds.length
          ? [
              ...filters,
              {
                field_name: 'account_id',
                operator: 'IS_NOT',
                operandList: notSelectedLoansAccountIds,
              } as Filter,
            ]
          : null;
    }

    loanDiligence.selectLoans(loanIds, queryFilters);

    if (switchToReviewDiligence) switchToReviewDiligence();
  }, [selectedLoans, loanDiligence, switchToReviewDiligence]);

  return (
    <TabContent>
      <Row>
        <SmallRowLabel>Diligence Population</SmallRowLabel>
        <DiligencePopulationMetrics metrics={selectLoansMetrics} />
      </Row>
      <Row>
        <div>
          {portfolioFiltersConfig && (
            <PillFilters
              filtersConfig={portfolioFiltersConfig}
              currentFilters={queryParams.filters}
              setCurrentFilters={(filters) =>
                updateQueryParams({ ...queryParams, filters })
              }
            />
          )}
        </div>
      </Row>
      {!is_complete && (
        <>
          <Row className="justify-between">
            <div className="flex gap-6">
              <Button
                type="secondary"
                size="compact"
                label="Clear all rows"
                className="px-[6px] py-[2px]"
                onClick={() => {
                  setSelectedLoans({});
                  setIsAllSelected(false);
                }}
              >
                Clear All Rows
              </Button>
              <Button
                type="secondary"
                size="compact"
                label={`Select ${pageSize} rows`}
                className="px-[6px] py-[2px]"
                onClick={() => {
                  handleSelectAllRows(loans);
                }}
              >
                Select {loans.length < pageSize ? loans.length : pageSize} Rows
              </Button>
              <Button
                disabled={!allLoans.length}
                type="secondary"
                size="compact"
                label={`Select ${pageSize} rows`}
                className="px-[6px] py-[2px]"
                onClick={() => {
                  setIsAllSelected(true);
                  handleSelectAllRows(allLoans);
                }}
              >
                Select All List
              </Button>
              <Button
                disabled={!isAllSelected && !Object.keys(selectedLoans).length}
                type="primary"
                size="compact"
                label="Select For Diligence"
                className="px-[6px] py-[2px]"
                onClick={() => {
                  handleSelectForDiligence();
                }}
              >
                Select For Diligence
              </Button>
            </div>
          </Row>
        </>
      )}
      <SelectLoansForDiligenceTable
        selection={{
          selected: selectedLoans,
          onSelectionChange: (newSelection) => {
            !is_complete && setSelectedLoans(newSelection);
            setIsAllSelected(false);
          },
        }}
        data={loans}
        assetClass={assetClass}
        pagination={{
          updateParams: updateQueryParams,
          queryParams,
          totalItems: totalLoans,
        }}
      />
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <SvgIcon name="info" />
          {isAllSelected ? totalLoans : numSelected} Loans Selected
        </div>
      </div>
    </TabContent>
  );
};
